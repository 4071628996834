import { HttpClient } from 'utils/constants/httpClient'
import { logAnalytics } from 'services/analytics/analytics.service'

let httpClient = new HttpClient()

export namespace userClient {
  export const postUserEventsWithAuth = async (data: any, token?: any) => {
    logAnalytics.logEvents(data)
    const response = await httpClient.post(
      'events',
      { data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }
}
