import { FC,forwardRef } from 'react'
import { S } from './styled'
interface Props {
  style: {
    height: string
    width: string
    alignSelf?: string
    order?: string
    zIndex?: number
    fontSize?: string
    borderRadius?: string
    position?: string
    padding?: string
    margin?: string
    background?: string
    color?: string
  }
  disable?: boolean
  onClick?: (e?: any) => void
  children: any
  ref?:HTMLButtonElement
}

const PrimaryButton: FC<Props> =({ style, children, onClick, disable, ref }) => {
  return (
    <S.PrimaryCustomButton onClick={onClick} ref={ref} sx={style} disabled={disable ?? false}>
      {children}
    </S.PrimaryCustomButton>
  )
}

export default PrimaryButton
