import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace topupClient {
  
  export const getTopUps = async (token: any, countryCode: any, poolId: any, iccid: any) => {
    return await httpClient.get(`regions/active?countryCode=${countryCode}&poolId=${poolId}&iccid=${iccid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }  
}
