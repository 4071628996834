import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Typography, Tooltip, useMediaQuery } from '@mui/material'
import { B } from './styled'
import { S } from '../../modal.styles'
import { useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useModalContext } from 'contexts/modal.context'
import { useSwitchNetwork, useNetwork, useAccount, useContractRead } from 'wagmi';
import { ethers } from 'ethers';
import copyIcon from 'assets/icons/copy-icon-green.svg'
import PaymentSuccessIllustaration from 'assets/PaymentSuccessIllustration.svg'
import PrimaryButton from 'components/buttons/PrimaryButton'
import ActivationFailure from './ActivationFailure'
import { shortenedAddress } from 'utils'
import { activateDctV3, activatePurchase } from 'utils/constants/config'
import { PURCHASE_TYPE_BUY, SCAN,PURCHASE_TYPE_BULK_BUY,PURCHASE_TYPE_SINGLE_BUY } from 'utils/constants'
import { userClient } from 'services/http/user.service'
import { useAppContext } from 'contexts/app.context'
import { purchaseclient } from 'services/http/purchases.service'
import { stakingClient } from 'services/http/staking.service'
import { useEthersSigner } from '../../../../wagmi/EthersSigner'
import { useEthersProvider } from '../../../../wagmi/EthersProvider'
import { erc1155_ABI } from '../../../../wagmi/ERC1155_ABI'
import { forwarderAbi } from '../../../../wagmi/ForwarderAbi'
import AddEsimButton from 'components/buttons/AddEsimButton'
import { useTranslation } from 'react-i18next'

interface Props {
  handleClose: any
}
const ActivateBuy: FC<Props> = ({ handleClose }) => {
  const { userLogin, purchaseType, isWagmiConnected } = useWalletContext()
  const {
    walletPurchaseData,
    offerData,
    purchaseOrderType,
    handleActivatedPurchaseData,
    handleWalletPurchaseData,
    setPurchaseModalLoading,    
    setPurchaseOrderType,
  } = usePaymentContext()
  const { selectedOfferType } = useAppContext()
  const { setActiveStep, setOpenActivationFailureModal, openActivationFailureModal } = useModalContext()
  const [tooltipTimer, setTooltipTimer] = useState<boolean>(false)
  const [activateUrl, setActivateUrl] = useState('')
  const [activationError, setActivationError] = useState([])
  const [activationLoading, setActivationLoading] = useState(false)
  const [retryState, setRetryState] = useState(false)
  const [redeemStatus, setRedeemStatus] = useState(false)
  const medium = useMediaQuery('(max-width:899.95px)')
  const { t } = useTranslation()

  const ERC1155_ADDRESS:string = process.env.REACT_APP_ERC1155_ADDRESS!
  const ForwarderAddress:string = process.env.REACT_APP_FORWARDER_ADDRESS!
  const chainID = Number(process.env.REACT_APP_CHAIN_ID ?? '8453')
  const { address } = useAccount()
  const {refetch: refetchIsStaked,data:readData,isSuccess:isReadSuccess } = useContractRead({
    address: ERC1155_ADDRESS as `0x${string}`,
    abi: erc1155_ABI,
    watch:isWagmiConnected?true:false,
    functionName: 'isStaked',
    args: [walletPurchaseData?.dctId],
    chainId: chainID,
    enabled: walletPurchaseData?.evmMintMetadata?.chainId && isWagmiConnected,    
    onSuccess(d:boolean) {      
      console.log('Sign-DeList', d)
    }, 
  })
  const { chain } = useNetwork();
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [contract, setContract] = useState(null);
  const code = pathname.split('/').includes('gift') || pathname.split('/').includes('dct')
        ? pathname.split('/').pop()
        : walletPurchaseData?.purchaseHash

  const { switchNetwork, isSuccess } = useSwitchNetwork({ 
    chainId: chainID ,
    onSuccess(data) {
      setActivationLoading(false) 
      if(isReadSuccess&&!readData){ 
        signTypedData()
      }else if(isReadSuccess&&readData){
        handleActivation({ code: code },chain?.id!)
      }       
     // signTypedData()      
    },
  })

  const segmentData = () => {
    return {
      id: walletPurchaseData?.id,
      activationCode: walletPurchaseData?.activationCode,
      dataLimitInBytes: walletPurchaseData?.dataLimitInBytes,
      dataUsageRemainingInBytes: walletPurchaseData?.dataUsageRemainingInBytes,
      isProviderPurchased: walletPurchaseData?.isProviderPurchased,
      purchaseHash: walletPurchaseData?.purchaseHash,
      type: walletPurchaseData?.type,
      poolId: walletPurchaseData?.poolId,
      activationPeriod: walletPurchaseData?.offer?.activationPeriod,
      active: walletPurchaseData?.offer?.active,
      connectionType: walletPurchaseData?.offer?.connectionType,
      currency: walletPurchaseData?.offer?.currency,
      description: walletPurchaseData?.offer?.description,
      name: walletPurchaseData?.offer?.name,
      networkType: walletPurchaseData?.offer?.networkType,
      providerId: walletPurchaseData?.offer?.providerId,
      region: walletPurchaseData?.offer?.region,
      retailPrice: walletPurchaseData?.offer?.retailPrice,
      validFromActivation: walletPurchaseData?.offer?.validFromActivation,
      paymentId: walletPurchaseData?.paymentId?.id,
      paidAmount: walletPurchaseData?.paidAmount,
      paidChain: walletPurchaseData?.paidChain,
      paidCurrency: walletPurchaseData?.paidCurrency,
    }
  }
  useEffect(() => {
    setActivateUrl(`${window.location.origin}/dct/${shortenedAddress(walletPurchaseData?.purchaseHash)}`)
  }, [walletPurchaseData])

  const handleTooltip = () => {
    setTooltipTimer(true)
    setTimeout(() => {
      setTooltipTimer(false)
    }, 2000)
  }

  useEffect(() => {
    if (redeemStatus === true && purchaseType === PURCHASE_TYPE_BUY) {
      activatePurchase(walletPurchaseData?.id, userLogin)
        .then((res: any) => {
          setRetryState(true)
        })
        .catch((e) => {
          toast.error('Error activating purchase')
          setActivationLoading(false)
        })
    }
  }, [redeemStatus])

  const getActivationCode = () => {
    let activationTimeout: any
    setActivationLoading(true)
    purchaseclient
      .getPurchaseByCode(
        pathname.split('/').includes('gift') || pathname.split('/').includes('dct')
          ? pathname.split('/').pop()
          : walletPurchaseData?.purchaseHash,
        userLogin,
      )
      .then((res: any) => {
        handleActivatedPurchaseData(res?.purchase)
        handleWalletPurchaseData(res?.purchase)
        if (
          res?.purchase?.dctStatus === 'minted' ||
          res?.purchase?.dctStatus === 'inactive' ||
          !res?.purchase?.activationCode
        ) {
          activationTimeout = setTimeout(() => {
            getActivationCode()
          }, 2000)
        } else {         
          setActivationLoading(false)
          setPurchaseModalLoading(false)
          setActiveStep(SCAN)
        }
      })
      .catch((err) => {
        clearTimeout(activationTimeout)
        toast.error(
          pathname.split('/').includes('gift')
            ? 'Invalid gift code! Please try again.'
            : 'Invalid dct code! Please try again.',
        )
        setActivationLoading(false)
        setPurchaseModalLoading(false)
        handleClose()
        navigate('/shop')
      })
  }

  const handleActivation = async (param:any,chain_id:number) => {
   // console.log(param)
   // console.log('chain_id-'+chain_id)
    setActivationLoading(true)    
    activateDctV3(
      param,
      userLogin,
      chain_id
    )
      .then((res: any) => {
        setActivationError([])
        if (res?.status) {
          setActivationLoading(false)
          setPurchaseModalLoading(false)
          getActivationCode()
        } else if(!res?.status&&res?.retryCount < 3) {  
          setActivationLoading(false)       
          toast.error(res?.message)
        }else if (!res?.status&&res?.retryCount >= 3){
          setActivationLoading(false) 
          setPurchaseModalLoading(false)
          setOpenActivationFailureModal(true)
          setActivationError(res?.message?res?.message?.split('.').filter(Boolean):[])
          toast.error(res?.message)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(err?.response?.data?.message)
        setActivationLoading(false)
      })      
  }

  const signer = useEthersSigner()
  const provider = useEthersProvider({chainId:chainID});
  
  const EIP712DomainType = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' }
  ]

  const ForwardRequestType = [
    { name: 'from', type: 'address' },
    { name: 'to', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'gas', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'data', type: 'bytes' }
  ]

  const TypedData = {
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId: chainID,
      verifyingContract: ForwarderAddress,
    },
    primaryType: 'ForwardRequest',
    types: {
      EIP712Domain: EIP712DomainType,
      ForwardRequest: ForwardRequestType
    },
    message: {}
  };
  
  const switchChain = async () => { 
    //await refetchIsStaked?.()
    setActivationLoading(true)    
    console.log('isReadSuccess-'+isReadSuccess+' readData-'+readData)
    if(switchNetwork && chain?.id !== chainID ){ switchNetwork(chainID)}    
    else {
      if(isReadSuccess&&!readData){ 
        await signTypedData()
      }else if(isReadSuccess&&readData){
         handleActivation({ code: code },chain?.id!)
      }  
    }
 }
  
  const signTypedData = async () => {     
      const dctId = walletPurchaseData?.dctId      
      const from = address;
      try {
    // Get nonce for current signer
      const forwarder = new ethers.Contract(ForwarderAddress, forwarderAbi, provider);
      const nonce:any = await forwarder.getNonce(from).then((nonce:any) => nonce.toString());
    // Encode meta-tx request
      const boxesInterface = await new ethers.utils.Interface(erc1155_ABI);
      const data = await boxesInterface.encodeFunctionData('stake', [dctId,from]);
      const request = {
        from,
        to: ERC1155_ADDRESS,
        value: 0,
        gas: 1e6,
        nonce,
        data
      };
      const toSign = { ...TypedData, message: request };  
      try { 
        setActivationLoading(true)
        const jsonRpcProvider = provider as ethers.providers.JsonRpcProvider  
        const signature = await jsonRpcProvider.send('eth_signTypedData_v4', [from, JSON.stringify(toSign)]);        
        handleActivation({ ...request, signature, code },chain?.id!)
       // handleStakeNFT(chain?.id!, chain?.name!,dctId,request,signature)
      } catch (error) {
        setActivationLoading(false)
        toast.error('User denied signature')
      }  
    } catch (error) {
      setActivationLoading(false)
      toast.error('Error in getting signature')
    }   
  }
  const handleActivateNow = async () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Activate Now button',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData(),
            screenName: pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
          },
        },
        userLogin,
      )
    }
    if (!activationLoading) {     
      isWagmiConnected?await switchChain():handleActivation({ code: code },chain?.id!)
    }
  }
 /* const handleStakeNFT = async (chain_id:number, chain_name:string,dctId:number,request:any,signature:any) => {
    if (userLogin) {      
      stakingClient.stakeNFT(                
        userLogin,
        { ...request, signature, chain_id, chain_name, dctId },
      ).then((res: any) => {
        setActivationLoading(false)
        //refetchIsStaked?.()
        res.status?handleActivation():toast.error(res?.message?res.message:'stakeNFT error!')
      })
    }    
  }
  */
  const handleGoTOMyPage =()=>{
    setPurchaseOrderType(PURCHASE_TYPE_SINGLE_BUY);
    navigate('/mypage');
    handleClose();
  }

  return (<>
    {openActivationFailureModal?
      <ActivationFailure handleClose = { handleClose } messageArray = { activationError }/>:  
      <B.ActivateContainer>      
        {(purchaseOrderType !== PURCHASE_TYPE_BULK_BUY)?(
              <B.ActivateContents>
                {!selectedOfferType || walletPurchaseData.dctStatus === 'minted' ? (
                  <B.InfoContainer>
                    {searchParams.get('status') ? (
                      <B.SuccessText>{t('activate_modal.congrats')}</B.SuccessText>
                    ) : (
                      <B.SuccessText>{t('activate_modal.activate')}</B.SuccessText>
                    )}
                    {medium && (
                      <B.ImgContainer medium={medium}>
                        <B.PurchaseSuccessImg src={PaymentSuccessIllustaration} alt="" />
                      </B.ImgContainer>
                    )}
                    <B.ActivateMessage>
                    {t('activate_modal.activate-message.first')}{' '}
                      <Typography component="span" sx={{ fontWeight: 700, margin: 'unset' }}>
                      {t('activate_modal.activate-message.second')}
                      </Typography>
                      {' '}{t('activate_modal.activate-message.third')}
                    </B.ActivateMessage>
                    <B.URLContainer>
                      <Tooltip title={tooltipTimer ? 'copied!' : ''} arrow>
                        <B.TextBox
                          onClick={() => {
                            navigator.clipboard.writeText(`${window.location.origin}/dct/${walletPurchaseData?.purchaseHash}`)
                            handleTooltip()
                            // handleSegment('Copied Activation link', {
                            //   build: process.env.REACT_APP_CURRENT_CHAIN,
                            //   offer: segmentData(),
                            //   'activation link': `${window.location.origin}/dct/${walletPurchaseData?.purchaseHash}`,
                            //   screenName: pathname === '/' ? 'Home' : 'Shop',
                            // })
      
                            if (userLogin) {
                              userClient.postUserEventsWithAuth(
                                {
                                  eventName: 'Copied Activation link',
                                  properties: {
                                    build: process.env.REACT_APP_CURRENT_CHAIN,
                                    offer: segmentData(),
                                    'activation link': `${window.location.origin}/dct/${walletPurchaseData?.purchaseHash}`,
                                    screenName: pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
                                  },
                                },
                                userLogin,
                              )
                            }
                          }}
                        >
                          <B.URLText>{activateUrl}</B.URLText>
                          <img
                            src={copyIcon}
                            alt="Copy Icon"
                            style={{ cursor: 'pointer', height: '1rem' }}
                            onClick={() => {
                              navigator.clipboard.writeText(`${window.location.origin}/dct/${walletPurchaseData?.purchaseHash}`)
                              handleTooltip()
                            }}
                          />
                        </B.TextBox>
                      </Tooltip>
                      <B.ActivateDays>
                        {`(you have ${
                          walletPurchaseData?.offer?.activationPeriod
                            ? walletPurchaseData?.offer?.activationPeriod
                            : offerData?.activationPeriod
                        } ${
                          (walletPurchaseData?.offer?.activationPeriod
                            ? walletPurchaseData?.offer?.activationPeriod
                            : offerData?.activationPeriod) === '1' ||
                          (walletPurchaseData?.offer?.activationPeriod
                            ? walletPurchaseData?.offer?.activationPeriod
                            : offerData?.activationPeriod) === 1
                            ? 'day'
                            : 'days'
                        } to activate this eSIM)`}
                      </B.ActivateDays>
                    </B.URLContainer>
                  </B.InfoContainer>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.3125rem' }}>
                    {searchParams.get('status') ? (
                      <B.SuccessText>{t('activate_modal.congrats')}</B.SuccessText>
                    ) : (
                      <B.SuccessText>{t('activate_modal.activate')}</B.SuccessText>
                    )}
                    <B.ActivateMessage>
                    {t('activate_modal.activate-message.fourth')}{' '}
                      <Typography component="span" sx={{ fontWeight: 700, margin: 'unset' }}>
                      {t('activate_modal.activate-message.second')} <br />
                      </Typography>
                    </B.ActivateMessage>
                  </Box>
                )}
      
                {selectedOfferType && walletPurchaseData.dctStatus !== 'minted' ? (
                  <PrimaryButton
                    style={{ height: '3.125rem', width: '11.375rem' }}
                    onClick={() => {
                      setActivationLoading(false)
                      setPurchaseModalLoading(false)
                      setActiveStep(SCAN)
                      if (userLogin) {
                        userClient.postUserEventsWithAuth(
                          {
                            eventName: 'Configure Button Clickedd',
                            properties: {
                              build: process.env.REACT_APP_CURRENT_CHAIN,
                              offer: segmentData(),
                              screenName: pathname === '/' ? 'Home' : 'Shop',
                            },
                          },
                          userLogin,
                        )
                      }
                    }}
                  >
                    {t('activate_modal.button.configure-now')}
                  </PrimaryButton>
                ) : (
                <Box sx={{display:'flex',alignItems:'center'}}>
                 <PrimaryButton
                    style={{ height: '3.125rem', width: '11.25rem' }}
                    onClick={handleActivateNow}
                    disable={activationLoading}
                  >
                    {activationLoading ? <S.ContrastProgress /> : t('activate_modal.button.activate-now')}
                  </PrimaryButton>
                  { isWagmiConnected && <AddEsimButton dctId={walletPurchaseData?.dctId?.toString()}/>}
                </Box>                  
                )}
              </B.ActivateContents>
            ):(
              <B.ActivateContents>
                {!selectedOfferType || walletPurchaseData.dctStatus === 'minted' ? (
                  <B.InfoContainer>
                    <B.SuccessText>{t('activate_modal.congrats')}</B.SuccessText>
                    {medium && (
                      <B.ImgContainer medium={medium}>
                        <B.PurchaseSuccessImg src={PaymentSuccessIllustaration} alt="" />
                      </B.ImgContainer>
                    )}
                    <B.ActivateMessage>
                    {t('activate_modal.activate-message.fifth')} {' '}
                      <Typography component="span" sx={{ fontWeight: 700, margin: 'unset' }}>
                      {t('activate_modal.activate-message.second')}. <br />
                      </Typography>
                    </B.ActivateMessage>              
                  </B.InfoContainer>
                ) : (null)}
      
                {selectedOfferType && walletPurchaseData.dctStatus !== 'minted' ? (
                  <PrimaryButton
                    style={{ height: '3.125rem', width: '12.25rem' }}
                    onClick={handleGoTOMyPage}
                  >
                    {t('activate_modal.button.goto-my-page')}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    style={{ height: '3.125rem', width: '12.25rem' }}
                    onClick={handleGoTOMyPage}
                  >
                    {t('activate_modal.button.goto-my-page')}
                  </PrimaryButton>
                )}
              </B.ActivateContents>
            )}
            <B.ImgContainer>
             <B.PurchaseSuccessImg src={PaymentSuccessIllustaration} alt="" />
            </B.ImgContainer>
        </B.ActivateContainer> } 
  </>)
}

export default ActivateBuy
