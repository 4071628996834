import styled from '@emotion/styled'
import { AddBox } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

export namespace S {
  export const ModalMainContainer = styled(Box)(({ theme }: any) => ({
    // width: '60.5%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F1F2F6',
    borderRadius: '15px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    // padding: '0 2.5rem',
    color: theme.palette.text.primary,
    padding: '2rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    [theme.breakpoints.up('md')]: {
      minHeight: 396,
      minWidth: 633,
    },

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      maxHeight: '95vh',
      height: '85vh',
    },
  }))

  export const NetworkModalHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.75rem',
  }))

  export const HeaderSubContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.75rem',
  }))

  export const NetworkModalBody = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '100%',
    minHeight: '9rem',
    maxHeight: '12rem',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }))

  export const HeaderText = styled(Box)(({ theme }: any) => ({
    fontSize: '1.5rem',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
  }))

  export const HeaderSubText = styled(Typography)(({ theme }: any) => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    color: 'rgba(11, 33, 62, 0.6)',
  }))

  export const SingleItem = styled(Box)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '14px',
    padding: '1.25rem 1rem 1.25rem 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.07);',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
  }))

  export const LteBox = styled(Box)(({ theme }: any) => ({
    border: '1px solid black',
    height: '1rem',
    width: '1.75rem',
    borderRadius: '2px',
    display: 'grid',
    placeContent: 'center',
  }))

  export const CustomInput = styled.input(({ theme }: any) => ({
    border: 'none',
    borderRadius: '4px',
    background: '#ced6e040',
    height: '52px',
    padding: '0 0.875rem 0 2.5rem',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
  }))

  export const InputContainer = styled(Box)(({ theme }: any) => ({
    position: 'relative',
    width: '100%',
  }))
  export const SearchIconImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    top: '1.1rem',
    left: '1rem',
  }))

  export const CloseIconContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    right: '-3.5rem',
    top: '-3.5rem',
    height: '3.0625rem',
    width: '3.0625rem',
    background: theme.palette.primary.main,
    borderRadius: '0.6875rem',
    display: 'grid',
    placeContent: 'Center',
    cursor: 'pointer',
  }))

  export const NetworksHeaderText = styled(Box)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
  }))
}
