import { useEffect, useState } from 'react'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'

export const useGetStakeModalData = () => {
  // Contexts
  const { setShowStakeModal } = useModalContext()
  const { allProviders, userLogin } = useWalletContext()

  // States
  const [selectedProvider, setSelectedProvider] = useState<any>({ value: '', label: '', poolId: '' })
  const [providerList, setProviderList] = useState<any>([])
  const [amount, setAmount] = useState<string>('')
  const [rowsCount, setRowsCount] = useState<number>(5)

  // Effects
  useEffect(() => {
    manipulateDropDownData()
  }, [allProviders, userLogin])

  const manipulateDropDownData = async () => {
    let temp: any = []
    let filteredProviders = allProviders.filter((provider: any) => provider?.status === 'active')
    filteredProviders.forEach((provider: any) => {
      temp.push({
        value: provider.displayName,
        label: provider.displayName,
        logo: provider.logo,
        poolId: provider.poolId,
      })
    })

    setProviderList(temp)
  }

  const handleClose = () => {
    setShowStakeModal({
      show: false,
      type: 'stake',
    })
  }

  return {
    handleClose,
    providerList,
    setProviderList,
    selectedProvider,
    setSelectedProvider,
    amount,
    setAmount,
    rowsCount,
    setRowsCount,
  }
}
