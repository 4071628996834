import React, { useState, useEffect, ChangeEvent } from 'react'
import { Modal, Box, CircularProgress } from '@mui/material'

import { useModalContext } from 'contexts/modal.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'

import ReviewStar from '../../../assets/icons/PostReviewStar.svg'
import ReviewStarGreen from '../../../assets/icons/PostReviewGreen.svg'
import { FEEDBACK_STARS, FEEDBACK_CAROUSEL } from 'utils/constants'
import { purchaseclient } from '../../../services/http/purchases.service'
import { P } from './PurchaseSurvey.styles'

interface FeedbackData {
  type: string;
  user: string | undefined;
  stars: number;
  questions: {
    q1: string;
    a1: string;
    q2: string;
    a2: string;
  };
  purchaseId: string | null;
}

const PurchaseSurvey: React.FC = () => {
  // Contexts
  const {
    postPurchaseSurvey,
    setpostPurchaseSurvey,
    feedbackState,
    setFeedbackState,
    getPurchaseId,
    setActivateNow,
  } = useModalContext()
  
  const { walletPurchaseData, paymentSuccess } = usePaymentContext()
  const { userLogin, userDetails, setShowPurchaseModal, showPurchaseModal } = useWalletContext()

  // State
  const [carouselValue, setCarouselValue] = useState(2)
  const [feedbackQuestion, setFeedbackQuestion] = useState<any>({})
  const [feedbackId, setFeedbackId] = useState<string>('')
  const [feedbackData, setFeedbackData] = useState<FeedbackData>({
    type: 'payment-feedback',
    user: userDetails?.user?.id,
    stars: 0,
    questions: { q1: '', a1: '', q2: '', a2: '' },
    purchaseId: null,
  })

  useEffect(() => {
    if (userLogin) {
      purchaseclient.feedbackStarReview(userLogin).then((questions: any) => {
        setFeedbackQuestion(questions)
      })
    }
  }, [userLogin])

  const handleNext = () => {
    const isPoorRating = feedbackData.stars <= 3
  
    if (feedbackData.stars > 0) {
      if (carouselValue === 2) {
        setFeedbackState(isPoorRating ? 'STAR_RATE_POOR' : 'STAR_RATE_GOOD')
        setCarouselValue((prev) => prev + 1)
  
        // Update feedbackData with purchaseId and user directly in the next state
        const updatedFeedbackData = {
          ...feedbackData,
          questions: {
            ...feedbackData.questions,
            q1: isPoorRating ? feedbackQuestion?.paymentFeedback?.bq1 : feedbackQuestion.paymentFeedback.gq1,
            q2: isPoorRating ? feedbackQuestion?.paymentFeedback?.bq2 : feedbackQuestion.paymentFeedback.gq2,
          },
          purchaseId: getPurchaseId,
          user: userDetails?.user?.id,
        }
  
        setFeedbackData(updatedFeedbackData)
  
        // Pass the updated data to handlePostFeedback directly
        handlePostFeedback(updatedFeedbackData)
      } else {
        setCarouselValue(4)
        setFeedbackState('STAR_RATE_REVIEW')
      }
  
      if (carouselValue === 3 || carouselValue === 4) handleUpdateFeedback()
    }
  }

  const handleTextInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFeedbackData((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        [name]: value,
      },
    }))
  }

  const handlePostFeedback = (updatedFeedbackData:any) => {
    purchaseclient.postFeedback(updatedFeedbackData, userLogin).then((res: any) => {
      setFeedbackId(res._id)
    })
  }

  const handleUpdateFeedback = () => {
    purchaseclient.updateFeedback(feedbackId, feedbackData, userLogin).then(() => {
      if (carouselValue === 4 && paymentSuccess) {
        setActivateNow(true)
        setShowPurchaseModal(true)
        setpostPurchaseSurvey(false)
      }
    })
  }

  const handleClose = () => {
    setpostPurchaseSurvey(false)
    if (paymentSuccess) {
      setActivateNow(true)
      setShowPurchaseModal(true)
    }
  }

  const getDisableCondition = () => {
    const { stars, questions } = feedbackData;
    if (feedbackData.stars === 0) return true; // Disable if no stars are selected
    if (carouselValue === 3) {
      return questions.a1.length === 0
    } else if (carouselValue === 4) {
      return stars >= 3 ? questions.a2.length === 0 : questions.a1.length === 0
    }
    return false
  }

  return (
    <Modal open={postPurchaseSurvey} onClose={handleClose}>
      <P.PurchaseSurveyContainer>
        <P.PurchaseSurvey>
          <P.SkipSurveyContainer>
            <P.SubContainerHeader onClick={handleClose}>Skip survey</P.SubContainerHeader>
          </P.SkipSurveyContainer>

          <P.SubContainer>
            {feedbackState === '' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.mq}</P.SubContainerTitle>
                <P.StarReview>
                  {FEEDBACK_STARS.map((_, index) => (
                    <Box
                      key={index}
                      onClick={() => setFeedbackData((prev) => ({ ...prev, stars: index + 1 }))}
                    >
                      <img src={feedbackData.stars > index ? ReviewStarGreen : ReviewStar} alt="star icon" />
                    </Box>
                  ))}
                </P.StarReview>
              </>
            )}

            {feedbackState === 'STAR_RATE_POOR' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.bq1}</P.SubContainerTitle>
                <P.FeedbackInput
                  placeholder="Type your answer here"
                  maxLength={300}
                  name="a1"
                  value={feedbackData.questions.a1}
                  onChange={handleTextInput}
                />
              </>
            )}

            {feedbackState === 'STAR_RATE_GOOD' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.gq1}</P.SubContainerTitle>
                <P.FeedbackInput
                  placeholder="Type your answer here"
                  maxLength={300}
                  name="a1"
                  value={feedbackData.questions.a1}
                  onChange={handleTextInput}
                />
              </>
            )}

            {feedbackState === 'STAR_RATE_REVIEW' && (
              <>
                <P.SubContainerTitle>{feedbackQuestion?.paymentFeedback?.bq2}</P.SubContainerTitle>
                <P.FeedbackInput
                  placeholder="Type your answer here"
                  maxLength={300}
                  name="a2"
                  value={feedbackData.questions.a2}
                  onChange={handleTextInput}
                />
              </>
            )}

            <P.DisableButton
              style={{ height: '3.125rem', width: '8.75rem' }}
              onClick={handleNext}
              disabled={getDisableCondition()}
            >
              Next
            </P.DisableButton>
            <P.CarouselContainer>
              {FEEDBACK_CAROUSEL.map((index) => (
                <P.CarouselStep
                  key={index}
                  style={{ background: carouselValue > index ? '#18BC46' : '#DEE7FD' }}
                ></P.CarouselStep>
              ))}
            </P.CarouselContainer>

            {feedbackQuestion === '' && (
              <P.SubContainer sx={{ padding: '0!important' }}>
                <CircularProgress />
              </P.SubContainer>
            )}
          </P.SubContainer>
        </P.PurchaseSurvey>
      </P.PurchaseSurveyContainer>
    </Modal>
  )
}

export default PurchaseSurvey
