import React, { FC } from 'react'
interface Props {
  children: React.ReactNode
}

const Content: FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        marginTop: '5rem',
        minHeight: '42rem',
        display: process.env.REACT_APP_MAINTENANCE === 'true' ? 'flex' : 'block',
        justifyContent: process.env.REACT_APP_MAINTENANCE === 'true' ? 'center' : 'unset',
        alignItems: process.env.REACT_APP_MAINTENANCE === 'true' ? 'center' : 'unset',
      }}
    >
      {children}
    </div>
  )
}

export default Content
