import { apiProvider } from 'polkadot'

export const getCreditsFromChain = async (connectedAccount: any) => {
  try {
    const res = await apiProvider.query.credits.account(connectedAccount)
    let parsed = JSON.parse(res)
    return parsed.free
  } catch (e) {
    return null
  }
}
