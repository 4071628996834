import { FC } from 'react'
import { S } from './styled'
import MetamaskLogo from 'assets/icons/MetamaskLogo.png'
import CoinbaseWalletLogo from 'assets/icons/CoinbaseWalletLogo.png'
import { useAccount } from 'wagmi'
import { toast } from 'react-toastify'

interface Props {   
  dctId: string  
}

const AddEsimButton: FC<Props> =({ dctId }) => {
  const { connector } = useAccount()

  const handleAddNFT = async() => { 
    if(connector?.id ==='metaMask'){
     try {
       const result = await window.ethereum.request({
         "method": "wallet_watchAsset",
         "params": {
           "type": "ERC1155",
           "options": {
            "address": process.env.REACT_APP_ERC1155_ADDRESS,
            "symbol": "GIANT",
            "decimals": 0,
            "tokenId": dctId?.toString()
          }
        }
      })
      result&&toast.success('eSIM added to the wallet')
     } catch (error:any) {
      toast.error(error?.message)
      console.log(error, 'error')
     }
    }else{
      try {
       await connector?.watchAsset?.({      
        address: process.env.REACT_APP_ERC1155_ADDRESS!,
        symbol: "GIANT",
        decimals: 0,
       })
      } catch (error:any) {
        toast.error(error?.message)
        console.log(error, 'error')
       }
    }    
  }
  return (
    <S.ImportBtn
          onClick={() => {
            handleAddNFT()
          }}
        ><S.BtnDiv><img style={{ height: '22px', display: 'flex', marginRight: '10px', opacity:'0.8' }} src={connector?.id ==='metaMask'?MetamaskLogo:CoinbaseWalletLogo} alt="Metamask" /></S.BtnDiv>
              Add eSIM to Wallet
    </S.ImportBtn>
  )
}

export default AddEsimButton
