import { TypeRegistry } from '@polkadot/types'
import BigNumber from 'bignumber.js'
import { apiProvider, getExtrinsicFailed } from 'polkadot'
import { toast } from 'react-toastify'
import { currencyToNumber, toDecimal } from 'utils'

const eventName = (ev: any) => `${ev.section}:${ev.method}`
const eventParams = (ev: any) => JSON.stringify(ev.data)
const registry = new TypeRegistry()

type GET_BALANCE = (address: any) => Promise<any>

export const txEventHandler = () => {
  apiProvider.query.system.events((events: any) => {
    // loop through the Vec<EventRecord>
    getExtrinsicFailed(events)
    events.forEach((record: any) => {
      // extract the phase, event and the event types
      const { event, phase } = record

      // show what we are busy with
      const evHuman = event.toHuman()
      const evName = eventName(evHuman)
      const evParams = eventParams(evHuman)
      const evNamePhase = `${evName}::(phase=${phase.toString()})`
      switch (evHuman.method) {
        case 'InstanceMinted':
          toast.success(evHuman.method, {
            toastId: 'eventMethod1',
          })
          break
        case 'ExtrinsicFailed':
          toast.error(evHuman.method, {
            toastId: 'eventMethod2',
          })
          break
        case 'Staked':
          toast.success(evHuman.method, {
            toastId: 'eventMethodStaked',
          })
          break
        default:
          break
      }
      // evMessage[evHuman.method]
    })
  })
}

///////// Need to fetch the balance every 5 secs and should shown the updated balance on wallet info button

export const getBalance: GET_BALANCE = async (address: any) => {
  try {
    let balance = {
      full: '0',
      deci: '0',
    }

    //////// To get current stake and reward balance of the account

    //get balance of first account of an array
    if (address?.address !== undefined) {
      let res = await apiProvider.query.system.account(address.address)
      let frozenFreeBalance = new BigNumber(currencyToNumber(res.data.free.toHuman()))
        .minus(currencyToNumber(res.data.miscFrozen.toHuman()))
        .toString()
      let readableBalance = res.data.free.toHuman()
      balance = toDecimal(frozenFreeBalance)
    }
    return balance
  } catch (err: any) {
    console.log(err)
  }
}

export const retrieveChainInfo = async () => {
  const [systemChain, systemChainType] = await Promise.all([
    apiProvider.rpc.system.chain(),
    apiProvider.rpc.system.chainType
      ? apiProvider.rpc.system.chainType()
      : Promise.resolve(registry.createType('ChainType', 'Live')),
  ])

  return {
    systemChain: (systemChain || '<unknown>').toString(),
    systemChainType,
  }
}
