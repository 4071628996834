import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'

import PrimaryButton from 'components/buttons/PrimaryButton'
import PolkadotButton from 'polkadot/PolkadotButton'
import { useWalletContext } from 'contexts/wallet.context'
import GiftIllustration from 'assets/GiftIllustration.svg'
import RedDot from 'assets/icons/RedDot.svg'
import YellowDot from 'assets/icons/YellowDot.svg'
import GreenDot from 'assets/icons/GreenDot.svg'
import PurchaseSuccess from 'assets/PurchaseSuccess.svg'
import { useTranslation } from 'react-i18next'

import { S } from './Receiver.styles'
import { usePaymentContext } from 'contexts/payment.context'
import { convertToPascalCase } from 'utils'
import PlanDetailsCard from 'components/cards/PlanDetailsCard'

interface Props {
  handleClose?: any
}
const ActivatedPlan: FC<Props> = ({ handleClose }) => {
  const { walletPurchaseData, currentLoggedUserData } = usePaymentContext()
  const { isWagmiConnected, isConnected } = useWalletContext()
  const mobileView = useMediaQuery('(max-width:599.95px)')
  const medium = useMediaQuery('(max-width:899.95px)')
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <S.ActivatedContainer>
      <S.ActivatedBodyLeftContent>
        <S.ActivatedStatusBody>
          <Box
            sx={{
              display: 'flex',
              justifyContent: medium ? 'center' : 'space-between',
              paddingTop: medium ? '2rem' : '0',
            }}
          >
            <S.StatusTextContainer>            
            {t('mypage_data-plans-card.status')} :&nbsp;{' '}
              <img
                src={
                  walletPurchaseData?.esimStatus?.toLowerCase() === 'error' ||
                  walletPurchaseData?.esimStatus?.toLowerCase() === 'expired' ||
                  walletPurchaseData?.esimStatus?.toLowerCase() === 'deleted' ||
                  walletPurchaseData?.esimStatus?.toLowerCase() === 'disabled'
                    ? RedDot
                    : walletPurchaseData?.esimStatus?.toLowerCase() === 'inactive' || !walletPurchaseData?.esimStatus
                    ? YellowDot
                    : GreenDot
                }
                alt="active logo"
                style={{ width: '23px', height: '23px' }}
              />
              &nbsp;
              <S.StatusText>
                {!walletPurchaseData?.esimStatus
                  ? t('status_texts.inactive')
                  : walletPurchaseData?.daysleft <= 0
                  ? t('status_texts.expired')
                  : convertToPascalCase(walletPurchaseData?.esimStatus)}
              </S.StatusText>
            </S.StatusTextContainer>
          </Box>
          {medium &&
            (walletPurchaseData.type !== 'self' ? (
              <S.GiftImgContent medium={medium}>
                <S.GiftImg src={GiftIllustration} alt="" />
              </S.GiftImgContent>
            ) : (
              <S.BuyImgContent medium={medium}>
                <S.BuyImg src={PurchaseSuccess} alt="" />
              </S.BuyImgContent>
            ))}

          <PlanDetailsCard data={walletPurchaseData} isDisplayRaward={false} />
        </S.ActivatedStatusBody>
        <S.ActivatedStatusSubBody medium={medium}>
          {(
            walletPurchaseData?.user?.id
              ? !walletPurchaseData?.user?.polkadotAccountAddress
              : !currentLoggedUserData?.polkadotAccountAddress
          ) ? (
            <>
              <S.StatusTextContainer sx={{ lineHeight: '31px !important', fontSize: '18px !important' }}>
              {t('activatedplan_modal.message.five')}
                <br /> {t('activatedplan_modal.message.six')}
              </S.StatusTextContainer>
              <Box sx={{ padding: medium ? '1rem 0' : '0',display: 'flex',justifyContent: 'center' }}>
                {isWagmiConnected || isConnected ?                
                <PrimaryButton
                style={{
                  height: '50px',
                  width: mobileView ? '80%' : '20.4375rem',
                }}
                onClick={() => {
                  handleClose()
                  navigate('/mypage')
                }}
              >
                {t('activate_modal.button.goto-my-page')}
              </PrimaryButton>:
              <PolkadotButton style={{ width: mobileView ? '100%' : '28.25rem' }} connectToPolkadot={true} />
              }
              </Box>
            </>
          ) : (
            <>
              {walletPurchaseData?.type === 'gift' ? (
                <>
                  <S.StatusTextContainer sx={{ lineHeight: '31px !important', fontSize: '18px !important' }}>
                  {t('activatedplan_modal.message.three')}
                    <br /> {t('activatedplan_modal.message.four')}
                  </S.StatusTextContainer>
                  <Box sx={{ padding: medium ? '1rem 0' : '0' }}>
                    <PrimaryButton
                      style={{
                        height: '50px',
                        width: mobileView ? '100%' : '20.4375rem',
                      }}
                      onClick={() => {
                        handleClose()
                        navigate('/shop')
                      }}
                    >
                      {t('activatedplan_modal.button.gift-and-earn')}
                    </PrimaryButton>
                  </Box>
                </>
              ) : (
                <>
                  <S.StatusTextContainer sx={{ lineHeight: '31px !important', fontSize: '16px !important' }}>
                  {t('activatedplan_modal.message.one')}
                    <br /> {t('activatedplan_modal.message.two')}
                  </S.StatusTextContainer>
                  <PrimaryButton
                    style={{
                      height: '50px',
                      width: mobileView ? '80%' : '20.4375rem',
                    }}
                    onClick={() => {
                      handleClose()
                      navigate('/mypage')
                    }}
                  >
                    {t('activate_modal.button.goto-my-page')}
                  </PrimaryButton>
                </>
              )}
            </>
          )}
        </S.ActivatedStatusSubBody>
      </S.ActivatedBodyLeftContent>
      {walletPurchaseData.type !== 'self' ? (
        <S.GiftImgContent>
          <S.GiftImg src={GiftIllustration} alt="" />
        </S.GiftImgContent>
      ) : (
        <S.BuyImgContent>
          <S.BuyImg src={PurchaseSuccess} alt="" />
        </S.BuyImgContent>
      )}
    </S.ActivatedContainer>
  )
}

export default ActivatedPlan
