import { createContext, useContext, useReducer } from 'react'
import { BUY } from 'utils/constants'

interface ModalContextType {
  cryptoPurchaseLoading: boolean
  activeStep: number
  receiverState: string
  activateNow: boolean
  androidSteps: any
  iosSteps: any
  disableVerification: boolean
  isModalDisabled: boolean
  showGuideModal: boolean
  showNetworkModal: any
  postPurchaseSurvey: boolean
  feedbackState: any
  getPurchaseId: string
  buyModalCloseSurvey: string
  showEarningDetailsModal: boolean
  showTopUpModal: boolean
  showDctStatusModal: boolean
  showTopUpConfirmModal: boolean
  openVerificationModal: boolean
  openActivationFailureModal:boolean
  showStakeModal: any
  earnMetaData: any
  showSoldModal: boolean
  soldModalData: any
  fetchMyProvidersList: boolean
  topUpMetaData: any
  setCryptoPurchaseLoading: (data: boolean) => void
  setActiveStep: (data: number) => void
  setReceiverState: (data: string) => void
  setActivateNow: (data: boolean) => void
  setAndroidSteps: (data: any) => void
  setIosSteps: (data: any) => void
  setDisableVerificationButton: (data: boolean) => void
  setDisableModal: (data: boolean) => void
  setShowGuideModal: (data: boolean) => void
  setShowNetworkModal: (data: any) => void
  setpostPurchaseSurvey: (data: boolean) => void
  setFeedbackState: (data: any) => void
  setPurchaseId: (data: any) => void
  setbuyModalCloseSurvey: (data: any) => void
  setShowEarningDetailsModal: (data: any) => void
  setShowStakeModal: (data: any) => void
  setEarnMetaData: (data: any) => void
  setShowSoldModal: (data: any) => void
  setSoldModalData: (data: any) => void
  setFetchMyProvidersList: (data: any) => void
  setTopUpMetaData: (data: any) => void
  setShowTopUpModal: (data: any) => void
  setShowDctStatusModal: (data: any) => void
  setTopUpConfirmModal: (data: any) => void
  setOpenVerificationModal:(data: boolean) => void
  setOpenActivationFailureModal:(data: boolean) => void
}

const initialState: ModalContextType = {
  cryptoPurchaseLoading: false,
  activeStep: BUY,
  receiverState: '',
  activateNow: false,
  androidSteps: { step1: false, step2: false, step3: false },
  iosSteps: {
    iosStep1: false,
    iosStep2: false,
    iosStep3: false,
  },
  disableVerification: false,
  isModalDisabled: false,
  showGuideModal: false,
  showNetworkModal: {
    data: [],
    type: 'network',
    isOpen: false,
    offer: {},
  },
  postPurchaseSurvey: false,
  feedbackState: '',
  getPurchaseId: '',
  buyModalCloseSurvey: '',
  showEarningDetailsModal: false,
  showStakeModal: {
    type: 'stake',
    show: false,
  },
  fetchMyProvidersList: false,
  earnMetaData: {},
  showSoldModal: false,
  soldModalData: {},
  showTopUpModal: false,
  showDctStatusModal:false,
  showTopUpConfirmModal:false,
  openVerificationModal:false,
  openActivationFailureModal:false,
  topUpMetaData: {},
  setCryptoPurchaseLoading: () => {},
  setActiveStep: () => {},
  setReceiverState: () => {},
  setActivateNow: () => {},
  setAndroidSteps: () => {},
  setIosSteps: () => {},
  setDisableVerificationButton: () => {},
  setDisableModal: () => {},
  setShowGuideModal: () => {},
  setShowNetworkModal: () => {},
  setpostPurchaseSurvey: () => {},
  setFeedbackState: () => {},
  setPurchaseId: () => {},
  setbuyModalCloseSurvey: () => {},
  setShowEarningDetailsModal: () => {},
  setShowStakeModal: () => {},
  setEarnMetaData: () => {},
  setShowSoldModal: () => {},
  setSoldModalData: () => {},
  setFetchMyProvidersList: () => {},
  setTopUpMetaData: () => {},
  setShowTopUpModal: () => {},
  setShowDctStatusModal: () => {},
  setTopUpConfirmModal:() => {},
  setOpenVerificationModal:() => {},
  setOpenActivationFailureModal:() => {},
}

const reducer = (state: ModalContextType, action: any) => {
  switch (action.type) {
    case 'SET_CRYPTO_PURCHASE_LOADING': {
      return { ...state, cryptoPurchaseLoading: action.payload }
    }
    case 'SET_ACTIVE_STEP': {
      return { ...state, activeStep: action.payload }
    }
    case 'SET_RECEIVER_STATE': {
      return { ...state, receiverState: action.payload }
    }
    case 'SET_ACTIVATE_NOW': {
      return { ...state, activateNow: action.payload }
    }
    case 'SET_ANDROID_STEPS': {
      return { ...state, androidSteps: action.payload }
    }
    case 'SET_IOS_STEPS': {
      return { ...state, iosSteps: action.payload }
    }
    case 'SET_DISABLE_VERIFICATION_BUTTON': {
      return { ...state, disableVerification: action.payload }
    }
    case 'DISABLE_MODAL': {
      return { ...state, isModalDisabled: action.payload }
    }
    case 'SHOW_GUIDE_MODAL': {
      return { ...state, showGuideModal: action.payload }
    }
    case 'SHOW_NETWORK_MODAL': {
      return { ...state, showNetworkModal: action.payload }
    }
    case 'POST_PUCHASE_SURVEY_MODAL': {
      return { ...state, postPurchaseSurvey: action.payload }
    }
    case 'FEEDBACK_STATE': {
      return { ...state, feedbackState: action.payload }
    }
    case 'PURCHASEID_FEEDBACK': {
      return { ...state, getPurchaseId: action.payload }
    }
    case 'BUYMODAL_CLOSE_POPUP_SURVEY': {
      return { ...state, buyModalCloseSurvey: action.payload }
    }
    case 'SHOW_EARNING_DETAILS_MODAL': {
      return { ...state, showEarningDetailsModal: action.payload }
    }
    case 'SHOW_TOPUPS_CONFIRM_MODAL': {
      return { ...state, showTopUpConfirmModal: action.payload }
    }
    case 'SHOW_VERIFICATION_MODAL': {
      return { ...state, openVerificationModal: action.payload }
    }
    case 'SHOW_ACTIVATIONFAILURE_MODAL': {
      return { ...state, openActivationFailureModal: action.payload }
    }    
    case 'SHOW_STAKE_MODAL': {
      return { ...state, showStakeModal: action.payload }
    }
    case 'SET_EARN_METADATA': {
      return { ...state, earnMetaData: action.payload }
    }
    case 'SET_SHOW_SOLD_MODAL': {
      return { ...state, showSoldModal: action.payload }
    }
    case 'SET_SOLD_MODAL_DATA': {
      return { ...state, soldModalData: action.payload }
    }
    case 'SET_FETCH_MY_PROVIDERS_LIST': {
      return { ...state, fetchMyProvidersList: action.payload }
    }
    case 'SET_SHOW_TOPUPS_LIST_MODAL': {
      return { ...state, showTopUpModal: action.payload }
    }
    case 'SET_SHOW_DCTSTATUS_MODAL': {
      return { ...state, showDctStatusModal: action.payload }    }
    
    case 'SET_TOPUP_METADATA': {
      return { ...state, topUpMetaData: action.payload }
    }
    default: {
      throw new Error(`Unknown type: ${action.type}`)
    }
  }
}

export const ModalContext = createContext<ModalContextType>(initialState)

const ModalProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  state.setCryptoPurchaseLoading = (data: boolean) => {
    dispatch({ type: 'SET_CRYPTO_PURCHASE_LOADING', payload: data })
  }
  state.setActiveStep = (data: number) => {
    dispatch({ type: 'SET_ACTIVE_STEP', payload: data })
  }
  state.setReceiverState = (data: string) => {
    dispatch({ type: 'SET_RECEIVER_STATE', payload: data })
  }
  state.setActivateNow = (data: boolean) => {
    dispatch({ type: 'SET_ACTIVATE_NOW', payload: data })
  }
  state.setAndroidSteps = (data: any) => {
    dispatch({ type: 'SET_ANDROID_STEPS', payload: data })
  }
  state.setIosSteps = (data: any) => {
    dispatch({ type: 'SET_IOS_STEPS', payload: data })
  }
  state.setDisableVerificationButton = (data: boolean) => {
    dispatch({ type: 'SET_DISABLE_VERIFICATION_BUTTON', payload: data })
  }
  state.setDisableModal = (data: boolean) => {
    dispatch({ type: 'DISABLE_MODAL', payload: data })
  }
  state.setShowGuideModal = (data: boolean) => {
    dispatch({ type: 'SHOW_GUIDE_MODAL', payload: data })
  }
  state.setShowNetworkModal = (data: boolean) => {
    dispatch({ type: 'SHOW_NETWORK_MODAL', payload: data })
  }
  state.setpostPurchaseSurvey = (data: boolean) => {
    dispatch({ type: 'POST_PUCHASE_SURVEY_MODAL', payload: data })
  }
  state.setFeedbackState = (data: boolean) => {
    dispatch({ type: 'FEEDBACK_STATE', payload: data })
  }
  state.setPurchaseId = (data: string) => {
    dispatch({ type: 'PURCHASEID_FEEDBACK', payload: data })
  }
  state.setbuyModalCloseSurvey = (data: string) => {
    dispatch({ type: 'BUYMODAL_CLOSE_POPUP_SURVEY', payload: data })
  }
  state.setShowEarningDetailsModal = (data: string) => {
    dispatch({ type: 'SHOW_EARNING_DETAILS_MODAL', payload: data })
  }
  state.setShowStakeModal = (data: string) => {
    dispatch({ type: 'SHOW_STAKE_MODAL', payload: data })
  }
  state.setEarnMetaData = (data: any) => {
    dispatch({ type: 'SET_EARN_METADATA', payload: data })
  }
  state.setFetchMyProvidersList = (data: any) => {
    dispatch({ type: 'SET_FETCH_MY_PROVIDERS_LIST', payload: data })
  }
  state.setShowSoldModal = (data: any) => {
    dispatch({ type: 'SET_SHOW_SOLD_MODAL', payload: data })
  }
  state.setSoldModalData = (data: any) => {
    dispatch({ type: 'SET_SOLD_MODAL_DATA', payload: data })
  }
  state.setTopUpMetaData =  (data: any) => {
    dispatch({ type: 'SET_TOPUP_METADATA', payload: data })
  }
  state.setShowTopUpModal = (data: string) => {
    dispatch({ type: 'SET_SHOW_TOPUPS_LIST_MODAL', payload: data })
  }
  state.setShowDctStatusModal = (data: string) => {
    dispatch({ type: 'SET_SHOW_DCTSTATUS_MODAL', payload: data })
  }  
  state.setTopUpConfirmModal = (data: string) => {
    dispatch({ type: 'SHOW_TOPUPS_CONFIRM_MODAL', payload: data })
  }
  state.setOpenVerificationModal = (data: boolean) => {
    dispatch({ type: 'SHOW_VERIFICATION_MODAL', payload: data })
  }
  state.setOpenActivationFailureModal = (data: boolean) => {
    dispatch({ type: 'SHOW_ACTIVATIONFAILURE_MODAL', payload: data })
  }  
  let data = {
    ...state,
  }
  return <ModalContext.Provider value={data}>{props.children}</ModalContext.Provider>
}

const useModalContext = () => useContext(ModalContext)

export { ModalProvider, useModalContext }
