import styled from '@emotion/styled'
import { Box, Link, Typography } from '@mui/material'

export namespace S {
  export const ReceiverContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '20px 35px 45px 50px',
    boxSizing: 'border-box',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: '26rem',
    gap: '2rem',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      height: '87vh',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      height: 'auto',
      overflow:'auto'
    },
  }))
  export const ReceiverContentContainer = styled(Box)(({ theme }: any) => ({
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.625rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }))
  export const ReceiverTitleText = styled(Box)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  }))
  export const ReceiverText = styled(Box)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))
  export const GiftSuccessImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    right: '0',
    bottom: '-4rem',
    height: '30rem',
    width: '22.0625rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }))
  export const GiftSuccessMobileImg = styled.img(({ theme }: any) => ({
    position: 'relative',
    right: '0',
    height: '20rem',
    alignSelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  }))
  export const GiftSuccessUpdatedImg = styled(GiftSuccessImg)(({ theme }: any) => ({
    height: '22.0625rem',
    width: '30rem',
    bottom: '0',
    display: 'block',
    right: '-2rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }))
  export const GiftSuccessUpdatedImgMobile = styled.img(({ theme }: any) => ({
    height: '15.0625rem',
    width: '25rem',
    bottom: '0',
    display: 'block',
    right: '-2rem',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  }))
  export const GiftFailedImg = styled(GiftSuccessImg)(({ theme }: any) => ({
    height: '18.375rem',
    width: '19.4375rem',
    bottom: '1rem',
    right: '2rem',
  }))
  export const DeviceToggleContainer = styled(Box)(({ theme }: any) => ({
    padding: '1.25rem 0 1.25rem 0',
    margin: '0 2.3125rem 0 2.5rem',
    borderBottom: `1px solid ${theme.palette.grey[700]}`,
    boxSizing: 'border-box',
  }))
  export const CustomLink = styled(Link)(({ theme }: any) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }))

  export const ActivatedContainer = styled(Box)(({ theme }: any) => ({
    padding: '2rem 0 1rem 2rem',
    display: 'grid',
    gridTemplateColumns: '60% auto',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      padding: '0 1rem',
      gridTemplateColumns: '1fr',

      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
    },
    '@media (max-width: 395px)': {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '46rem',
    },
  }))

  export const GiftImgContent = styled(Box)(({ theme, medium }: any) => ({
    position: 'relative',
    right: '-3rem',
    overflow: 'hidden',
    width: '380px',
    bottom: '-6rem',
    [theme.breakpoints.up('lg')]: {
      bottom: '-8rem',
      right: '-3rem',
      overflow: 'hidden',
      width: '400px',
      height: '408px',
    },

    [theme.breakpoints.down('lg')]: {
      bottom: '-8rem',
      right: '-3rem',
      overflow: 'hidden',
      width: '400px',
      height: '410px',
    },

    [theme.breakpoints.down('md')]: {
      display: medium ? 'flex' : 'none',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      right: '0',
      height: '300px',
      bottom: '0',
    },
  }))
  export const BuyImgContent = styled(Box)(({ theme, medium }: any) => ({
    width: '100%',
    height: 'auto',

    position: 'relative',
    top: '1rem',
    [theme.breakpoints.down('md')]: {
      display: medium ? 'flex' : 'none',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      right: '0',
      height: '300px',
      bottom: '0',
    },
  }))
  export const BuyImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    width: '356px',
    height: '370px',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '280px',
    },
  }))
  export const GiftImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    height: 'auto',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '300px',
    },
    [theme.breakpoints.down(380)]: {
      height: 'auto',
      width: '270px',
    },
  }))
  export const StatusTextContainer = styled(Typography)(({ theme }: any) => ({
    lineHeight: '23px',
    fontWeight: 700,
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))
  export const StatusText = styled(Typography)(({ theme }: any) => ({
    fontSize: '24px',
    lineHeight: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: theme.palette.grey[600],
  }))
  export const ProgressContainer = styled(Box)(({ theme }: any) => ({
    boxSizing: 'border-box',
    background: theme.palette.grey[800],
    minHeight: '108px',
    borderRadius: '8px',
    border: '1px solid #DEE7FD',
    padding: '0.75rem 0.625rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  }))
  export const ActivatedBodyLeftContent = styled(Box)(({ theme }: any) => ({
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    gap: '28px',

    '@media (max-width: 900px)': {
      width: '100%',
    },
  }))
  export const ActivatedStatusBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    [theme.breakpoints.down('md')]: {
      gap: '1rem',
    },
  }))
  export const ActivatedStatusSubBody = styled(Box)(({ theme, medium }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: medium && 'center',
      alignItems: medium && 'center',
      padding: '1rem 0',
    },
  }))
  export const AvailableText = styled(Typography)(({ theme }: any) => ({
    fontSize: '14px',
    color: '#66717B',
    lineHeight: '18px',
    textAlign: 'left',
  }))
  export const DayLeftText = styled(Typography)(({ theme }: any) => ({
    textAlign: 'right',
    fontSize: '18px',
    color: '#66717B',
    lineHeight: '18px',
  }))
  export const LinkText = styled.a(({ theme }: any) => ({
    textDecoration: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#45B549',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }))
}
