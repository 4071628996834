import styled from '@emotion/styled'
import { Box, Divider, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export namespace S {
  export const EarnContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px 35px 20px 50px',
    paddingTop: '1rem',
    [theme.breakpoints.down('md')]: {
      gap: '2rem',
    },

    [theme.breakpoints.down(500)]: {
      overflowX: 'hidden',
      height: '63vh',
      overflowY: 'auto',
    },
  }))
  export const TitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '31px',
  }))
  export const ContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '18px',
    paddingTop: '1rem',
  }))
  export const AddressLink = styled(Typography)(({ theme, medium }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: medium && 'center',
  }))
  export const ContentContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '60%',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      gap: '3rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }))
  export const ImgContainer = styled(Box)(({ theme, medium }: any) => ({
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: medium ? 'flex' : 'none',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }))

  export const PurchaseSuccessImg = styled.img(({ theme }: any) => ({
    height: '315px',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
  }))
  export const CreditPurchaseDivider = styled(Divider)(({ theme }: any) => ({
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '1rem 0',
    },
  }))
  export const CreditPurchaseDividerText = styled(Typography)(({ theme }: any) => ({
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#0B213E',
    lineHeight: '18px',
    marginBottom: '-10px',
  }))
  export const EmailInput = styled(TextField)(({ theme, isValid }: any) => ({
    '& fieldset': {
      background: '#ced6e040',
      color: '#66717B',
      borderRadius: '0.5rem',
      padding: '1rem 0.75rem',
      outline: 'none',
      '&::placeholder': {
        color: '#66717B',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  }))

  export const CustomLink = styled(Link)(({ theme }: any) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }))
}
