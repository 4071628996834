import PrimaryButton from 'components/buttons/PrimaryButton'
import { S } from './Receiver.styles'
import { S as D } from '../../modal.styles'
import GIftIllustrartion from 'assets/GIftIllustrartion.svg'
import GiftSuccessIllustration from 'assets/GiftSuccessIllustration.svg'
import { FC } from 'react'
import Scan from '../scan'
import DeviceToggle from 'components/toggle/DeviceToggle'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { IconButton, useMediaQuery } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import GiftFailedIllustration from 'assets/GiftFailedIllustration.svg'
import ActivatedPlan from './ActivatedPlan'
import { useModalContext } from 'contexts/modal.context'
import { useWalletContext } from 'contexts/wallet.context'

interface Props {
  activateGift: any
  activationLoading: boolean
  handleClose?: any
  offer: any
}

const Receiver: FC<Props> = ({ activateGift, activationLoading, handleClose, offer }) => {
  const navigate = useNavigate()
  const { receiverState } = useModalContext()
  const { purchaseType } = useWalletContext()
  const { pathname } = useLocation()
  const medium = useMediaQuery('(max-width:899.95px)')

  let showQRButton =
    offer?.dctStatus === 'active' && (offer?.esimStatus?.toLowerCase() === 'inactive' || !offer?.esimStatus) && !offer?.iccid

  return (
    <>
      {receiverState === 'ACTIVATION' && (
        <S.ReceiverContainer>
          <S.ReceiverContentContainer>
            <S.ReceiverTitleText sx={{ paddingTop: '5rem' }}>
              Woohoo! You’ve received free mobile data!
            </S.ReceiverTitleText>
            <S.GiftSuccessMobileImg src={GIftIllustrartion} />
            <S.ReceiverText>
              Click Activate Now to activate your free data immediately. You may visit
              <br /> this URL any time in the next 7 days to activate your data plan later.
            </S.ReceiverText>
          </S.ReceiverContentContainer>
          <PrimaryButton
            style={{ height: '50px', width: '11.375rem' }}
            onClick={async () => {
              await (window as any).analytics.track('Clicked Activate Now button', {
                build: process.env.REACT_APP_CURRENT_CHAIN,
                offer: { ...offer },
                'screen name': `${pathname === '/' ? 'Home' : 'Shop'}`,
              })
              activateGift(showQRButton)
            }}
            disable={activationLoading}
          >
            {activationLoading ? <D.ContrastProgress /> : showQRButton ? 'Show QR' : 'Activate Now'}
          </PrimaryButton>
          <S.GiftSuccessImg src={GIftIllustrartion} />
        </S.ReceiverContainer>
      )}
      {receiverState === 'SCAN' && (
        <>
          <S.DeviceToggleContainer>
            <DeviceToggle />
          </S.DeviceToggleContainer>
          <Scan />
        </>
      )}
      {receiverState === 'SUCCESS' && (
        <S.ReceiverContainer sx={{ gap: medium && '10rem !important' }}>
          <S.ReceiverContentContainer sx={{ paddingTop: medium ? '0' : '5rem', gap: medium && '3rem !important' }}>
            <S.ReceiverTitleText>You successfully activated your plan!</S.ReceiverTitleText>
            <S.GiftSuccessUpdatedImgMobile src={GiftSuccessIllustration} />
            <S.ReceiverText>Now it’s your turn! Earn GIANT when you gift data.</S.ReceiverText>
          </S.ReceiverContentContainer>
          <PrimaryButton
            style={{ height: '50px', width: '11.375rem' }}
            onClick={() => {
              navigate('/shop')
              handleClose()
            }}
          >
            Gift and Earn
          </PrimaryButton>
          <S.GiftSuccessUpdatedImg src={GiftSuccessIllustration} />
        </S.ReceiverContainer>
      )}
      {receiverState === 'FAILURE' && (
        <S.ReceiverContainer>
          <S.ReceiverContentContainer sx={{ paddingTop: medium ? '1rem' : '5rem' }}>
            <S.ReceiverTitleText>Something's not right.</S.ReceiverTitleText>
            <S.GiftSuccessUpdatedImgMobile src={GiftFailedIllustration} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: medium ? '8rem' : '1rem' }}>
              <S.ReceiverText>Please click Back to try again.</S.ReceiverText>
              <S.ReceiverText>
                Still having problems? &nbsp;
                <S.CustomLink href="https://support.giantprotocol.org/hc/en-us/requests/new">
                  Contact us{' '}
                  <IconButton sx={{ padding: '3px' }}>
                    <OpenInNewIcon sx={{ color: '#45B549', fontSize: '0.8rem' }} />
                  </IconButton>
                </S.CustomLink>
              </S.ReceiverText>
            </Box>
          </S.ReceiverContentContainer>
          <PrimaryButton
            style={{ height: '50px', width: '11.375rem' }}
            onClick={() => {
              handleClose()
            }}
          >
            Back
          </PrimaryButton>

          <S.GiftSuccessUpdatedImg src={GiftFailedIllustration} />
        </S.ReceiverContainer>
      )}      
      {receiverState === 'ALREADY_ACTIVATED' && purchaseType !=='topup' &&<ActivatedPlan handleClose={handleClose} />}
      
    </>
  )
}

export default Receiver
