import React, { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { usePaymentContext } from 'contexts/payment.context'
import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { userClient } from 'services/http/user.service'
import { useWalletContext } from 'contexts/wallet.context'
import { RouteConfig } from '../utils/constants'

const NotFound = React.lazy(() => import('../components/notFound/NotFound'))
const Shop = React.lazy(() => import('views/shop'))
const Earn = React.lazy(() => import('views/earn'))
const MyPage = React.lazy(() => import('views/my-page'))
const EarnPageProvider = React.lazy(() => import('views/provider-details'))
const LeaderBoard = React.lazy(() => import('views/leaderboard'))
const Resell = React.lazy(() => import('views/resell'))

const AppRouters: FC = () => {
  const [prevPath, setPrevPath] = React.useState('')
  const location = useLocation()
  const { activationModalLocation } = usePaymentContext()
  const { userLogin } = useWalletContext()

  const routes: RouteConfig[] = [
    { path: '/', element: <Navigate to="/shop" replace />, enabled: true },
    { path: '/earn', element: <Earn />, enabled: process.env.REACT_APP_ENABLE_EARN_PAGE === 'true' },
    { path: '/shop', element: <Shop />, enabled: true },
    { path: '/gift/:id', element: activationModalLocation === 'MY_PAGE' ? <MyPage /> : <Shop />, enabled: true },
    { path: '/dct/:id', element: activationModalLocation === 'MY_PAGE' ? <MyPage /> : <Shop />, enabled: true },
    { path: '/dctStatus/:id', element: <Shop />, enabled: true },
    { path: '/payment/:id', element: activationModalLocation === 'MY_PAGE' ? <MyPage /> : <Shop />, enabled: true },
    { path: '/resaleOffer/:id', element: activationModalLocation === 'MY_PAGE' ? <MyPage /> : <Shop />, enabled: true },
    { path: '/offer/:id', element: activationModalLocation === 'MY_PAGE' ? <MyPage /> : <Shop />, enabled: true },
    { path: '/mypage', element: <MyPage />, enabled: true },
    { path: '/mypage/provider-details/:id', element: <EarnPageProvider />, enabled: true },
    { path: '/mypage/resell', element: <Resell />, enabled: true },
    { path: '/earn/provider-details/:id', element: <EarnPageProvider />, enabled: true },
    { path: '/shop/referral/:id', element: <Shop />, enabled: true },
    { path: '/verification/:id', element:<Shop />,enabled:true },
    { path: '/competition', element: <LeaderBoard />, enabled: process.env.REACT_APP_ENABLE_COMPETITION_PAGE === 'true' },
    { path: '/*', element: <NotFound />, enabled: true },
  ];

  useEffect(() => {
    if (location.pathname !== prevPath) {
      setPrevPath(location.pathname)
    }
  }, [location.pathname, prevPath])

  useEffect(() => {
    if (userLogin)
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Page Loaded',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': location.pathname.substring(1) === '' ? 'Home' : location.pathname.substring(1),
          },
        },
        userLogin,
      )
  }, [userLogin, location.pathname])

  return (
    <React.Suspense
      fallback={
        <Box sx={{ height: '100vh', display: 'grid', placeContent: 'center' }}>
          <CircularProgress />
        </Box>
      }
    >
      <Routes>
        {routes.map(({ path, element, enabled = true  }) => enabled && <Route key={path} path={path} element={element} />)}
      </Routes>
    </React.Suspense>
  )
}

export default AppRouters
