import styled from '@emotion/styled'
import { Box, Button, Table, TableCell, TableRow } from '@mui/material'

export namespace S {
  export const StakeModalHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.75rem',
  }))

  export const CustomTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '1.125rem',
    width: '15%',
  }))

  export const CustomTableRow = styled(TableRow)(({ theme }) => ({
    height: '3rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }))

  export const CustomTable = styled(Table)(({ theme }: any) => ({
    minWidth: 800,
    tableLayout: 'fixed',
    maxHeight: '5rem',
    overflow: 'scroll',
  }))

  export const CancelBtn = styled(Button)(({ theme }: any) => ({
    height: '31px',
    width: '89px',
    borderRadius: '4px',
    background: '#EF5153',
    color: theme.palette.common.white,
    textTransform: 'none',
    '&:hover': {
      background: '#EF5153',
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))

  export const LoadMoreBtn = styled(Button)(({ theme }: any) => ({
    background: '#C4C4C4',
    height: '1.875rem',
    width: '8.5625rem',
    borderRadius: '0.5rem',
    color: theme.palette.common.white,
  }))
}
