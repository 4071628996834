import styled from '@emotion/styled'
import { AddBox } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

export namespace S {
  export const ModalMainContainer = styled(Box)(({ theme }: any) => ({
    // width: '60.5%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F1F2F6',
    borderRadius: '15px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    // padding: '0 2.5rem',
    color: theme.palette.text.primary,
    padding: '2rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [theme.breakpoints.up('md')]: {
      minHeight: 474,
      width: 897,
      minWidth: 600,
    },

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      maxHeight: '95vh',
      height: '85vh',
    },
  }))

  export const StakeModalHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.75rem',
  }))

  export const HeaderContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1rem',
    paddingBottom: '1.5rem',
    borderBottom: '1px solid #DEE7FD',
  }))

  export const StakeModalSubHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }))

  export const StakeModalBody = styled(Box)(({ theme }: any) => ({}))

  export const StakeHistoryHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #DEE7FD',
    paddingBottom: '1rem',
  }))

  export const HeaderText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.5rem',
    fontWeight: '700',
  }))

  export const SubText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.125rem',
    fontWeight: '500',
  }))

  export const HeaderSubText = styled(Typography)(({ theme }: any) => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    color: 'rgba(11, 33, 62, 0.6)',
  }))

  export const CustomInput = styled.input(({ theme }: any) => ({
    border: 'none',
    borderRadius: '0.25rem',
    background: '#ced6e040',
    height: '2.75rem',
    padding: '0 0 0 1rem',
    outline: 'none',
    boxSizing: 'border-box',
    width: '12.375rem',
  }))

  export const InputContainer = styled(Box)(({ theme }: any) => ({
    position: 'relative',
    width: '100%',
  }))

  export const SearchIconImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    top: '1.1rem',
    left: '1rem',
  }))

  export const CloseIconContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    right: '-3.5rem',
    top: '-3.5rem',
    height: '3.0625rem',
    width: '3.0625rem',
    background: theme.palette.primary.main,
    borderRadius: '0.6875rem',
    display: 'grid',
    placeContent: 'Center',
    cursor: 'pointer',
  }))
}
