import styled from '@emotion/styled'
import { Box, Typography, Card, Tooltip, Divider, Button } from '@mui/material'

// my data plan card section
export namespace S {
  export const DataPlanCardContainer = styled(Box)(({ theme }: any) => ({
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: '8px',
    minHeight: '223px',
    width: '592px',
    minWidth: '250px',
  }))
  export const DataPlanCardHeader = styled(Box)(({ theme }: any) => ({
    minHeight: '92px',
    borderRadius: '8px 8px 0 0 ',
    background: 'orange',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem 0 1rem',
    '@media (max-width: 750px)': {
      padding: '1rem',
      justifyContent: 'center',
    },
  }))
  export const DataPlanCardHeaderBox = styled(Box)(({ theme }: any) => ({
    minWidth: '250px',
    fontWeight: '700',
    fontSize: '46.87px',
    lineHeight: '61px',
  }))
  export const DataPlanCardBody = styled(Box)(({ theme }: any) => ({
    height: 'calc(223px - 92px)',
    padding: '1.9375rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 508px)': {
      padding: '0.2rem 1rem',
    },
  }))
  export const DataCardBodyContainer = styled(Box)(({ theme }: any) => ({
    width: '50%',
    minWidth: '150px',
    '@media (max-width: 450px)': {
      width: '100%',
    },
  }))
  export const DataPlansCountryText = styled(Typography)(({ theme }: any) => ({
    margin: '0',
    minWidth: '50px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }))
  export const DataPlansSubText = styled(Typography)(({ theme }: any) => ({
    color: '#66717B',
    fontSize: '18px',
    fontWeight: 400,
  }))
  export const DataPlansText = styled(DataPlansSubText)(({ theme }: any) => ({
    width: '50%',
    color: '#131A22',
    fontWeight: '700',
  }))
  export const DataExpirePlanBtn = styled(Box)(({ theme }: any) => ({
    border: '1px solid #00C853',
    background: '#00C853',
    borderRadius: '0.651rem',
    height: '2.1156rem',
    minWidth: '8.9975rem',
    color: '#FFFFFF',
    display: 'grid',
    placeContent: 'center',
    cursor: 'pointer',
  }))
  export const DataPlansIcons = styled.img(({ theme }: any) => ({
    paddingBottom: '0.5rem',
  }))
}

//my data plan card section
export namespace M {
  export const MyDataPlanCardContainer = styled(Box)(({ theme }: any) => ({
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: '8px',
    minHeight: '291px',
    '@media (max-width: 508px)': {
      width: '100%',
    },
  }))
  export const MyDataPlanCardHeader = styled(Box)(({ theme }: any) => ({
    minHeight: '92px',
    borderRadius: '8px 8px 0 0 ',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem 0 1rem',    
  }))
  export const MyDataPlanCardHeaderLeft = styled(Typography)(({ theme }: any) => ({    
    color: '#131A22',
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '42px',
  }))
  export const MyDataPlanCardHeaderRight = styled(Box)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '42px',
    // letterSpacing: '0.1em',
    color: '#0B213E',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'center',
  }))
  export const MydataplanSubText = styled(Box)(({ theme }: any) => ({
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 608px)': {
      maxWidth: '80px',
      fontSize:'1.75rem',
    },
  }))
  export const MyDataPlanBody = styled(Box)(({ theme }: any) => ({
    minHeight: 'calc(200px - 92px)',
    height: '100%',
    padding: '1.5rem',
    background: 'white',
    borderRadius: '0 8px 8px 8px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // alignItems: 'center',
    gap: '1rem',
    '@media (max-width: 508px)': {
      padding: '0.5rem 0.5rem',
    },
  }))
  export const MyDataPlanBodyText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#0B213E',
  }))
  export const SoldBtn = styled(Button)(({ theme }: any) => ({
    textTransform: 'none',
    height: '2.0625rem',
    width: '10.3125rem',
    borderRadius: '5rem',
    background: '#737373',
    color: theme.palette.common.white,
    '&:disabled': {
      color: theme.palette.primary.contrastText,
    },
  }))
  export const MyDataPlanCardHeaderLeftText = styled(Typography)(({ theme }: any) => ({
    color: '#131A22',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '18px',
  }))
  export const MyDataPlanBodySubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.02em',
    color: '#0B213E',
  }))
  export const MyDataPlanBodyActiveText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.02em',
    color: '#0B213E',
    '@media (max-width: 400px)': {
      fontSize: '18px',
    },
  }))
  export const MyDataPlanBodyContentCard = styled(Box)(({ theme }: any) => ({
    width: '100%',
    background: '#F3F5F7',
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0.8rem',
    boxSizing: 'border-box',
    gap: '0.8rem',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  }))
  export const MyDataPlanBodyContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'auto',
    gap: '5px',
  }))
  export const MyDataPlanErrorBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexShrink: '0',
    borderRadius: '8px',
    background: '#FFF2DF',
    gap: '5px',
    padding: '13px 10px', 
  }))
  
  export const MyDataPlanErrorHead = styled(Typography)(({ theme }: any) => ({
    color: '#131A22',
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  }))
  export const MyDataPlanErrorText = styled(Typography)(({ theme }: any) => ({
    color: '#5D5D5E',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left',
    marginLeft: '34px',
  }))
  export const SoldText = styled(Typography)(({ theme }: any) => ({
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'left',
  }))
  export const MyDataPlanBodyContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 508px)': {
      width:'auto',
    },
  }))
  export const MyDataPlanBodySubContentBox = styled(Box)(({ theme }: any) => ({
    width: '100%',
    display: 'grid',
    // gridTemplateColumns: 'auto 3fr',
    alignItems: 'center',
    // marginBottom: '0.2rem',
    gap: '0.8rem',
  }))
  export const MyDataPlansContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    flexWrap: 'wrap',
    paddingTop: '0.8rem',
  }))
  export const MyDataPlansText = styled(Typography)(({ theme }: any) => ({
    color: '#66717B',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5rem',
  }))
  export const MyDataPlanBodyBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
    alignItems: 'center',    
    '@media (max-width: 508px)': {
      alignItems: 'normal',
    },
  }))
  export const MyDataPlansDataText = styled(MyDataPlansText)(({ theme }: any) => ({
    textAlign: 'left',
    fontWeight: 700,
    fontSize: '12px',
    alignItems: 'baseline !important',
  }))
  export const MyDataPlansUpdateText = styled(MyDataPlansText)(({ theme }: any) => ({
    textAlign: 'right',
    fontWeight: 700,
    fontSize: '12px',
    alignItems: 'baseline !important',
    lineHeight: '16px',
    color: '#0B213E',
  }))
  export const MyDataPlansActivateText = styled(MyDataPlansText)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '14px',
    alignItems: 'baseline !important',
    lineHeight: '16px',
    '@media (max-width: 508px)': {
      fontSize:'12px',
      textAlign: 'right',
    },
  }))
  export const MyDataPlansBytesText = styled(Typography)(({ theme, currentdata }: any) => ({
    textAlign: 'left',
    color: currentdata > 0 ? '#00C853' : '#DE1428',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31.25px',
  }))
  export const DataLimitContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }))
  export const DataValue = styled.div(({ theme }: any) => ({
    display: 'flex',
    gap: '0.3rem',
    '@media (max-width: 508px)': {
      alignItems: 'center',
    },
  }))
  export const DataSize = styled(Typography)(({ theme, currentdata }: any) => ({
    fontWeight: '700',
    fontSize: '3.25rem',
    color: '#131A22',
    '@media (max-width: 508px)': {
      fontSize: '1.75rem',
    },
  }))
  export const DataLimit = styled(Typography)(({ theme, currentdata }: any) => ({
    fontWeight: '700',
    fontSize: '2.25rem',
    paddingTop: '1rem',
    color: '#131A22',
    '@media (max-width: 508px)': {
      fontSize: '1.25rem',
      paddingTop: '0',
    },
  }))
  export const DataValidity = styled(Typography)(({ theme, currentdata }: any) => ({
    paddingTop: '1rem',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#0B213E',
    '@media (max-width: 508px)': {
      paddingTop: '0',
    },
  }))
  export const SellBtn = styled(Button)(({ theme, currentdata }: any) => ({
    height: '44px',
    width: '100px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0.5rem',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '20px',
    position: 'relative',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 508px)': {
      marginLeft: 'auto',
      marginTop: '-15px',
      marginRight: '5px',
      width: 'auto',
    },
  }))
  export const SparkleImg = styled.img(({ theme, currentdata }: any) => ({
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.8rem',
  }))
  export const MyDataPlanBodyComboBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
  }))
  export const MyDataPlanBodySubBox =  styled(Box)(({ theme }: any) => ({
    display: 'grid',
    marginRight:'auto',
    width:'100%'
  }))
  export const MyDataPlanBodyTopupBox =  styled(Box)(({ theme }: any) => ({
    display: 'flex',
    marginLeft: 'auto',
    marginTop:'-1.875rem',
    alignItems: 'center',
    paddingLeft: '0.6rem'
  }))
  export const TopupBtn = styled(Button)(({ theme, currentdata }: any) => ({
    height: '38px',
    width: '100px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0.5rem',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '14px',
    position: 'relative',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 400px)': {      
      width: 'auto',
    },    
  }))
  export const DelistBtn = styled(TopupBtn)(({ theme, currentdata }: any) => ({
    background: '#F60',
    opacity: 0.75,
    '&:hover': {
      background: '#F60',
      opacity: 1,
    },
  }))
  export const ShareBtn = styled(TopupBtn)(({ theme, currentdata }: any) => ({
    background: '#30B34A',
    opacity: 0.75,
    '&:hover': {
      background: '#30B34A',
      opacity: 1,
    },
  }))
  export const SellBtnNew = styled(Button)(({ theme }: any) => ({
    height: '38px',
    width: '100px',
    background: '#FF6600',
    color: theme.palette.common.white,
    borderRadius: '0.5rem',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '14px',
    position: 'relative',
    '&:hover': {
      background: '#FF6600',
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 400px)': {      
      width: 'auto',
    },
  }))
  export const PlusImg = styled.img(({ theme, currentdata }: any) => ({
    marginLeft:'5px',
  }))
}

//primary & secondary card section
export namespace P {
  export const PrimaryCardContainer = styled(Card)(({ theme }: any) => ({
    // gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
    padding: '0 0 1rem 0',
    width: '387px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '347px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  }))

  export const PrimaryCardHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    padding: '1.5rem',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    background: `${theme}`,
    borderRadius: '8px 8px 0px 0px',
    boxSizing: 'border-box',
  }))
  export const PrimaryCardBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
  }))

  export const PlanContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '1.1fr auto',
    padding: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1.5fr 0.5fr',
    },
  }))

  export const PlanDetails = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  }))

  export const ProviderContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '0.1rem',
    // width: '4.75rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }))

  export const CardDivider = styled(Divider)(({ theme }: any) => ({
    borderColor: 'rgba(102, 113, 123, 0.5)',
  }))

  export const ESimContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  }))

  export const ESimImg = styled.img(({ theme }: any) => ({
    height: '60px',
  }))

  export const PaymentContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    padding: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      gap: '5rem',
    },
  }))

  export const PayWrapper = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    gap: '0.1rem',
  }))

  export const RewardWrapper = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.1rem',
    flexWrap: 'wrap',
  }))

  export const DiscountContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))

  export const PrimaryCardContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.1rem',
  }))
  export const PrimaryCardSubContentBox = styled(PrimaryCardContentBox)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'flex-end',
  }))
  export const PrimaryCardMainContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: '1rem',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  }))

  export const PrimaryCardMainContentLeft = styled(PrimaryCardMainContent)`
    width: 160px;
  `

  export const PrimaryCardMainContentRight = styled(PrimaryCardMainContent)`
    max-width: 100px;
  `

  export const PrimaryCardSubContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
    marginTop: '0.75rem',
  }))
  export const PrimaryCardHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '47px',
    color: theme.palette.common.black,
  }))

  export const DataSizeText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '20px',
    // lineHeight: '47px',
    paddingTop: '0.5rem',
    color: theme.palette.common.black,
  }))

  export const PrimaryCardSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    color: theme.palette.common.black,
    maxWidth: '120px',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }))

  export const PrimaryCardMainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.625rem',
    color: theme.palette.text.primary,
  }))

  export const PrimaryCardMainSubText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
  }))

  export const PrimaryCardMainPayText = styled(PrimaryCardMainSubText)(({ theme }: any) => ({
    fontWeight: 500,
  }))

  export const PrimaryCardMainGntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.4375rem',
    gap: '0.2rem',
    color: theme.palette.text.primary,
  }))

  export const DiscountText = styled(PrimaryCardMainGntText)(({ theme }: any) => ({
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: '1rem',
  }))

  export const PrimaryCardFooter = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    gap: '1rem',
  }))

  //secondary card
  export const SecondaryCardContainer = styled(Box)(({ theme }: any) => ({
    background: theme.palette.grey[200],
    fontSize: '1.125rem',
    fontWeight: 700,
    borderRadius: '12px',
    height: '10rem',
    width: '390px',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '0 2rem',
    boxSizing: 'border-box',
  }))
  export const SecondaryCardBody = styled(Box)(({ theme }: any) => ({
    width: '80%',
  }))
  export const SecondaryCardHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
  }))
  export const SecondaryCardSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '0.8125rem',
    lineHeight: '1.5rem',
  }))
  export const SecondaryCardIcon = styled.img(({ theme }: any) => ({
    marginRight: '1rem',
  }))
}

//why us card section
export namespace W {
  export const WhyUsCardContainer = styled(Box)(({ theme }: any) => ({
    height: '11.9375rem',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    background: theme.palette.grey[400],
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1rem',
    boxSizing: 'border-box',
  }))

  export const WhyUsCardHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
    // marginBottom: '1rem',
  }))
  export const WhyUsCardSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    // color: '#66717B',
  }))
  export const WhyUsCardHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2rem',
  }))
  export const WhyUsCardHeaderBox = styled(Box)(({ theme }: any) => ({
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    boxShadow: '0px 0px 54px rgba(69, 181, 73, 0.42)',
    background: 'white',
    display: 'grid',
    placeContent: 'center',
  }))
  export const WhyUsCardBox = styled(Box)(({ theme }: any) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1.5rem',
  }))
}
