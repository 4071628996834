import styled from '@emotion/styled'
import { Box, IconButton, Input, Typography } from '@mui/material'

export namespace S {
  export const GiftContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    padding: '20px 40px 20px 50px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down(395)]: {
      overflowX: 'hidden',
      height: 'auto',
      overflowY: 'auto',
    },
  }))
  export const ContentContainer = styled(Box)(({ theme }: any) => ({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4.375rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      gap: '1rem',
      alignItems: 'center',
    },
  }))

  export const TitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    color: '#0B213E',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '19px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  }))
  export const GiftContainerURL = styled(Typography)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.1875rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '4rem',
    },
  }))

  export const CopyContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      width: '100%',
    },
  }))
  export const GiftInput = styled(Input)(({ theme }: any) => ({
    background: '#F3F5F7',
    borderRadius: '0.5rem',
    border: '1px solid #F3F5F7',
    width: '28rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    color: '#45B549',
    padding: '0.4rem 0 0.4rem 1rem',
    flexWrap: 'nowrap',
    input: {
      cursor: 'pointer',
    },
  }))
  export const GiftIconButton = styled(IconButton)(({ theme }: any) => ({
    color: '#ffffff',
    background: '#45B549',
    height: '2.8125rem',
    width: '3.6056rem',
    borderRadius: '0px 8px 8px 0px',
    svg: {
      '&:hover': {
        background: 'none',
      },
    },
  }))
  export const SmallText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '12px',
    lineHeoght: '16px',
    marginTop: '0.5rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0.5rem',
    },
  }))
  export const GiftSuccessImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    right: '-8rem',
    bottom: '0rem',
    height: '22.0625rem',
    width: '21.5625rem',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }))
  export const GiftSuccessLeftImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    bottom: '0rem',
    height: '21.5625rem !important',
    width: '30rem !important',
    right: '-5rem !important',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }))
  export const GiftSuccessMobileImg = styled.img(({ theme }: any) => ({
    position: 'relative',
    bottom: '0rem',
    height: '21.5625rem !important',
    width: '30rem !important',
    right: '-5rem !important',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'visible',
    },
  }))
  export const ShareButton = styled(IconButton)(({ theme }: any) => ({
    color: '#ffffff',
    background: '#45B549',
    height: '2.8125rem',
    width: '3.6056rem',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#45B549',
    },
  }))
}
