import { FC } from 'react'
import { S } from './styled'

interface Props {
  style: {
    height: string
    width: string
    marginLeft?: string
    alignSelf?: string
  }
  children: string
  onClick?: (e?: any) => void
  disable?: boolean
}

const SecondaryButton: FC<Props> = ({ style, children, onClick, disable }) => {
  return (
    <S.SecondaryCustomButton onClick={onClick} style={style} disabled={disable ?? false}>
      {children}
    </S.SecondaryCustomButton>
  )
}


export default SecondaryButton
