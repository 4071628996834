import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePaymentContext } from 'contexts/payment.context'
import CreditCardPaymentPage from './CreditCardPaymentPage'
import CoinbasePaymentPage from './CoinbasePaymentPage'
import CryptoPaymentPage from './CryptoPaymentPage'
import { PAYMENT_TYPE, PURCHASE_TYPE_BUY } from 'utils/constants'
import WalletPurchase from './WalletPurchase'
import ActivateBuy from './ActivateBuy'
import CheckPurchase from './CheckPurchase'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'
import { handleSegment } from 'utils'
import { userClient } from 'services/http/user.service'
import { useAppContext } from 'contexts/app.context'

interface Props {
  selectedPayment: any
  setSelectedPayment: any
  handleClose: any
  segmentData: any
}

const Buy: FC<Props> = ({ selectedPayment, setSelectedPayment, handleClose, segmentData }) => {
  const {
    paymentType,
    paymentSuccess,
    paymentId,
    transactionStatus,
    transactionHash,
    handleWalletPurchaseData,
    handleOfferData,
    setPaymentSuccess,
    setTransactionStatus,
    setTransactionHash
    
  } = usePaymentContext()
  const { activateNow, setCryptoPurchaseLoading } = useModalContext()
  const { showPurchaseModal, purchaseType, modalData, userLogin } = useWalletContext()
  const { selectedOfferType } = useAppContext()
  const [retryStatus, setRetryStatus] = useState(false)
  /* const [transactionHash, setTransactionHash] = useState('') */

  const { pathname } = useLocation()
  
  useEffect(() => {
    if (transactionStatus.status === 2000) {    

      if (userLogin) {
        userClient.postUserEventsWithAuth(
          {
            eventName: 'USDC Payment Success',
            properties: {
              build: process.env.REACT_APP_CURRENT_CHAIN,
              offer: segmentData,
              network: selectedPayment.value,
              'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
              'transaction hash': transactionHash,
              'screen name': `${pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
            },
          },
          userLogin,
        )
      }
      //console.log("transactionHash : ", transactionHash)
      setCryptoPurchaseLoading(false)
      setTransactionStatus({ status: 0, statusMsg: '' })
      setTransactionHash('')
      setPaymentSuccess(true)
    } else if (transactionStatus.status === 4000) {
      if (userLogin) {
        userClient.postUserEventsWithAuth(
          {
            eventName: 'USDC Payment Failure',
            properties: {
              build: process.env.REACT_APP_CURRENT_CHAIN,
              offer: segmentData,
              network: selectedPayment.value,
              'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
              'transaction hash': transactionHash,
              'screen name': `${pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
              reason: transactionStatus.statusMsg,
            },
          },
          userLogin,
        )
      }
    }
  }, [transactionStatus.status, showPurchaseModal])

  return paymentSuccess ? (
    <>
      {activateNow ? (
        <ActivateBuy handleClose={handleClose} />
      ) : (
        <CheckPurchase retryStatus={retryStatus} handleClose={handleClose} />
      )}
    </>
  ) : paymentType === PAYMENT_TYPE[0].value ? (
    <WalletPurchase
      selectedPayment={selectedPayment}
      setSelectedPayment={setSelectedPayment}
      /* transactionHash={transactionHash}
      setTransactionHash={setTransactionHash} */
      segmentData={segmentData}
    />
  ) : paymentType === PAYMENT_TYPE[1].value ? (
    <CoinbasePaymentPage segmentData={segmentData} />
  ) : paymentType === PAYMENT_TYPE[2].value ? (
    <CryptoPaymentPage segmentData={segmentData} />
  ) : (
    <CreditCardPaymentPage segmentData={segmentData} />
  )
}

export default Buy
