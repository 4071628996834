import { FC, useEffect, useState } from 'react'
import { Box, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import { S } from './stakerequest.style'
import { STAKE_REQUEST_TABLE_HEADER } from 'utils/constants'
import { convertToPascalCase, toDecimal } from 'utils'
import moment from 'moment'
import { useWalletContext } from 'contexts/wallet.context'
import { handleCancelUnstakeRequest } from 'polkadot/sendmethods/staking'

interface Props {
  data: any
  loader: boolean
  getUnstakeRequestData: any
  fetchNextPage: any
  showLoadmoreBtn: boolean
}

const RequestTable: FC<Props> = ({ data, loader, getUnstakeRequestData, fetchNextPage, showLoadmoreBtn }) => {
  const { userLogin, connectedAccount } = useWalletContext()
  const [cancelLoader, setCancelLoader] = useState<boolean>(false)
  const [requestId, setRequestId] = useState<number>(0)

  const handleCancelUnstake = (id: any, requestId: any) => {
    setRequestId(requestId)
    setCancelLoader(true)
    handleCancelUnstakeRequest(id, connectedAccount, requestId, userLogin, getUnstakeRequestData, setCancelLoader)
  }

  return (
    <TableContainer sx={{ maxHeight: 300 }}>
      <S.CustomTable size="medium" stickyHeader aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ height: '4rem' }}>
            {STAKE_REQUEST_TABLE_HEADER.map((data, i) => (
              <S.CustomTableCell
                key={i}
                sx={{
                  fontWeight: 'bold',
                  background: '#F1F2F6',
                }}
              >
                {data}
              </S.CustomTableCell>
            ))}
          </TableRow>
        </TableHead>

        {loader ? (
          <TableBody>
            <S.CustomTableRow>
              <S.CustomTableCell colSpan={7} align="center">
                <CircularProgress />
              </S.CustomTableCell>
            </S.CustomTableRow>
          </TableBody>
        ) : (
          <TableBody>
            {data?.map((request: any, index: number) => {
              return (
                <S.CustomTableRow>
                  <S.CustomTableCell align="left">{request?.unStakeId}</S.CustomTableCell>
                  <S.CustomTableCell align="left">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={`data:image/png;base64,${request?.selectedProvider?.logo}`}
                        alt="logo"
                        style={{ width: '1.75rem', height: '1.75rem', marginRight: '1rem' }}
                      />
                      {convertToPascalCase(request?.selectedProvider?.displayName)}
                    </Box>
                  </S.CustomTableCell>
                  <S.CustomTableCell align="left">{Number(request?.amount).toFixed(2)}</S.CustomTableCell>
                  <S.CustomTableCell align="left">{Number(request?.remainingDays).toFixed(0)}</S.CustomTableCell>
                  <S.CustomTableCell align="left">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Box
                        sx={{
                          height: '11px',
                          width: '11px',
                          borderRadius: '50%',
                          // background: request?.remainingDays > 0 ? '#18BC46' : '#FF8730',
                          background: '#FF8730',
                        }}
                      ></Box>
                      {/* {request?.remainingDays > 0 ? 'Complete' : 'Pending'} */}
                      Pending
                    </Box>
                  </S.CustomTableCell>
                  <S.CustomTableCell align="left">
                    <S.CancelBtn onClick={() => handleCancelUnstake(request?.unStakeId, request?.id)}>
                      {cancelLoader && requestId === request.id ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        'Cancel'
                      )}
                    </S.CancelBtn>
                  </S.CustomTableCell>
                </S.CustomTableRow>
              )
            })}

            {!data.length && (
              <S.CustomTableRow>
                <S.CustomTableCell colSpan={5} align="center">
                  {`No unstake requests found.`}
                </S.CustomTableCell>
              </S.CustomTableRow>
            )}
          </TableBody>
        )}
      </S.CustomTable>

      {!loader && data?.length >= 10 && showLoadmoreBtn === true && (
        <Box sx={{ display: 'grid', placeContent: 'center', margin: '1rem 0' }}>
          <S.LoadMoreBtn onClick={() => fetchNextPage('request')}>Load next 10</S.LoadMoreBtn>
        </Box>
      )}
    </TableContainer>
  )
}

export default RequestTable
