import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { NavLink } from 'react-router-dom'
import { headerPages } from 'utils/constants'
import { Divider, Grid, Stack, styled, Tooltip, Typography } from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import { Box } from '@mui/system'
import WalletIcon from 'assets/icons/WalletIcon.svg'
import { useWalletContext } from 'contexts/wallet.context'
import ProfileMenu from './ProfileMenu'
import DisconnectWalletModal from './DisconnectWalletModal'
import { getItem, setItem } from '../../../services/localStorage/localStorage.service'
import { getBalance } from 'polkadot/callmethods/system'
import { useLocation } from 'react-router-dom'
import WagmiAddress from './WagmiAddress'
import newRedIcon from 'assets/icons/new-red.svg'
import { Page } from '../../../types'
import { useTranslation } from 'react-i18next'

const injectedWeb3 = (window as any).injectedWeb3
interface Props {
  selectedLink: any
  setSelectedLink: any
}

const Collapse: React.FC<Props> = ({ selectedLink, setSelectedLink }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()
  const location = useLocation()

  const [isDisconnectToggle, setIsDisconnectToggle] = useState<boolean>(false)
  const handleDisconnectModalOpen = () => setIsDisconnectToggle(true)
  const handleDisconnectModalClose = () => setIsDisconnectToggle(false)

  const { isConnected, isWagmiConnected, connectedAccount, balance, setConnectedModalOpen } = useWalletContext()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const navPages = headerPages.filter((p) => p.enabled);

  useEffect(() => {
    window.scrollTo(0, 0)
    setSelectedLink(navPages.find((item: any) => item.route.split('/')[1].includes(location.pathname.split('/')[1])))
  }, [location.pathname])

  useEffect(() => {
    const checkInjected = async () => {
      if (Object.keys(injectedWeb3).length && isConnected) {
        const accounts = getItem<any[]>('accounts', [])
        // console.log("connected accounts: ", accounts);
        if (accounts.length) {
          // setAddress(accounts[0].address)
          getBalance(accounts[0].address).then((bal) => {})
        } else console.log('please link your account to the wallet.')
      } else {
        setItem<boolean>('isConnected', false)
      }
    }
    checkInjected()
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }


  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ProfileMenu />
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ListIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <WalletContainer item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {isConnected ? (
              <>
                <BalanceContainer
                  onClick={() => {
                    handleDisconnectModalOpen()
                  }}
                >
                  <LeftContainer
                    className="animate__animated animate__fadeInRight"
                    sx={{ fontWeight: 700, fontSize: '18px' }}
                  >
                    {(balance?.deci ?? '0') + 'GIANT'}
                  </LeftContainer>
                  <img
                    src={WalletIcon}
                    style={{
                      zIndex: '99',
                      background: isConnected ? '#CED6E0' : '',
                      height: '2.5rem',
                    }}
                    className="animate__animated animate__fadeIn"
                    alt="walletLogo"
                  />
                  <RightContainer
                    sx={{
                      fontWeight: 400,
                      color: '#66717B',
                      fontSize: '1.125rem',
                    }}
                    className="animate__animated animate__fadeInLeft"
                  >
                    {connectedAccount?.address.substring(0, 6)}...
                    {connectedAccount?.address.substring(connectedAccount.address.length - 4)}
                  </RightContainer>
                </BalanceContainer>
              </>
            ) : isWagmiConnected ?(
              <WagmiAddress handleModalOpen={handleDisconnectModalOpen} />
            ): (
              <div
                style={{
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => {
                  setConnectedModalOpen(true)
                }}
              >
                <Tooltip title="Connect wallet" arrow>
                  <div>
                    <ConnectContainer>
                      <img src={WalletIcon} alt="walletLogo" />
                      Connect wallet
                    </ConnectContainer>
                  </div>
                </Tooltip>
              </div>
            )}
          </Box>
        </WalletContainer>
        <Divider />

        {navPages.map((page: Page) => {
          return (
            <NavItem
              to={page.route}
              key={page.id}
              style={{
                borderLeft: page?.id === selectedLink?.id ? '5px solid #45B549' : '',
                paddingLeft: '2rem',
              }}
              onClick={() => {
                setSelectedLink(page)
                setAnchorEl(null)
              }}
            >
              {page.title === 'My Page' ? (
                <MenuItem sx={{ width: '100%', displat: 'flex', justifyContent: 'center' }}>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography>{t('header-nav.mypage')}</Typography>
                    <img src={newRedIcon} alt="" title="Resell My Plans" style={{ paddingBottom: '1.5rem' }} />
                  </Stack>
                </MenuItem>
              ) : (
                page.title === 'Shop' ? <MenuItem sx={{ width: '100%', displat: 'flex', justifyContent: 'center' }}>{t('header-nav.shop')}</MenuItem>:
                page.title === 'Earn' ? <MenuItem sx={{ width: '100%', displat: 'flex', justifyContent: 'center' }}>{t('header-nav.earn')}</MenuItem>:                        
                <MenuItem sx={{ width: '100%', displat: 'flex', justifyContent: 'center' }}>{page.title}</MenuItem>
              )}
            </NavItem>
          )
        })}
      </Menu>
      <DisconnectWalletModal isToggle={isDisconnectToggle} handleModalClose={handleDisconnectModalClose} />
    </div>
  )
}

const NavItem = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.black,
  boxSizing: 'border-box',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  minWidth: '20rem',
  textDecoration: 'none',
}))

const WalletContainer = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  display: 'grid',
  placeContent: 'center',
  [theme.breakpoints.down('sm')]: {
    order: 2,
  },
}))

const LeftContainer: any = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  width: '100%',
  minWidth: '40%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '2.5rem',
  borderRadius: '2rem 0 0 2rem',
  padding: '0 0.5rem 0 1rem',
}))
const RightContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '0 1rem',
  borderRadius: '0 2rem 2rem 0',
}))
const ConnectContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '10rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  padding: '0.2rem 1rem 0.2rem 0.2rem',
  borderRadius: '2rem',
}))

const BalanceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: '20px',
}))
export default Collapse
