import React, { FC } from 'react'
// import { Typography } from '@mui/material';
import { S } from './progress.styles'
interface Props {
  remainValue: number
  totalValue: number
  dctStatus?: string
  esimStatus?: string
}

const CustomProgessBar: FC<Props> = ({ remainValue, totalValue, dctStatus, esimStatus }) => {
  const dataPercentage = Math.round((remainValue / totalValue) * 100)

  return (
    <>
      <S.CustomSliderContainer>
        <S.CustomSlider
          value={remainValue}
          min={0}
          max={totalValue}
          percentage={dataPercentage}
          dctStatus={dctStatus ?? ''}
          esimStatus={esimStatus ?? ''}
          // valueLabelDisplay="on"
          // valueLabelFormat={() => <Typography>{dataPercentage}%</Typography>}
        />
        {remainValue > 0 && <S.SliderText percentage={dataPercentage}>{dataPercentage}%</S.SliderText>}
      </S.CustomSliderContainer>
    </>
  )
}

export default CustomProgessBar
