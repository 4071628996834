import * as React from 'react'
import { FC } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { S } from './staketab.style'
import HistoryTable from '../stakehistory'
import RequestTable from '../stakerequest'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface Props {
  loader: boolean
  requestLoader: boolean
  history: any
  type: string
  unstakeRequests: any
  fetchNextPage: any
  showLoadmoreBtn: boolean
  getUnstakeRequestData: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const StakeTab: FC<Props> = ({
  history,
  type,
  unstakeRequests,
  fetchNextPage,
  loader,
  showLoadmoreBtn,
  requestLoader,
  getUnstakeRequestData,
}) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {type !== 'stake' && <S.CustomTab label="Unstake Requests" {...a11yProps(0)} />}
          <S.CustomTab label={type === 'stake' ? 'Stake History' : 'Unstake History'} {...a11yProps(1)} />
        </Tabs>
      </Box>

      {type === 'unstake' && (
        <TabPanel value={value} index={0}>
          <RequestTable
            data={unstakeRequests}
            loader={requestLoader}
            getUnstakeRequestData={getUnstakeRequestData}
            fetchNextPage={fetchNextPage}
            showLoadmoreBtn={showLoadmoreBtn}
          />
        </TabPanel>
      )}

      <TabPanel value={value} index={type === 'stake' ? 0 : 1}>
        <HistoryTable
          loader={loader}
          data={history}
          type={type}
          fetchNextPage={fetchNextPage}
          showLoadmoreBtn={showLoadmoreBtn}
        />
      </TabPanel>
    </Box>
  )
}

export default StakeTab
