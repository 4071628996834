import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DateDifference, formatBytes } from 'utils'
import GiantDollor from 'assets/icons/GiantDollor.svg'
import { useTranslation } from 'react-i18next'
import { M } from './styled'
import CustomProgessBar from 'components/progressBar/CustomeProgressBar'
import moment from 'moment'
import { usePaymentContext } from 'contexts/payment.context'
import { useQuery } from 'react-query'
import { getConsumptionStatus } from 'utils/constants/config'
import { useModalContext } from 'contexts/modal.context'
import { useWalletContext } from 'contexts/wallet.context'
import PlusIcon from 'assets/icons/GreenPlus.svg'

interface Props {
  data: any
  isDisplayRaward?: boolean
  location?: any
  currentPackDetails?: any
}

const PlanDetailsCard: FC<Props> = ({ data, isDisplayRaward, location, currentPackDetails }) => {
  const { walletPurchaseData, offerData } = usePaymentContext()
  const { receiverState } = useModalContext()
  const { userLogin } = useWalletContext()
  const [dataConsumed, setDataConsumed] = useState(null)
  const [userToken, setUserToken] = useState<any>(false)
  const [remainData, setRemainData] = useState(0)
  const { pathname } = useLocation()
  const { setShowTopUpModal, setTopUpMetaData } = useModalContext()
  const { t } = useTranslation()

  useQuery(
    ['getConsumptionStatus', walletPurchaseData?.id],
    async () => await getConsumptionStatus(walletPurchaseData?.id, userLogin),
    {
      cacheTime: 1000 * 60 * 5,
      onSuccess: (data) => {
        setDataConsumed(data.data.dataUsageRemainingInBytes)
      },
      enabled: Boolean(receiverState === 'ALREADY_ACTIVATED') && userToken,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    userLogin && setUserToken(true)
  }, [userLogin])

  const getBalanceData = () => {
    if (dataConsumed) {
      let value = formatBytes(dataConsumed).value
      remainData < dataConsumed && setRemainData(dataConsumed)
      return value
    } else {
      if (location === 'accordion') {
        const remb = currentPackDetails?.total?.remainingBytes?currentPackDetails.total.remainingBytes:currentPackDetails.remainingBytes
        const value = formatBytes(remb).value
        remainData < remb && setRemainData(remb)
        return value
      } else {
        const remb = data?.total?.dataUsageRemainingInBytes?data.total.dataUsageRemainingInBytes:data.dataUsageRemainingInBytes
        const value = formatBytes(parseInt(remb)).value
        remainData < remb && setRemainData(remb)
        return value
      }
    }
  }

  const getBalanceDataSize = () => {
    if (dataConsumed) {
      return formatBytes(dataConsumed).size
    } else {
      if (location === 'accordion') {
        return formatBytes(currentPackDetails?.total?.remainingBytes?currentPackDetails.total.remainingBytes:currentPackDetails.remainingBytes).size
      } else {
        return formatBytes(parseInt(data?.total?.dataUsageRemainingInBytes?data.total.dataUsageRemainingInBytes:data.dataUsageRemainingInBytes)).size
      }
    }
  }

  return (
    <M.MyDataPlanBodyContentCard>
      <M.MyDataPlanBodyBox>
        <M.MyDataPlansDataText>
          {t('mypage_data-plans-card-details.data-available')} :{' '}
          <M.MyDataPlansBytesText currentdata={dataConsumed ?? (data?.total?.dataUsageRemainingInBytes?data.total.dataUsageRemainingInBytes:data.dataUsageRemainingInBytes)}>
            {getBalanceData()}&nbsp;
            {getBalanceDataSize()}
          </M.MyDataPlansBytesText>
        </M.MyDataPlansDataText>
        {(data?.dctStatus === 'active' || data?.dctStatus === 'disabled') && (
          <M.MyDataPlansDataText>
            {t('mypage_data-plans-card-details.last-updated')} :{' '}
            <M.MyDataPlansUpdateText>
              {data?.dataLastUpdatedAt
                ? moment(data?.dataLastUpdatedAt).format('DD MMM YYYY hh:mm a')
                : moment(data?.createdAt).format('DD MMM YYYY hh:mm a')}
            </M.MyDataPlansUpdateText>
          </M.MyDataPlansDataText>
        )}
      </M.MyDataPlanBodyBox>
      <M.MyDataPlanBodyComboBox>
      <M.MyDataPlanBodySubBox>
      <M.MyDataPlanBodySubContentBox>
        <CustomProgessBar
          dctStatus={data?.dctStatus}
          remainValue={data?.total?.dataUsageRemainingInBytes?data.total.dataUsageRemainingInBytes:remainData}
          totalValue={data?.total?.dataLimitInBytes?data.total.dataLimitInBytes:data.dataLimitInBytes}
          esimStatus={data?.esimStatus}
        />
      </M.MyDataPlanBodySubContentBox>
      <M.MyDataPlansContainer>
        <M.MyDataPlansText sx={{ textAlign: 'left' }}>
          {isDisplayRaward !== false && (
            <>
              {data?.type === 'gift' && (data?.isExpired === true ? t('mypage_data-plans-card-details.reward-earned') : t('mypage_data-plans-card-details.estimated-rewards'))}
              {data?.type === 'self' && (data?.isExpired === true ? t('mypage_data-plans-card-details.reward-earned') : t('mypage_data-plans-card-details.estimated-rewards'))}
              <M.MyDataPlanBodySubText>
                <img src={GiantDollor} alt="GiantDollor" style={{ width: '15px', height: '13px' }} />
                &nbsp;
                {data?.total?.estimated_dct_reward?.amount ? Math.round((data.total.estimated_dct_reward.amount + Number.EPSILON)*1000)/1000 : data?.estimated_dct_reward?.amount ? data.estimated_dct_reward.amount :'0'}
              </M.MyDataPlanBodySubText>
            </>
          )}
        </M.MyDataPlansText>
        <M.MyDataPlansText sx={{ color: DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) > 0 ? '#66717B' : '#DE1428 !important' }}>
          {(data?.dctStatus === 'minted' || data?.dctStatus === 'inactive') && data?.isMinted === 'success' ? (
            <M.MyDataPlansActivateText >
              {t('mypage_data-plans-card-details.activate-in')}{' '}
              {pathname.split('/').includes('payment')
                ? offerData?.activationPeriod
                : walletPurchaseData?.offer?.activationPeriod ?? data?.offer?.activationPeriod}{' '}
              {t('mypage_data-plans-card-details.days')}
            </M.MyDataPlansActivateText>
          ) : (
            data?.isMinted === 'success' && (
              <span
                style={{
                  color: '#000000',
                  // color: DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) > 0 ? '#000000' : '#DE1428 !important',
                  fontWeight: 700,
                }}
              >
                {DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) > 0 ? DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) : ''}&nbsp;
                {DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) === 1 && t('mypage_data-plans-card-details.day-left')}
                {DateDifference(data?.total?.endTime?data.total.endTime:data.endTime) > 1 && t('mypage_data-plans-card-details.days-left')}
              </span>
            )
          )}
        </M.MyDataPlansText>
      </M.MyDataPlansContainer>
      </M.MyDataPlanBodySubBox>
      </M.MyDataPlanBodyComboBox>
    </M.MyDataPlanBodyContentCard>
  )
}

export default PlanDetailsCard
