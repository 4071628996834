import styled from '@emotion/styled'
import { Box, Typography, Checkbox, FormGroup, FormControl } from '@mui/material'
import { Link } from 'react-router-dom'

export namespace S {
  export const ScanContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    padding: '20px 40px 20px 50px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      gap: '1rem',
      height:'69vh',
    },
    [theme.breakpoints.down(400)]: {
      overflowX: 'hidden',
      height: '52vh',
      overflowY: 'auto',
    },
  }))
  export const ScanContent = styled(Box)(({ theme }: any) => ({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    justifyContent:'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: '2',
      gap:'10px'
    },
  }))
  export const ScanQRContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
     gap:'0'
    },
  }))
  export const ScanQR = styled(Box)(({ theme,medium }: any) => ({
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      // order: '1',
      display:medium ? 'flex':'none',
      justifyContent: 'center',
    },
  }))
  export const SubContentContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }))
  export const CustomFormGroup = styled(FormGroup)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.625rem',
    [theme.breakpoints.down('md')]: {
     gap:'1rem'
    },
  }))
  export const CustomFormControl = styled(FormControl)(({ theme }: any) => ({
    margin: '0.8rem',
    [theme.breakpoints.down('md')]: {
      alignItems:'center'
    },
  }))
export const VerifyButton = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
 
}))

 
  export const CheckBoxStyles = styled(Checkbox)(({ theme }: any) => ({
    width: '1.25rem',
    padding: 0,
    color: '#45B549',
    marginRight:"1rem",
  }))

  export const ScanTitle = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    [theme.breakpoints.down('sm')]: {
      fontSize:'19px'
    },
  }))
  export const TroubleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '21px',
    display:"flex",
    flexWrap:'wrap',
     [theme.breakpoints.down('md')]: {
      justifyContent:'center',
      alignItems:'center',
    },
  }))
  export const CustomLink = styled(TroubleText)(({ theme }: any) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor:"pointer"
  }))

  //Right container
  export const QRWrapper = styled(Box)(({ theme }: any) => ({
    border: `4px solid ${theme.palette.primary.main}`,
    height: '300px',
    width: '300px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '238px',
      height: '238px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '190px',
      height: '190px',
    },
  }))
  export const MiniText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: '700',
    paddingTop: '0.3rem',
  }))
}
