import styled from '@emotion/styled'
import { Box, Slider, Typography } from '@mui/material'

export namespace S {
  export const ProgressContainer = styled(Box)(({ theme }: any) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    alignItems: 'center',
    margin: '0.2rem 0',
    gap: '0.8rem',
    position: 'relative',
  }))

  export const CustomSliderContainer = styled(Box)(({ theme }: any) => ({
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
    justifyContent: 'flex-start',
  }))

  export const CustomSlider = styled(Slider)(({ theme, percentage, dctStatus, esimStatus }: any) => ({
    padding: '0',
    '& .MuiSlider-thumb': {
      display: 'none',
      // border: 'none',
      // background: 'none',
      // boxShadow: 'none',
      // width: '0px',
      // cursor: 'default',
      // '&:hover': {
      //   boxShadow: 'none',
      //   background: 'none',
      // },
    },
    // '& .MuiSlider-valueLabelOpen': {
    //   transform: 'translate(-100%,0%) scale(1) !important',
    // },
    // '& .MuiSlider-valueLabel': {
    //   cursor: 'default',
    //   top: 26,
    //   backgroundColor: 'unset',
    //   position: 'initial',
    //   padding: '0px',
    //   textAlign: 'center',
    //   '&:before': {
    //     display: 'none',
    //   },
    //   '& *': {
    //     background: 'transparent',
    //     color: '#fff',
    //     paddingTop: '1px',
    //     fontWeight: 700,
    //     fontSize: '0.75rem',
    //     lineHeight: '1rem',
    //   },
    // },
    '& .MuiSlider-track': {
      border: 'none',
      backgroundColor:
        dctStatus === 'minted' ||
        dctStatus === 'disabled' ||
        dctStatus === 'inactive' ||
        dctStatus === 'expired' ||
        esimStatus?.toLowerCase() === 'expired'
          ? '#66717B'
          : percentage <= 15
          ? '#F27564'
          : percentage <= 50
          ? '#FAC748'
          : percentage > 50
          ? '#00C853'
          : '#F27564',
      height: 15,
    },
    '& .MuiSlider-rail': {
      opacity: 1,
      height: 15,
      backgroundColor: '#EBEEF3',
    },
  }))

  export const SliderText = styled(Typography)(({ theme, percentage }: any) => ({
    position: 'absolute',
    left: `${percentage / 2 + 3}%`,
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    transform: 'translate(-100%,7%) scale(1) !important',
    color: 'white',
  }))
}
