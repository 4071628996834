import { Tooltip } from '@mui/material'
import React, { FC } from 'react'
interface Props {
  message: any
  customMargin?: any
  children: any
}

const CustomTooltip: FC<Props> = ({ message, customMargin, children }) => {
  return (
    <Tooltip
      arrow
      title={message}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            background: '#ffffff',
            color: 'black',
            border: '1px solid rgb(69, 181, 73)',
            boxShadow: '0px 4px 8px rgb(0 0 0 / 10%), 0px 0px 1px rgb(11 33 62 / 25%);',
          },

          '& .MuiTooltip-arrow': {
            '&::before': {
              backgroundColor: '#F3F5F7',
              border: '1px solid rgb(69, 181, 73)',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
