import styled from '@emotion/styled'
import { Button, Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'

export namespace S {
  export const ModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '2rem',
    boxShadow: '24px',
    borderRadius: '8px',
    maxWidth: '41rem',
    // minHeight: '502px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    '&:focus-visible': {
      outline: 'none',
    },
  }))
  export const DisconnectModalContainer = styled(ModalContainer)(({ theme }: any) => ({
    maxWidth: '40%',
    width: '100%',
    padding: '1.5rem',
    '@media (max-width: 600px)': {
      maxWidth: '70%',
    },
  }))

  export const ModalHeading = styled.div(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '5px',
    h2: {
      margin: '0px',
    },
  }))

  export const ModalContent = styled(Box)(({ theme }: any) => ({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    // flexWrap: 'wrap',
    gap: '0.5rem',
    maxHeight: '18rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0rem 1rem',
  }))
  export const DisconnectModalContent = styled(ModalContent)(({ theme }: any) => ({
    width: '100%',
  }))
  export const ModalContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '0.5rem',
    padding: '1rem',
    background: 'transparent',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    width: '20rem',
    borderRadius: '5px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: 1,
    },
  }))
  export const WagmiChainBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    background: 'transparent',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    width: '22rem',
    borderRadius: '5px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: 1,
    },
  }))
  export const ErrorMsgBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    background: 'transparent',
    color: '#ff6b84',
    width: '22rem',
    
  }))
  export const DisconnectModalContentBox = styled(ModalContentBox)((props) => {
    return {
      boxSizing: 'border-box',
      width: '100%',
      background: props.theme.palette.secondary.main,
      color: props.theme.palette.common.black,
      opacity: props.toggleTheme ? '1' : '0.6',
      '&:hover': {
        opacity: 1,
      },
    }
  })
  export const ConnectedAccountBox = styled(DisconnectModalContentBox)(({ theme }: any) => ({
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    opacity: 1,
  }))
  export const DisconnectBtn = styled(Button)(({ theme }: any) => ({
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    height: '2rem',
    borderRadius: '1rem',
    fontSize: '0.7rem',
  }))
  export const ModalContentBoxItems = styled(Box)(({ theme }: any) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0.6rem',
    boxSizing: 'border-box',
  }))
  export const AddressText = styled(Typography)(({ theme }: any) => ({
    fontSize: '0.8rem',
    fontWeight: '200',
  }))
  export const MainText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: '900',
  }))
  export const SourceChip = styled(Chip)(({ theme }: any) => ({
    fontSize: '0.7rem',
    fontWeight: '700',
  }))
  export const ConnectWalletButton = styled(Button)(({ theme }: any) => ({
    height: '44px',
    width: '215px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50px',
    fontSize: '18px',
    fontWeight: 400,
    textTransform: 'none',
    color: theme.palette.common.black,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  }))  
  export const ModalTabs = styled(Box)(({ theme }: any) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0.6rem',
    boxSizing: 'border-box',
    marginRight:'auto',
  }))
  export const Toggler = styled(Box)(({ theme, selected,disabled }: any) => ({
    display: 'flex',
    borderBottom: '2px solid black',
    padding: '0.5rem',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    //background: selected ? 'rgba(60, 60, 67, 0.05)' : '#fff',
    borderColor: selected ? '#45B549' : 'rgba(60, 60, 67, 0.06)',
    color: selected ? '#45B549' : 'rgba(61, 61, 61, 0.5)',
    cursor: disabled === true ? 'not-allowed' :'pointer',
    fontSize: '1.125rem',
    fontWeight: 500,
    borderRadius: '0rem',
    zIndex: selected ? 2 : 'unset',
  }))
  export const WagmiConnectButton = styled(Button)(({ theme }: any) => ({
    width: '22rem',
    border: 'none',
    borderRadius: '0px',
    fontWeight: '400',
    fontSize: '1rem',
    textTransform: 'none',
    color: theme.palette.common.black,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    lineHeight: '1',
    padding:'1rem 0.8rem',
    transition:'none',
    justifyContent:'left',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  })) 
  export const MessageBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: 'transparent',    
    width: '22rem',
    borderRadius: '5px',
    padding:'3rem 0rem'
   
  }))
  export const animateCharacter = styled(Box)(({ theme }: any) => ({    
    color: '#45b549',
    fontSize:'20px',
    fontWeight:'bold',
  }));
  
}
