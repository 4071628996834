import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Box, Button } from '@mui/material'
import styled from '@emotion/styled'

import Img from 'assets/ShopAccordianBanner.png'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'


interface Props {
  handleClose?: any
}
const TopUpModal: FC<Props> = ({ handleClose }) => {
  const { setShowPurchaseModal } = useWalletContext()
  const { setTopUpConfirmModal } = useModalContext()
  const navigate = useNavigate()

  return (   
        <TopupContainer>
          <LeftContainer>
          <TopContainer>
            <WooContainer>Congrats!</WooContainer>
            <DataContainer>Top up is done</DataContainer>
          </TopContainer>          
          <BottomContainer>
            <TextContainer>You’ll receive credits and your $GIANT rewards when the plan expires.</TextContainer>
            <ButtonContainer>
            <CheckBalanceButton onClick={() => {
                      handleClose()                      
                      setTopUpConfirmModal(false) 
                      setShowPurchaseModal(false)
                      navigate('/mypage')
                    }}>Go to My Page</CheckBalanceButton>
            </ButtonContainer>
          </BottomContainer>
          </LeftContainer>
          <RightContainer>
            <ImgContainer><TopupSuccessImg src={Img} alt="" /></ImgContainer>
          </RightContainer>
        </TopupContainer>
  )
}
export default TopUpModal

const TopupContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  background: '#fff',
  borderRadius:'6px',
  padding:'65px 10%',
  display:'flex',
  '@media (max-width: 600px)': {
    padding:'56px 8%',
    display:'grid',
  },
}))

const LeftContainer = styled(Grid)(({ theme }) => ({
  
}))

const RightContainer = styled(Grid)(({ theme }) => ({
  
}))
const TopContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: '700',
  fontFamily: 'DM Sans',
  display:'flex',
  alignItems:'center',
  marginBottom:'20px',
}))

const WooContainer = styled(Box)(({ theme }) => ({
  color: '#0B213E',
  marginRight:'10px',  
}))

const DataContainer = styled(Box)(({ theme }) => ({
  color: '#18BC46',
}))

const ImgContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 0px',
}))

const BottomContainer = styled(Box)(({ theme }) => ({
  
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  
}))

const TextContainer = styled(Box)(({ theme }) => ({
  color:'#66717B',
  fontSize: '20px',
  fontWeight: '500',
  fontFamily: 'DM Sans',
  marginBottom: '32px',
}))

const TopupSuccessImg = styled.img(({ theme }: any) => ({
  width: '145px',
  height: '120px',
}))
const CheckBalanceButton = styled(Button)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: '2.75rem',
  width: '50%',
  borderRadius: '7px',
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.primary.main,
    opacity: 0.75,
  },
  '&:disabled': {
    background: '#C5C5C5',
    color: theme.palette.primary.contrastText,
  },
}))