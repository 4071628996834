import React, { useState, FC, useEffect } from 'react'
import { Box, Modal, useMediaQuery, CircularProgress, Link } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { S } from './modal.styles'
import { S as R } from './purchase/receiver/Receiver.styles'
import { purchaseclient } from 'services/http/purchases.service'
import { useModalContext } from 'contexts/modal.context'
import GlobeIcon from 'assets/icons/GlobelIcon.svg'
import CloseIcon from '@mui/icons-material/Close'
import RedDot from 'assets/icons/RedDot.svg'
import YellowDot from 'assets/icons/YellowDot.svg'
import GreenDot from 'assets/icons/GreenDot.svg'
import { convertToPascalCase } from 'utils'
import PlanDetailsCard from 'components/cards/PlanDetailsCard'
import { useTranslation } from 'react-i18next'

const DctStatusModal: FC = () => {
  const { showDctStatusModal, setShowDctStatusModal } = useModalContext()
  const [data, setDctStatus] = useState<any>([])
  const [dctStatusLoading, setDctStatusLoading] = useState<any>(true)
  const { pathname } = useLocation()
  const medium = useMediaQuery('(max-width:599.95px)')
  const { t } = useTranslation()

  useEffect(() => {
    //console.log(showDctStatusModal)
    if (showDctStatusModal) {
      getDctStatus()
    }
  }, [showDctStatusModal])

  const getDctStatus = () => {
    setDctStatus([])
    setDctStatusLoading(true)
    purchaseclient.getPurchaseByDctId(pathname.split('/')?.pop()!)
      .then((res: any) => {
        //console.log(res)
        setDctStatus(res)
        setDctStatusLoading(false)
      })
  }
  const closePopup = ()=> { setShowDctStatusModal(false)   }

  const packSize = (data?.purchase?.offer?.quantityOfDataInBytes / (1024 * 1024) / 1024).toString()  
  let dataInGB = packSize.length > 3 ? parseFloat(packSize).toFixed(2) : packSize

  return (
    <Modal
      open={showDctStatusModal}
      onClose={() => setShowDctStatusModal(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"      
    >
        <>
          {dctStatusLoading ? (
            <S.DctStatusModalContainer sx={{ display: 'grid', placeContent: 'center', minHeight: '40vh!important' }}>
              <CircularProgress />
            </S.DctStatusModalContainer>
          ) : (
            <S.DctStatusModalContainer sx={{ minHeight: 'unset!important' }}>
              <S.PurchaseInfoHeaderContainer
                sx={{
                  background: `linear-gradient(113.74deg, ${
                      data?.region?.countryColor?.metadata?.gradientstart
                      ? data?.region?.countryColor?.metadata?.gradientstart
                      : '#CFEBE1'
                  } 9.6%,  ${
                      data?.region?.countryColor?.metadata?.gradientend
                      ? data?.region?.countryColor?.metadata?.gradientend
                      : '#F0F9F6'
                  } 91.34%)`,
                }}
              >
                <S.ModalHeader>                
                <S.ModalSubHeader>
                    <div style={{ display: 'flex', gap: '0.3rem' }}>
                      <S.ModalHeading>{dataInGB}</S.ModalHeading>
                      <S.ModalSubHeadingGB>GB</S.ModalSubHeadingGB>
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                      <Box sx={{ fontWeight: 900 }}>|</Box> &nbsp;
                      <>
                        {!data?.purchase?.offer?.validFromActivation ? (
                          <S.ModalHeaderCoverageMap>
                            { data?.purchase?.offer?.validFromActivation }{' '}
                            { data?.purchase?.offer?.validFromActivation === 1 ? 'day' : 'days'}{' '}
                            &nbsp;
                          </S.ModalHeaderCoverageMap>
                        ) : (
                          <S.ModalHeaderCoverageMap>
                            { data?.purchase?.offer?.validity }{' '}
                            { data?.purchase?.offer?.validity === 1  ? 'day' : 'days'}{' '}
                            &nbsp;
                          </S.ModalHeaderCoverageMap>
                        )}
                      </>
                    </Box>
                  </S.ModalSubHeader>                  
                  {data?.purchase?.offer?.quantityOfDataInBytes || data?.purchase?.offer?.dataLimitInBytes? 
                  <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <S.ModalHeaderCountry>
                      { data?.region?.countryCode }
                    </S.ModalHeaderCountry>
                    {data?.region?.flagCountryCode?.toUpperCase() === 'GLOBAL' ? (
                      <img src={GlobeIcon} alt="currency img" style={{ height: '2rem' }} />
                    ) : (
                      <img
                        alt="country flag"
                        style={{ height: '2rem', width: '2rem' }}
                        src={`https://giant-protocol.github.io/country-flag-icons/3x2/${
                            data?.region?.flagCountryCode?.toUpperCase()                            
                        }.svg`}
                      />
                    )}
                  </Box>:null}
                </S.ModalHeader>
                <S.CustomIconButton
                  onClick={closePopup}
                  sx={{
                    alignItems: `${medium && 'flex-start'}`,
                    padding: `${medium && '1rem 1rem 0 0'}`,
                  }}
                >
                  <CloseIcon
                    sx={{
                      border: '1px solid #DEE7FD',
                      background: '#FFFFFF',
                      padding: 'unset !important',
                    }}
                  />
              </S.CustomIconButton>
            </S.PurchaseInfoHeaderContainer>
        <S.ModalBody sx={{ padding:'2rem 2rem' }}>
          <R.ActivatedStatusBody>
          <Box
            sx={{
              display: 'flex',
              justifyContent: medium ? 'center' : 'space-between',
              paddingTop: medium ? '2rem' : '0',
            }}
          >
            <R.StatusTextContainer>
            {t('status_texts.status')} :&nbsp;{' '}
              <img
                src={
                  data?.purchase?.esimStatus?.toLowerCase() === 'error' ||
                  data?.purchase?.esimStatus?.toLowerCase() === 'expired' ||
                  data?.purchase?.esimStatus?.toLowerCase() === 'deleted' ||
                  data?.purchase?.esimStatus?.toLowerCase() === 'disabled'
                    ? RedDot
                    : data?.purchase?.esimStatus?.toLowerCase() === 'inactive' || !data?.purchase?.esimStatus
                    ? YellowDot
                    : GreenDot
                }
                alt="active logo"
                style={{ width: '23px', height: '23px' }}
              />
              &nbsp;
              <R.StatusText>
                {!data?.purchase?.esimStatus
                  ? t('status_texts.inactive')
                  : data?.purchase?.daysleft <= 0
                  ? t('status_texts.expired')
                  : convertToPascalCase(data?.purchase?.esimStatus)}
              </R.StatusText>
            </R.StatusTextContainer>
          </Box>
          <PlanDetailsCard data={data?.purchase} isDisplayRaward={false} />
         </R.ActivatedStatusBody>
        </S.ModalBody>
        </S.DctStatusModalContainer> )
        }
      </>   
    </Modal>
  )
}
export default DctStatusModal