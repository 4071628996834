import { ApiPromise, WsProvider } from '@polkadot/api'
import { apiProvider, txSend } from 'polkadot'
import { toast } from 'react-toastify'
import { stakingClient } from 'services/http/staking.service'
import { STAKE_TYPE } from 'types'
import { currencyToNumber, handleSegment, toWei } from 'utils'

export const handleStake = async (data: STAKE_TYPE) => {
  try {
    await data.loading(true)
    let weiAmount = toWei(data.stakeAmount)
    let paramFields = [true, true]
    let inputParams = [data.poolId, weiAmount]
    let { txExecute, fromAcct } = await txSend(data.account, paramFields, inputParams, 'staking', 'stake')
    const unsub = txExecute
      .signAndSend(...fromAcct, ({ status, events }: any) => {
        // console.log(status.type, 'STATUS')
        events
          .filter(({ event }: any) => apiProvider.events.system.ExtrinsicFailed.is(event))
          .forEach(
            ({
              event: {
                data: [error, info],
              },
            }: any) => {
              if (error.isModule) {
                // for module errors, we have the section indexed, lookup
                const decoded = apiProvider.registry.findMetaError(error.asModule)
                const { docs, method, section } = decoded
                toast.error(`${section}.${method}: ${docs.join(' ')}`)
                data.loading(false)
                if (data?.location === 'popover') {
                  data.refetch()
                } else {
                  data.getStakeHistoryData(1)
                }
              } else {
                // Other, CannotLookup, BadOrigin, no extra info
                toast.error(error.toString())
                data.loading(false)
                if (data?.location === 'popover') {
                  data.refetch()
                } else {
                  data.getStakeHistoryData(1)
                }
              }
            },
          )
        if (status.isInBlock) {
          toast.success('Staking in progress')
          events.forEach((record: any) => {
            const { event } = record
            const evHuman = event.toHuman()
            if (evHuman.method === 'Staked') {
              handleSegment(`Stake Success`, {
                build: process.env.REACT_APP_CURRENT_CHAIN,
                provider: data.provider,
                amount: data.stakeAmount,
                'screen name': `${data.pathname === '/earn' ? 'Earn' : 'My Page'}`,
              })
            }
          })
        }
        if (status.type === 'Invalid') {
          toast.error('Staking failed')
          data.loading(false)
          return
        }
        if (status.type === 'Finalized') {
          setTimeout(() => {
            if (data?.location === 'popover') {
              data.refetch()
              data.closePopup()
            } else {
              data.getStakeHistoryData(1)
              data.setAmount('')
              data.setSelectedProvider({ value: '', label: '', poolId: '' })
            }
            data.setFetchMyProvidersList(true)
            data.loading(false)
            toast.success('Staked successfully')
          }, 10000)
        }
      })
      .catch((err: any) => {
        toast.error(err.message)
        handleSegment(`Stake Failure`, {
          build: process.env.REACT_APP_CURRENT_CHAIN,
          provider: data.provider,
          amount: data.stakeAmount,
          'screen name': `${data.pathname === '/earn' ? 'Earn' : 'My Page'}`,
          reason: err.message,
        })
        data.loading(false)
        // handleClosePopover()
      })
  } catch (err) {
    // console.log("providerAccount doesn't exist", err)
    data.loading(false)
  }
}

export const handleUnstake = async (
  stakeAmount: string,
  account: any,
  setIsLoading: any,
  assetMetadata: any,
  poolId?: any,
  setAmount?: any,
  getUnstakeRequestData?: any,
  setSelectedProvider?: any,
  selectedProvider?: any,
  userLogin?: any,
  userDetails?: any,
  setFetchMyProvidersList?: any,
) => {
  try {
    await setIsLoading(true)
    // let providerAccount = await getProviderAccount()
    let weiAmount = toWei(stakeAmount)
    const wsProvider = new WsProvider(process.env.REACT_APP_WSPROVIDER?.split(','))
    const api = await ApiPromise.create({ provider: wsProvider })
    let paramFields = [true, true]
    let inputParams = [poolId, weiAmount]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'staking', 'unstake')
    const unsub = txExecute
      .signAndSend(...fromAcct, async ({ status, events, txHash }: any) => {
        events
          .filter(({ event }: any) => apiProvider.events.system.ExtrinsicFailed.is(event))
          .forEach(
            ({
              event: {
                data: [error, info],
              },
            }: any) => {
              if (error.isModule) {
                const decoded = apiProvider.registry.findMetaError(error.asModule)
                const { docs, method, section } = decoded
                toast.error(`${section}.${method}: ${docs.join(' ')}`)
                setIsLoading(false)
                getUnstakeRequestData(1)
              } else {
                toast.error(error.toString())
                setIsLoading(false)
                getUnstakeRequestData(1)
              }
            },
          )
        if (status.isInBlock) {
          // console.log(`Transaction included at blockHash ${status.asInBlock}`)
          // console.log(`Transaction hash ${txHash.toHex()}`)
          const signedBlock = await api.rpc.chain.getBlock(status.asInBlock)
          let blockNumber: any = signedBlock.toHuman()

          events.forEach(async (record: any) => {
            let {
              event: { data, method },
            } = record
            if (method === 'UnstakeRequestAdded') {
              let unstakeId = data.toHuman().unstakeId
              await stakingClient.postUnstakeQues(userLogin, {
                amount: parseFloat(stakeAmount),
                poolId: parseInt(poolId),
                providerName: selectedProvider.value,
                unStakeId: parseFloat(unstakeId),
                unStakeBlock: parseInt(currencyToNumber(blockNumber?.block?.header?.number)),
              })
            }
          })
        }
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setIsLoading(false)
          return
        }
        if (status.type === 'Finalized') {
          setIsLoading(false)
          getUnstakeRequestData(1)
          setAmount('')
          setSelectedProvider({ value: '', label: '', poolId: '' })
          setFetchMyProvidersList(true)
          toast.success('Unstaked')
        }
      })
      .catch((err: any) => {
        toast.error(err.message)
        setIsLoading(false)
      })
  } catch (err) {
    // console.log("providerAccount doesn't exist", err)
    setIsLoading(false)
  }
}

export const handleCancelUnstakeRequest = async (
  unstakeId: any,
  accounts: any,
  requestId: any,
  userLogin: any,
  getUnstakeRequestData: any,
  setCancelLoader: any,
) => {
  try {
    let paramFields = [true]
    let inputParams = [unstakeId]
    let { txExecute, fromAcct } = await txSend(accounts, paramFields, inputParams, 'staking', 'cancelUnstake')
    const unsub = await txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setCancelLoader(false)
        }
        if (status.type === 'Finalized') {
          stakingClient.cencelUnstakeRequest(requestId, userLogin).then((res: any) => {
            toast.success('Unstake request cancelled')
            getUnstakeRequestData(1)
            setCancelLoader(false)
          })
        }
      })
      .catch((err: any) => {
        // console.log('inside err')
        let errorMessage =
          err.message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low'
            ? 'Account balance too low'
            : err.message
        toast.error(errorMessage)
        setCancelLoader(false)
      })
  } catch (e) {
    // console.log('unstake-cancel-error', e)
    setCancelLoader(false)
  }
}
