import { Box, Typography, useMediaQuery, InputAdornment, CircularProgress } from '@mui/material'
import { FC, useState, useEffect } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PurchaseSuccess from 'assets/PurchaseSuccess.svg'
import PaymentSuccessIllustaration from 'assets/PaymentSuccessIllustration.svg'
import { useTranslation } from 'react-i18next'
import { S } from './Earn.styles'
import PolkadotButton from 'polkadot/PolkadotButton'
import ShareModal from 'components/modals/shareModal'
import { useWalletContext } from 'contexts/wallet.context'
import { PURCHASE_TYPE_BUY, PURCHASE_TYPE_GIFT } from 'utils/constants'
import { paymentClient } from 'services/http/payment.service'
import { decryptCrypto, encryptCrypto } from 'utils'
import { usePaymentContext } from 'contexts/payment.context'
import { toast } from 'react-toastify'
import { useAppContext } from 'contexts/app.context'
import { appClient } from 'services/http/app.service'

interface Props {
  handleClose: any
}

const Earn: FC<Props> = ({ handleClose }) => {
  const { purchaseType, connectedAccount, userDetails, userLogin, isWagmiConnected, isConnected } = useWalletContext()
  const { activatedPurchasedata, walletPurchaseData } = usePaymentContext()
  const { selectedOfferType, setRefetchMydata } = useAppContext()
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [shareModal, setShareModal] = useState<boolean>(false)
  const [showLinkedPage, setShowLinkedPage] = useState<boolean>(false)
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const mobileView = useMediaQuery('(max-width:599.95px)')
  const medium = useMediaQuery('(max-width:899.95px)')
  const { t } = useTranslation()

  const handleEmail = (e: any) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      setIsValidEmail(true)
    } else setIsValidEmail(false)
    setEmail(e.target.value)
  }

  useEffect(() => {
    if (connectedAccount.address !== undefined) {
      showLinkedPage && setShowLinkedPage(false)
    }
  }, [connectedAccount?.address])
  
  const handleEmailLogin = () => {
    if (isValidEmail) {
      setEmailLoading(true)
      appClient.getAppId().then((res: any) => {
        let result = decryptCrypto(res.appId)
        let data = encryptCrypto({
          paymentId: activatedPurchasedata.id !== undefined ? activatedPurchasedata.id : walletPurchaseData?.id,
          email: email,
          appId: result.appId,
        })
        paymentClient.postEmailForUrl(data, userLogin).then((res: any) => {
          if (res.status === true) {
            setShowLinkedPage(true)
            setEmailLoading(false)
          } else {
            toast.error('There is an error occured. Try later')
            setEmailLoading(false)
          }
        })
      })
    }
  }

  return (
    <S.EarnContainer>
      {/* YES_WALLET */}
      {((connectedAccount.address !== undefined && isConnected) || isWagmiConnected ) && (
        <S.ContentContainer>
          <S.TitleText>{t('earn_modal.title.sweet')}</S.TitleText>
          {medium && (
            <S.ImgContainer medium={medium}>
              <S.PurchaseSuccessImg
                src={
                  !connectedAccount.address && !userDetails?.user?.email && showLinkedPage === false
                    ? PaymentSuccessIllustaration
                    : PurchaseSuccess
                }
                alt=""
              />
            </S.ImgContainer>
          )}
          {selectedOfferType ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <S.ContentText>
                {' '}
                {t('earn_modal.view-plan-on')}
                {' '}
                <S.CustomLink to="/mypage" onClick={() => {
                  handleClose() 
                  setRefetchMydata(true)
                  }
                 }>
                 {t('earn_modal.my-page')}
                </S.CustomLink>
              </S.ContentText>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <S.ContentText>{t('earn_modal.receive')}</S.ContentText>
              <S.ContentText>
                {' '}
                {t('earn_modal.view-rewards-on')}
                {' '}
                <S.CustomLink to="/mypage" onClick={() => {
                  handleClose()
                  setRefetchMydata(true)
                  }}>
                  {t('earn_modal.my-page')}
                </S.CustomLink>
              </S.ContentText>
            </Box>
          )}
        </S.ContentContainer>
      )}
      {/* SELF_PURCHASE NO_WALLET YES_EMAIL */}
      {((purchaseType === PURCHASE_TYPE_BUY && !connectedAccount.address && userDetails?.user?.email && !isWagmiConnected) ||
        showLinkedPage === true) && (
        <S.ContentContainer>
          <S.TitleText>{t('earn_modal.title.sweet')}</S.TitleText>
          {medium && (
            <S.ImgContainer medium={medium}>
              <S.PurchaseSuccessImg
                src={
                  !connectedAccount.address && !userDetails?.user?.email && showLinkedPage === false
                    ? PaymentSuccessIllustaration
                    : PurchaseSuccess
                }
                alt=""
              />
            </S.ImgContainer>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <S.ContentText>
            {t('earn_modal.text.first')} <br /> {t('earn_modal.text.last')}
            </S.ContentText>
            <PolkadotButton style={{ width: '85%', margin: medium && 'auto' }} connectToPolkadot={true} />
            <S.CreditPurchaseDivider sx={{ padding: '0.8rem 0' }}>
              <S.CreditPurchaseDividerText>OR</S.CreditPurchaseDividerText>
            </S.CreditPurchaseDivider>
            <S.ContentText>{t('earn_modal.text.email')}</S.ContentText>
          </Box>
        </S.ContentContainer>
      )}
      {/* SELF_PURCHASE NO_WALLET NO_EMAIL */}
      {purchaseType === PURCHASE_TYPE_BUY &&
        !connectedAccount.address &&
        !userDetails?.user?.email && !isWagmiConnected &&
        showLinkedPage === false && (
          <S.ContentContainer sx={{ gap: '1.5rem !important' }}>
            <S.TitleText>{t('earn_modal.title.activation')}</S.TitleText>
            {medium && (
              <S.ImgContainer medium={medium}>
                <S.PurchaseSuccessImg
                  src={
                    !connectedAccount.address && !userDetails?.user?.email && showLinkedPage === false
                      ? PaymentSuccessIllustaration
                      : PurchaseSuccess
                  }
                  alt=""
                />
              </S.ImgContainer>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: medium ? '2rem' : '1rem' }}>
              <S.ContentText>{t('earn_modal.text.polkadot')}</S.ContentText>
              <PolkadotButton
                style={{ width: mobileView ? '100%' : '75%', margin: medium && 'auto' }}
                connectToPolkadot={true}
              />
              <S.CreditPurchaseDivider sx={{ padding: '0.3rem 0' }}>
                <S.CreditPurchaseDividerText>OR</S.CreditPurchaseDividerText>
              </S.CreditPurchaseDivider>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Typography sx={{ color: '#0B213E', fontSize: '0.9375rem' }}>
                {t('earn_modal.text.add-email')}
                </Typography>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <S.EmailInput
                    id="outlined-basic"
                    value={email}
                    placeholder="Enter email address"
                    onChange={handleEmail}
                    type="email"
                    name="email"
                    style={{ width: mobileView ? '90%' : '70%', margin: medium && 'auto' }}
                    autoComplete="email"
                    onKeyPress={(ev: any) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault()
                        handleEmailLogin()
                      }
                    }}
                    isValid={email.length !== 0 && isValidEmail}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => handleEmailLogin()}>
                          {emailLoading ? (
                            <CircularProgress color="success" size={25} />
                          ) : (
                            <NavigateNextIcon
                              sx={{
                                cursor: 'pointer',
                                fontSize: '2rem',
                                color: email.length !== 0 && isValidEmail ? '#45B549' : 'currentcolor',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          color: 'black',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </S.ContentContainer>
        )}
      {/* GIFT_PURCHASE NO_WALLET YES_EMAIL */}
      {purchaseType === PURCHASE_TYPE_GIFT && !connectedAccount.address && !isWagmiConnected && userDetails?.user?.email && (
        <S.ContentContainer sx={{ gap: '0.8rem !important' }}>
          <S.TitleText>{t('earn_modal.text.connect-wallet')}</S.TitleText>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <S.ContentText>
            {t('earn_modal.text.first')} <br /> {t('earn_modal.text.last')} 
            </S.ContentText>
            <PolkadotButton style={{ width: '85%', margin: medium && 'auto' }} connectToPolkadot={true} />
            <S.CreditPurchaseDivider sx={{ padding: '0.5rem 0' }}>
              <S.CreditPurchaseDividerText>OR</S.CreditPurchaseDividerText>
            </S.CreditPurchaseDivider>
            <S.ContentText>{t('earn_modal.text.email')}</S.ContentText>
          </Box>
        </S.ContentContainer>
      )}
      {/* GIFT_PURCHASE NO_WALLET NO_EMAIL */}
      {purchaseType === PURCHASE_TYPE_GIFT &&
        !connectedAccount.address &&
        !isWagmiConnected &&
        !userDetails?.user?.email &&
        showLinkedPage === false && (
          <S.ContentContainer sx={{ gap: '3rem !important' }}>
            <S.TitleText>{t('earn_modal.text.connect-wallet')}</S.TitleText>

            {medium && (
              <S.ImgContainer medium={medium}>
                <S.PurchaseSuccessImg
                  src={
                    !connectedAccount.address && !userDetails?.user?.email && showLinkedPage === false
                      ? PaymentSuccessIllustaration
                      : PurchaseSuccess
                  }
                  alt=""
                />
              </S.ImgContainer>
            )}
            <PolkadotButton style={{ width: '80%' }} connectToPolkadot={true} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Typography sx={{ color: '#0B213E', fontSize: '0.9375rem' }}>
                {t('earn_modal.text.send-url-self')}
                </Typography>
                <S.EmailInput
                  id="outlined-basic"
                  value={email}
                  placeholder="Enter email address"
                  onChange={handleEmail}
                  type="email"
                  name="email"
                  style={{ width: mobileView ? '90%' : '80%', margin: medium && 'auto' }}
                  autoComplete="email"
                  onKeyPress={(ev: any) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault()
                      handleEmailLogin()
                    }
                  }}
                  isValid={email.length !== 0 && isValidEmail}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => handleEmailLogin()}>
                        {emailLoading ? (
                          <CircularProgress color="success" size={25} />
                        ) : (
                          <NavigateNextIcon
                            sx={{
                              cursor: 'pointer',
                              fontSize: '2rem',
                              color: email.length !== 0 && isValidEmail ? '#45B549' : 'currentcolor',
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        color: 'black',
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </S.ContentContainer>
        )}
      {/* IMAGE_CONTAINER */}
      <S.ImgContainer>
        <S.PurchaseSuccessImg
          src={
            !connectedAccount.address && !userDetails?.user?.email && showLinkedPage === false
              ? PaymentSuccessIllustaration
              : PurchaseSuccess
          }
          alt=""
        />
      </S.ImgContainer>
      {shareModal && <ShareModal shareUrl={`${window.location.origin}/gift`} onClose={() => setShareModal(false)} />}
    </S.EarnContainer>
  )
}

export default Earn
