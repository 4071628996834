import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Typography, useMediaQuery, Tooltip, IconButton } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { B } from './styled'
import { S as D } from '../../modal.styles'
import GiantDollar from '../../../../assets/icons/GiantDollor_Green.svg'
import copyIcon from '../../../../assets/icons/copy-icon-green.svg'
import { useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useModalContext } from 'contexts/modal.context'
import { handleSegment, shortenedAddress } from 'utils'
import PaymentDropDown from 'components/dropdown/PaymentDropDown'
import PrimaryButton from 'components/buttons/PrimaryButton'
import QRCode from 'react-qr-code'
import { createPayment, getDestination, getTransactionStatus, createBulkPayment } from 'utils/constants/config'
import { PURCHASE_TYPE_SINGLE_BUY } from 'utils/constants'
import { useLocation } from 'react-router-dom'
import { userClient } from 'services/http/user.service'
import { useAppContext } from 'contexts/app.context'
import { getPaymentPrice } from './buy.helper'
import { useTranslation } from 'react-i18next'

interface Props {
  selectedPayment: any
  setSelectedPayment: any
 /*  transactionHash: any
  setTransactionHash: any */
  segmentData: any
}
/* transactionHash,
  setTransactionHash, */
const WalletPurchase: FC<Props> = ({
  selectedPayment,
  setSelectedPayment,
  segmentData,
}) => {
  const { modalData, userLogin, purchaseType, demandReward } = useWalletContext()
  const {
    paymentSuccess,
    cryptoData,
    setCryptoData,
    paymentType,
    transactionStatus,
    setPaymentId,
    setTransactionStatus,
    credits,
    purchaseOrderType,
    purchaseQuantity,
    transactionHash,
    setTransactionHash,
    paymentUsingWagmi,
    setPaymentOptionUsingWagmi,
    cryptos
  } = usePaymentContext()
  const { selectedOfferType, isTopup } = useAppContext()
  const { topUpMetaData } = useModalContext()
  const { cryptoPurchaseLoading, isModalDisabled, setCryptoPurchaseLoading, setDisableModal } = useModalContext()
  const [errorHashMessage, setErrorHashMessage] = useState('')
  const [tooltipTimer, setTooltipTimer] = useState<boolean>(false)
  const [isFetchingDestination, setIsFetchingDestination] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [paymentCreationError, setPaymentCreationError] = useState('')
  const currentPath = useLocation()
  let checkTransferStatus = useRef(false)
  const { pathname } = useLocation()
  const transHashBtn = useRef();
  const { t } = useTranslation()

  const medium = useMediaQuery('(max-width:599.95px)')
  const medium1 = useMediaQuery('(max-width:899.95px)')
  const packPrice = selectedOfferType ? Number((modalData?.resalePrice) * purchaseQuantity )?.toFixed(2) : Number((modalData?.retailPrice) * purchaseQuantity)?.toFixed(2)

  useEffect(() => {
    setTransactionStatus({
      status: 0,
      statusMessage: ''      
    })
    setPaymentOptionUsingWagmi(false);
    setPaymentId('')
    return () => {
      checkTransferStatus.current = false
    }
  }, [])

  // useEffect(() => {
  //   let interval: any
  //   let timeInterval = selectedPayment.chain === 'SOL' ? 3000 : 10000
  //   if ((transactionStatus.status === 3000 || transactionStatus.status === 3001) && showPurchaseModal) {
  //     interval = setInterval(() => {
  //       handleTransaction(paymentId)
  //     }, timeInterval)
  //   }
  //   return () => clearInterval(interval)
  // }, [transactionStatus.status, showPurchaseModal])

  const handleTooltip = () => {
    setTooltipTimer(true)
    setTimeout(() => {
      setTooltipTimer(false)
    }, 2000)
  }
  //console.log("cryptos : ", cryptos);
  const handleGetDepositAddress = () => {
    // handleSegment('Clicked Generate Deposit Address', {
    //   build: process.env.REACT_APP_CURRENT_CHAIN,
    //   offer: segmentData,
    //   network: selectedPayment.value,
    //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
    //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
    // })
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Generate Deposit Address',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData,
            network: selectedPayment.value,
            'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
            'screen name': `${currentPath.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
          },
        },
        userLogin,
      )
    }

    if (showWarning) setShowWarning(false)
    setIsFetchingDestination(true)

    getDestination(userLogin, selectedPayment.chain)
      .then((res) => {
        if (res.status === 200) {
          setCryptoData(res.data)
          setIsFetchingDestination(false)
          // handleSegment('Deposit Address Generated', {
          //   build: process.env.REACT_APP_CURRENT_CHAIN,
          //   offer: segmentData,
          //   network: selectedPayment.value,
          //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
          //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
          //   'deposit address': res.data.depositAddress,
          // })

          if (userLogin) {
            userClient.postUserEventsWithAuth(
              {
                eventName: 'Deposit Address Generated',
                properties: {
                  build: process.env.REACT_APP_CURRENT_CHAIN,
                  offer: segmentData,
                  network: selectedPayment.value,
                  'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                  'screen name': `${
                    currentPath.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'
                  }`,
                  'deposit address': res.data.depositAddress,
                },
              },
              userLogin,
            )
          }
        }
      })
      .catch((e) => {
        setIsFetchingDestination(false)
        // console.log('Error fetching destination--->', e)
      })
  }

  const handleTransaction = (payId: any) => {
    getTransactionStatus(payId, transactionHash, userLogin)
      .then((res) => {
        if (checkTransferStatus.current === true) {
          if (res.message.status !== 2000) {
            setTimeout(() => {
              handleTransaction(payId)
            }, 10000)
          } else {
            setDisableModal(false)
          }
        }
        setTransactionStatus(res.message)
      })
      .catch((err) => {
        /**
         * @todo handle error
         */
        setTransactionStatus({
          status: err?.response?.data?.statusCode ?? 500,
          statusMsg: err?.response?.data?.message ?? err.message,
        })
        setCryptoPurchaseLoading(false)
        setDisableModal(false)
      })
  }
  const setTransactionHashWagmi=()=>{
    //console.log("transactionHash : ",transactionHash)
    handleCreatePayment();
  }
  useEffect(()=>{
    //console.log("transactionHash : ",transactionHash)
    if(transactionHash !== "" && paymentUsingWagmi){
      checkTransferStatus.current = true;
      handleCreatePayment();
    }    
  },[transactionHash])
  const handleCreatePayment = () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Buy button on USDC payment',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData,
            network: selectedPayment.value,
            'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
            'screen name': `${currentPath.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
            'transaction hash': transactionHash,
          },
        },
        userLogin,
      )
    }

    setDisableModal(true)

   // const variables = {
   //   data: {
   //     paymentType: paymentType,
   //     offerId: modalData?._id,
   //    purchaseType: purchaseType,
   //     isTopup: isTopup,
   //     purchaseId:isTopup?topUpMetaData?.id:null,
   //     chain: selectedPayment.chain,
   //    transactionHash: transactionHash,
   //     referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
   //    },
   //  }

    setCryptoPurchaseLoading(true)
    if(purchaseOrderType === PURCHASE_TYPE_SINGLE_BUY){
        let variables = {
          data: {
            paymentType: paymentType,
            offerId: modalData?._id,
            purchaseType: purchaseType,
            isTopup: isTopup,
            purchaseId:isTopup?topUpMetaData?.id:null,
            isResalePack:selectedOfferType?true:false,
            chain: selectedPayment.chain,
            transactionHash: transactionHash,
            referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
          },
        }
        createPayment(variables.data, userLogin)
        .then((res) => {
          setPaymentId(res.id)
          handleTransaction(res.id)
        })
        .catch((e) => {
          setCryptoPurchaseLoading(false)
          setDisableModal(false)
          setPaymentCreationError(e?.response?.data?.message)
        })
    }else{
        let variables = {
          data: {
            paymentType: paymentType,
            offerId: modalData?._id,
            purchaseType: purchaseType,
            isResalePack:selectedOfferType?true:false,
            chain: selectedPayment.chain,
            transactionHash: transactionHash,
            referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
            purchaseQuantity:purchaseQuantity,
            platform:'web'
          },
        }
        createBulkPayment(variables.data, userLogin)
        .then((res) => {
          setPaymentId(res.id)
          handleTransaction(res.id)
          
        })
        .catch((e) => {
          setCryptoPurchaseLoading(false)
          setDisableModal(false)
          setPaymentCreationError(e?.response?.data?.message)
        })
    }    
  }

  const handleTxHashChange = (e: any) => {
    let hash = e.target.value
    let message: string = ''
    if (hash === cryptoData?.depositAddress && hash !== '') {
      message = hash === cryptoData?.depositAddress ? 'TXhash not valid' : ''
    }
    if (hash !== '' && selectedPayment.chain === 'ETH') {
      message = hash.startsWith('0x') === false || hash.length < 66 ? 'TXhash not valid' : ''
    }
    if (selectedPayment.chain === 'SOL' && hash !== '') {
      message = hash.length < 80 ? 'TXhash not valid' : ''
    }
    setTransactionHash(hash)
    setErrorHashMessage(message)
  }

  useEffect(() => {
    if (selectedPayment.value !== 'Select your Blockchain') {
      let path = currentPath.pathname

      if (userLogin) {
        userClient.postUserEventsWithAuth(
          {
            eventName: 'Selected Blockchain Network',
            properties: {
              build: process.env.REACT_APP_CURRENT_CHAIN,
              offer: segmentData,
              'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
              network: selectedPayment.chain,
              'screen name': `${path === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
            },
          },
          userLogin,
        )
      }
    }
  }, [selectedPayment])

  return (
    <B.ActivateBuyModalBody>
        <B.PurchaseContainer
          onClick={() => {
            if (showWarning) setShowWarning(false)
          }}
        >
          <B.DetailsContainer>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <B.InputContainer>
                <PaymentDropDown
                  isDisable={isModalDisabled}
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                />{' '}
                
                {selectedPayment.value !== 'Select your Blockchain' && cryptoData?.depositAddress && (
                  <>
                    
                  
                    <B.TransactionHash
                      value={transactionHash}
                      placeholder="Enter Payment Transaction Hash"
                      disableUnderline
                      disabled={isModalDisabled === true}
                      onChange={(e: any) => handleTxHashChange(e)}
                    />
                    {errorHashMessage !== '' && <B.ErrorText>{errorHashMessage}</B.ErrorText>}
                  </>
                )}
              </B.InputContainer>
              <B.WarningInfoContainer>
                <IconButton
                  disabled={isModalDisabled === true}
                  onClick={() => {
                    setShowWarning(showWarning ? false : true)
                  }}
                >
                  <InfoOutlinedIcon sx={{ cursor: 'pointer' }} />
                </IconButton>
                {showWarning && (
                  <B.PaymentInitiated>
                    Make sure to only transfer USDC. Any other token transferred cannot be recovered.
                  </B.PaymentInitiated>
                )}
              </B.WarningInfoContainer>
            </Box>

            {/* Need to update  */}
            {medium1 && (
              <D.QRContainer medium1={medium1}>
                <D.PaymentMessage>{selectedPayment?.paymentMessage}</D.PaymentMessage>
                {selectedPayment.value !== 'Select your Blockchain' && (
                  <D.QRWrapper>
                    <B.QRBox>
                      <QRCode
                        value={cryptoData?.depositAddress ?? ''}
                        size={210}
                        style={{ padding: '0.5rem', opacity: !Boolean(cryptoData?.depositAddress) ? 0.2 : 'unset' }}
                      />
                      {cryptoData?.depositAddress === undefined && (
                        <PrimaryButton
                          style={{
                            height: '2.5rem',
                            width: '90%',
                            position: 'absolute',
                            fontSize: '1rem !important',
                            padding: '1rem 0',
                          }}
                          onClick={handleGetDepositAddress}
                          disable={isFetchingDestination}
                        >
                          {isFetchingDestination ? <D.ContrastProgress /> : 'Generate Deposit Address'}
                        </PrimaryButton>
                      )}
                    </B.QRBox>
                    {cryptoData?.depositAddress !== undefined && (
                      <D.DepositAddressContainer>
                        <Tooltip title={tooltipTimer ? 'copied!' : ''} arrow>
                          <B.DepositAddressText
                            onClick={() => {
                              navigator.clipboard.writeText(cryptoData?.depositAddress)
                              if (!tooltipTimer) handleTooltip()
                              // handleSegment('Copied Deposit Address', {
                              //   build: process.env.REACT_APP_CURRENT_CHAIN,
                              //   offer: segmentData,
                              //   network: selectedPayment.value,
                              //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                              //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
                              //   'deposit address': cryptoData.depositAddress,
                              // })

                              if (userLogin) {
                                userClient.postUserEventsWithAuth(
                                  {
                                    eventName: 'Copied Deposit Address',
                                    properties: {
                                      build: process.env.REACT_APP_CURRENT_CHAIN,
                                      offer: segmentData,
                                      network: selectedPayment.value,
                                      'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                                      'screen name': `${
                                        currentPath.pathname === '/'
                                          ? 'Home'
                                          : selectedOfferType
                                          ? 'Shop/Resale'
                                          : 'Shop'
                                      }`,
                                      'deposit address': cryptoData.depositAddress,
                                    },
                                  },
                                  userLogin,
                                )
                              }
                            }}
                          >
                            {shortenedAddress(cryptoData?.depositAddress)}
                          </B.DepositAddressText>
                        </Tooltip>

                        <Tooltip title={tooltipTimer ? 'copied!' : ''} arrow>
                          <img
                            src={copyIcon}
                            alt="Copy Icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigator.clipboard.writeText(cryptoData?.depositAddress)
                              if (!tooltipTimer) handleTooltip()
                              // handleSegment('Copied Deposit Address', {
                              //   build: process.env.REACT_APP_CURRENT_CHAIN,
                              //   offer: segmentData,
                              //   network: selectedPayment.value,
                              //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                              //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
                              //   'deposit address': cryptoData.depositAddress,
                              // })

                              if (userLogin) {
                                userClient.postUserEventsWithAuth(
                                  {
                                    eventName: 'Copied Deposit Address',
                                    properties: {
                                      build: process.env.REACT_APP_CURRENT_CHAIN,
                                      offer: segmentData,
                                      network: selectedPayment.value,
                                      'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                                      'screen name': `${
                                        currentPath.pathname === '/'
                                          ? 'Home'
                                          : selectedOfferType
                                          ? 'Shop/Resale'
                                          : 'Shop'
                                      }`,
                                      'deposit address': cryptoData.depositAddress,
                                    },
                                  },
                                  userLogin,
                                )
                              }
                            }}
                          />
                        </Tooltip>
                      </D.DepositAddressContainer>
                    )}
                  </D.QRWrapper>
                )}
              </D.QRContainer>
            )}
            {/*<WagmiWalletPurchase setTransactionHashWagmi={setTransactionHashWagmi}/>*/}
            <D.RewardContents sx={{ display: 'flex', alignItems: medium1 && 'center', marginTop:"5rem" }}>
              <D.PayText>{t('shop_plancard-pay')} {getPaymentPrice(packPrice, credits)} USDC</D.PayText>
              <D.EarnText>
              {t('shop_plancard-earn')}
                <img src={GiantDollar} alt="Giant Dollar" style={{ paddingLeft: '0.2rem' }} />{' '}
                {!selectedOfferType ? Number((modalData?.retailPrice * demandReward) * purchaseQuantity).toFixed(2) : '0.00'}
              </D.EarnText>
              {/* {selectedPayment?.chainName === 'Ethereum' && <D.GasFeeText>(0.75 USDC gas fee)</D.GasFeeText>} */}
              
              
              <PrimaryButton
                style={{ height: '2.5rem', width: `${medium ? '60% ' : '10.25rem'}` }}
                disable={
                  transactionHash === '' ||
                  cryptoData?.depositAddress === undefined ||
                  cryptoPurchaseLoading ||
                  errorHashMessage !== ''
                }
                onClick={() => {
                  checkTransferStatus.current = true
                  if (showWarning) setShowWarning(false)
                  if (!cryptoPurchaseLoading) {
                    handleCreatePayment()
                  }
                }}
              >
                {cryptoPurchaseLoading ? <D.ContrastProgress /> : 'Submit'}
              </PrimaryButton>
              {paymentCreationError !== '' ? (
                <Box sx={{ height: '1.5rem' }}>
                  <Typography sx={{ fontSize: '1rem', color: '#D45E35' }}>{paymentCreationError}</Typography>
                </Box>
              ) : (
                <Box sx={{ height: '1.5rem' }}>
                  {transactionStatus.status === 0 && !cryptoPurchaseLoading && (
                    <B.TroubleText>
                      By clicking on Buy you are agreeing to our&nbsp;
                      <B.CustomLink
                        component="span"
                        onClick={async () => {
                          await (window as any).analytics.track('Clicked on Terms of service link')
                          window.open('https://giantprotocol.org/tos/')
                        }}
                      >
                        terms and condition{' '}
                        <IconButton sx={{ padding: '3px' }}>
                          <OpenInNewIcon sx={{ color: '#45B549', fontSize: '0.8rem' }} />
                        </IconButton>
                      </B.CustomLink>
                    </B.TroubleText>
                  )}
                  {transactionStatus?.status === 0 && cryptoPurchaseLoading && (
                    <Typography sx={{ fontSize: '10px', color: '#D45E35' }}>
                      Do not close this window if you have initiated the payment.
                    </Typography>
                  )}
                  {transactionStatus?.status === 3001 && (
                    <B.PaymentConfirming>Confirming payment transaction ...</B.PaymentConfirming>
                  )}
                  {transactionStatus?.status === 3000 && <B.TransferPending>Pending Transfer ...</B.TransferPending>}
                  {transactionStatus?.status === 4000 && (
                    <B.TransactionNotFound>
                      Transaction was not found. Please reenter a valid Ethereum transaction hash.
                    </B.TransactionNotFound>
                  )}
                  {transactionStatus?.status >= 400 && transactionStatus?.status <= 503 && (
                    <B.TransactionNotFound>{transactionStatus?.statusMsg}</B.TransactionNotFound>
                  )}
                </Box>
              )}
            </D.RewardContents>
          </B.DetailsContainer>
          <D.QRContainer>
            <D.PaymentMessage>{selectedPayment?.paymentMessage}</D.PaymentMessage>
            {selectedPayment.value !== 'Select your Blockchain' && (
              <D.QRWrapper>
                <B.QRBox>
                  <QRCode
                    value={cryptoData?.depositAddress ?? ''}
                    size={210}
                    style={{ padding: '0.5rem', opacity: !Boolean(cryptoData?.depositAddress) ? 0.2 : 'unset' }}
                  />
                  {cryptoData?.depositAddress === undefined && (
                    <PrimaryButton
                      style={{
                        height: '2.5rem',
                        width: '90%',
                        position: 'absolute',
                        fontSize: '1rem !important',
                        padding: '1rem 0',
                      }}
                      onClick={handleGetDepositAddress}
                      disable={isFetchingDestination}
                    >
                      {isFetchingDestination ? <D.ContrastProgress /> : 'Generate Deposit Address'}
                    </PrimaryButton>
                  )}
                </B.QRBox>
                {cryptoData?.depositAddress !== undefined && (
                  <D.DepositAddressContainer>
                    <Tooltip title={tooltipTimer ? 'copied!' : ''} arrow>
                      <B.DepositAddressText
                        onClick={() => {
                          navigator.clipboard.writeText(cryptoData?.depositAddress)
                          if (!tooltipTimer) handleTooltip()
                          // handleSegment('Copied Deposit Address', {
                          //   build: process.env.REACT_APP_CURRENT_CHAIN,
                          //   offer: segmentData,
                          //   network: selectedPayment.value,
                          //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                          //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
                          //   'deposit address': cryptoData.depositAddress,
                          // })

                          if (userLogin) {
                            userClient.postUserEventsWithAuth(
                              {
                                eventName: 'Copied Deposit Address',
                                properties: {
                                  build: process.env.REACT_APP_CURRENT_CHAIN,
                                  offer: segmentData,
                                  network: selectedPayment.value,
                                  'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                                  'screen name': `${
                                    currentPath.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'
                                  }`,
                                  'deposit address': cryptoData.depositAddress,
                                },
                              },
                              userLogin,
                            )
                          }
                        }}
                      >
                        {shortenedAddress(cryptoData?.depositAddress)}
                      </B.DepositAddressText>
                    </Tooltip>

                    <Tooltip title={tooltipTimer ? 'copied!' : ''} arrow>
                      <img
                        src={copyIcon}
                        alt="Copy Icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(cryptoData?.depositAddress)
                          if (!tooltipTimer) handleTooltip()
                          // handleSegment('Copied Deposit Address', {
                          //   build: process.env.REACT_APP_CURRENT_CHAIN,
                          //   offer: segmentData,
                          //   network: selectedPayment.value,
                          //   'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                          //   'screen name': `${currentPath.pathname === '/' ? 'Home' : 'Shop'}`,
                          //   'deposit address': cryptoData.depositAddress,
                          // })

                          if (userLogin) {
                            userClient.postUserEventsWithAuth(
                              {
                                eventName: 'Copied Deposit Address',
                                properties: {
                                  build: process.env.REACT_APP_CURRENT_CHAIN,
                                  offer: segmentData,
                                  network: selectedPayment.value,
                                  'payment method': paymentType !== 'crypto' ? 'Credit Card' : 'USDC',
                                  'screen name': `${
                                    currentPath.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'
                                  }`,
                                  'deposit address': cryptoData.depositAddress,
                                },
                              },
                              userLogin,
                            )
                          }
                        }}
                      />
                    </Tooltip>
                  </D.DepositAddressContainer>
                )}
              </D.QRWrapper>
            )}
          </D.QRContainer>
        </B.PurchaseContainer>      
    </B.ActivateBuyModalBody>
  )
}

export default WalletPurchase
