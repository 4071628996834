import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace regionClient {
  export const getRegions = async (token: any) => {
    const response = await httpClient.get('regions/active', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const getPopularRegions = async (token: any) => {
    return await httpClient.get('regions/popular', {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
