import styled from '@emotion/styled'
import { Button, Box } from '@mui/material'

export namespace S {
export const PrimaryCustomButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1.25rem',
  borderRadius: '0.5rem',
  '&:hover': {
    background: theme.palette.primary.main,
    opacity: 0.75,
  },
  '&:disabled': {
    background: '#C5C5C5',
    color: theme.palette.primary.contrastText,
  },
}))

export const SecondaryCustomButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.primary.main,
  background: theme.palette.secondary.main,
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1.25rem',
  borderRadius: '0.5rem',
  '&:hover': {
    opacity: 0.7,
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
}))
export const ConnectButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.primary.main,
  background: theme.palette.secondary.main,
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  borderRadius: '0.5rem',
  '&:hover': {
    opacity: 0.7,
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
}))
export const ImportBtn = styled(Button)(({ theme }: any) => ({
  position: 'relative',
  borderRadius: '5px',
  fontWeight: 600,
  fontSize: '14px',
  borderColor: '#2b6cb0',
  color: '#2b6cb0',
  background: '#fff',
  borderWidth: '2px',
  borderStyle: 'solid',
  margin: '0 0 0 auto!important',
  textTransform: 'none',
  padding: '2px 12px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const BtnDiv = styled(Box)(({ theme }: any) => ({

}))
}