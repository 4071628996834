import React, { FC } from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import { S } from './modal.styles'
import { useWalletContext } from 'contexts/wallet.context'
import CloseIcon from '@mui/icons-material/Close'
import SecondaryButton from 'components/buttons/SecondaryButton'
import PrimaryButton from 'components/buttons/PrimaryButton'

interface Props {
  onConfirmModalClose: any
}

const ConfirmModal: FC<Props> = ({ onConfirmModalClose }) => {
  const { openConfirmModal, setOpenConfirmModal } = useWalletContext()
  const mobileView = useMediaQuery('(max-width:600px)')

  const handleClose = () => {
    setOpenConfirmModal(false)
  }

  return (
    <Modal open={openConfirmModal}>
      <S.ConfirmContainer>
        <Box sx={{ minHeight: '10rem', display: 'grid', gridTemplateRows: '25% 40% 35%' }}>
          <S.CustomIconButton
            onClick={handleClose}
            sx={{ display: 'flex', justifySelf: 'flex-end', alignSelf: 'baseline' }}
          >
            <CloseIcon />
          </S.CustomIconButton>
          <S.WarningText>
            Your purchase will be interrupted if you close. Are you sure you want to continue?
          </S.WarningText>
          <S.ConfirmModalFooter
            sx={{
              justifyContent: `${mobileView ? 'center' : 'flex-end'}`,
            }}
          >
            <SecondaryButton
              onClick={() => handleClose()}
              style={{ height: '40px', width: `${mobileView ? '40%' : '20%'}` }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                onConfirmModalClose()
              }}
              style={{ height: '40px', width: `${mobileView ? '40%' : '20%'}` }}
            >
              Confirm
            </PrimaryButton>
          </S.ConfirmModalFooter>
        </Box>
      </S.ConfirmContainer>
    </Modal>
  )
}

export default ConfirmModal
