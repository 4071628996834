import { apiProvider } from 'polkadot'
import BigNumber from 'bignumber.js'
import { toDecimal } from 'utils'
import { getActivePoolIds } from '../provider'

export const getStakes = (account: any) => {
  let stakeDetail: any
  return apiProvider.query.staking
    .stakingPoolInfo(1, account)
    .then((res: any) => {
      if (!res.isEmpty) {
        stakeDetail = JSON.parse(res)
        return stakeDetail
      }
    })
    .catch((err: any) => {})
}

export const getProvidersList = async () => {
  let providersList: any
  return apiProvider.query.staking
    .stakingProviderPool(null)
    .then((res: any) => {
      if (!res.isEmpty) {
        providersList = JSON.parse(res)
        return providersList
      } else {
        return Promise.resolve([])
      }
    })
    .catch((err: any) => {
      // console.log("Can't get Providers List", err)
    })
}

export const getTotalStake = async (poolId: any) => {
  let totalStake: any
  return apiProvider.query.staking
    .stakingProviderPool(poolId)
    .then((res: any) => {
      if (!res.isEmpty) {
        totalStake = JSON.parse(res)
        let decimalVal = toDecimal(totalStake.total, 12)
        return decimalVal
      }
    })
    .then((res: any) => res)
    .catch((err: any) => {
      // console.log("Can't get totalStake", err)
    })
}

export const getSGiantBalance = async (account: any) => {
  return apiProvider.query.staking
    .account(account.address)
    .then((res: any) => {
      let sGiantBalance = JSON.parse(res)
      let decimalVal = toDecimal(sGiantBalance.free, 12)
      return decimalVal
    })
    .catch((err: any) => {
      // console.log("Can't get sGiantBalance", err)
    })
}

export const getActualPoolSize = async (poolId: any) => {
  return apiProvider.query.staking
    .stakingProviderPool(poolId)
    .then((res: any) => {
      let actualPool = JSON.parse(res)
      let decimalVal = toDecimal(actualPool.total, 12)
      return decimalVal
    })
    .catch((err: any) => {
      // console.log("Can't get actualpoolsize", err)
    })
}

export const getStakerByAddress = async (address: string, poolId: any) => {
  let stakingDetails
  return (
    apiProvider.query.staking
      // .stakingPool(poolId, address) // rpcNode
      .stakingPoolInfo(poolId, address) // devNode
      .then((res: any) => {
        if (!res.isEmpty) {
          stakingDetails = JSON.parse(res)
          return stakingDetails
        } else {
          return { total: 0, reward: 0 }
        }
      })
      .catch((err: any) => {
        // console.log("Can't get Providers List", err)
      })
  )
}

export const getTotalStakedAmount = async (address: any) => {
  const activePoolIds = await getActivePoolIds()

  let stakedAmounts = activePoolIds.map(async (poolId: any) => {
    let stakerDetails = await getStakerByAddress(address, poolId)
    return stakerDetails ?? 0
  })
  let stakedAmountsList = await Promise.all(stakedAmounts)
  let totalStakedAmount

  if (stakedAmountsList.length > 0) {
    totalStakedAmount = stakedAmountsList.reduce((a, b) => {
      return {
        total: new BigNumber(a.total).plus(b.total).toString(),
        reward: new BigNumber(a.reward).plus(b.reward).toString(),
      }
    })
  } else {
    totalStakedAmount = {
      total: 0,
      reward: 0,
    }
  }
  return totalStakedAmount
}
