import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace providerClient {
  export const getProviders = async (token: any) => {
    const response = await httpClient.get('providers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const getTotalRewards = async (token: any) => {
    const response = await httpClient.get(`/graph/total-rewards`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }
}
