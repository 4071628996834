import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material'
import { LightTheme } from './utils/theme'
import { WalletProvider } from './contexts/wallet.context'
import { PaymentProvider } from './contexts/payment.context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppProvider } from 'contexts/app.context'
import { ModalProvider } from 'contexts/modal.context'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from './wagmi/wagmiconfig';
import './i18n/config'

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={LightTheme}>
      <AppProvider>
        <ModalProvider>
          <PaymentProvider>
            <WalletProvider>
              <WagmiConfig config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </WagmiConfig>
            </WalletProvider>
          </PaymentProvider>
        </ModalProvider>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
