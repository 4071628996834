import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace offerClient {
  export const getFileredOffers = async (filter: string) => {
    const response = await httpClient.get(`offers?${filter}`)
    return response
  }
  export const getFileredResaleOffers = async (token: any, filter: string) => {
    const response = await httpClient.get(`/resale-offers/${filter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }
}
