
import { createConfig, configureChains, mainnet } from 'wagmi'
import { baseSepolia, base} from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'

const projectId:string = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!

const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet,base,baseSepolia],
    [publicProvider()],
  )
export const wagmiConfig  = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [ 
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
            appName: 'GIANT Protocol',
            },
        }), 
        new WalletConnectConnector({
            chains,
            options: {
            projectId: projectId,
            },
        }),        
    ]
})

