import styled from '@emotion/styled'
import { Box, Tab, Table, TableCell, TableRow } from '@mui/material'

export namespace S {
  export const StakeModalHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.75rem',
  }))

  export const CustomTab = styled(Tab)(({ theme }: any) => ({
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1.5rem',
    color: '#0B213E',
  }))
}
