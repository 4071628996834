import React, { CSSProperties, FC } from 'react'
import { DEVICE_TYPE, PURCHASE_TYPE_BUY, PURCHASE_TYPE_GIFT } from 'utils/constants'
import { usePaymentContext } from 'contexts/payment.context'
import { T } from './styled'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'

interface IDeviceToggleProps {
  customStyles?: CSSProperties
}

const DeviceToggle: FC<IDeviceToggleProps> = ({ customStyles }) => {
  const { deviceType, setDeviceType } = usePaymentContext()
  const { purchaseType } = useWalletContext()
  const { receiverState } = useModalContext()

  const handleToggle = (value: string) => {
    setDeviceType(value)
  }

  return (
    <T.ButtonsContainer paymentType={purchaseType !== PURCHASE_TYPE_BUY} sx={customStyles}>
      {(purchaseType !== PURCHASE_TYPE_GIFT || receiverState !== '') &&
        DEVICE_TYPE.map((item, i) => (
          <T.Toggler key={item.value} selected={deviceType === item.value} onClick={() => handleToggle(item.value)}>
            {item.label}
          </T.Toggler>
        ))}
    </T.ButtonsContainer>
  )
}

export default DeviceToggle
