import { API_STATE, useWalletContext } from 'contexts/wallet.context'
import { getCurrentRewardDemand } from 'polkadot/callmethods/provider'
import { useEffect, useState } from 'react'

export const useRewardPercentage = () => {
  const [rewardPercentage, setRewardPercentage] = useState(40)

  const { apiState } = useWalletContext()

  useEffect(() => {
    if (apiState === API_STATE.READY) {
      getCurrentRewardDemand().then((res: any) => {
        setRewardPercentage(parseFloat(res) * 100)
      })
    }
  }, [apiState])

  return rewardPercentage
}
