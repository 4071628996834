import PrimaryButton from 'components/buttons/PrimaryButton'
import { FC, useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { S } from './Gift.styles'
import { S as D } from '../../modal.styles'
import { Box, InputAdornment, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePaymentContext } from 'contexts/payment.context'
import CopyIcon from 'assets/icons/CopyAddress.svg'
import GiftIllustration from 'assets/GiftIllustration.svg'
import ShareModal from 'components/modals/shareModal'
import ShareIcon from 'assets/icons/shareIcon.svg'
import { useModalContext } from 'contexts/modal.context'
import { EARN } from 'utils/constants'
import { useLocation } from 'react-router-dom'
import { useWalletContext } from 'contexts/wallet.context'
import { userClient } from 'services/http/user.service'
import { useAppContext } from 'contexts/app.context'

const Gift: FC = () => {
  const { walletPurchaseData } = usePaymentContext()
  const { setActiveStep } = useModalContext()
  const { userLogin, connectedAccount } = useWalletContext()
  const { selectedOfferType } = useAppContext()

  const [copyState, setCopyState] = useState<any>(false)
  const [giftUrl, setGiftUrl] = useState('')
  const [shareModal, setShareModal] = useState<boolean>(false)
  const [claimLoader, setClaimLoader] = useState<boolean>(false)
  const { t } = useTranslation()
  const location = useLocation()
  const segmentData = () => {
    return {
      id: walletPurchaseData?.id,
      dataLimitInBytes: walletPurchaseData?.dataLimitInBytes,
      dataUsageRemainingInBytes: walletPurchaseData?.dataUsageRemainingInBytes,
      isProviderPurchased: walletPurchaseData?.isProviderPurchased,
      purchaseHash: walletPurchaseData?.purchaseHash,
      type: walletPurchaseData?.type,
      poolId: walletPurchaseData?.poolId,
      paymentId: walletPurchaseData?.paymentId,
      offer: walletPurchaseData?.offer,
      paidAmount: walletPurchaseData?.paidAmount,
      paidChain: walletPurchaseData?.paidChain,
      paidCurrency: walletPurchaseData?.paidCurrency,
      providerCustomerId: walletPurchaseData?.providerCustomerId,
    }
  }

  useEffect(() => {
    setGiftUrl(`${window.location.origin}/gift/${walletPurchaseData?.purchaseHash}`)
  }, [walletPurchaseData])

  const handleRewardClaim = () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Clicked Claim Rewards button',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData(),
            'gift url': `${window.location.origin}/gift/${walletPurchaseData?.purchaseHash}`,
            screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
          },
        },
        userLogin,
      )
    }

    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Gift Success popup',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            offer: segmentData(),
            screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
          },
        },
        userLogin,
      )
    }

    setActiveStep(EARN)
  }

  return (
    <S.GiftContainer>
      <S.ContentContainer>
        <S.TitleText>
          {t('gift_modal.title.first')} <br /> {t('gift_modal.title.last')}
        </S.TitleText>
        <S.GiftSuccessMobileImg src={GiftIllustration} />

        <S.GiftContainerURL>
          <Box>
            <S.CopyContainer
              sx={{
                gap: '0.5rem',
                '@media (max-width: 600px)': {
                  justifyContent: 'center',
                },
              }}
            >
              <Tooltip title={`${copyState ? 'copied!' : 'copy to clipboard'}`} arrow>
                <S.GiftInput
                  readOnly
                  value={giftUrl}
                  onClick={(e: any) => {
                    if (userLogin) {
                      userClient.postUserEventsWithAuth(
                        {
                          eventName: 'Copied gift url',
                          properties: {
                            build: process.env.REACT_APP_CURRENT_CHAIN,
                            offer: segmentData(),
                            'gift url': `${window.location.origin}/gift/${walletPurchaseData?.purchaseHash}`,
                            screenName: location.pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop',
                          },
                        },
                        userLogin,
                      )
                    }

                    e.target.select()
                    setCopyState(true)
                    setTimeout(() => {
                      setCopyState(false)
                    }, 2000)
                    navigator.clipboard.writeText(giftUrl)
                  }}
                  disableUnderline
                  endAdornment={
                    <InputAdornment position="end">
                      {!copyState ? (
                        <img
                          src={CopyIcon}
                          alt="Copy"
                          style={{ height: '47px', cursor: 'pointer' }}
                          onClick={() => {
                            setCopyState(true)
                            setTimeout(() => {
                              setCopyState(false)
                            }, 2000)
                            navigator.clipboard.writeText(giftUrl)
                          }}
                          loading="lazy"
                        />
                      ) : (
                        <S.GiftIconButton>
                          <CheckIcon />
                        </S.GiftIconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </Tooltip>
              <S.ShareButton onClick={() => setShareModal(true)}>
                <img src={ShareIcon} alt="" />
              </S.ShareButton>
            </S.CopyContainer>
            <S.SmallText>({t('gift_modal.save')})</S.SmallText>
          </Box>
        </S.GiftContainerURL>

        <PrimaryButton
          style={{ height: '3.125rem', width: '11.375rem' }}
          onClick={handleRewardClaim}
          disable={claimLoader}
        >
          {claimLoader ? (
            <D.ContrastProgress />
          ) : connectedAccount?.address === undefined ? (
            t('gift_modal.button.claim-rewards')
          ) : (
            t('gift_modal.button.continue')
          )}
        </PrimaryButton>
      </S.ContentContainer>
      <S.GiftSuccessLeftImg src={GiftIllustration} />
      {shareModal && (
        <ShareModal
          // setType={0}
          shareUrl={giftUrl}
          onClose={() => setShareModal(false)}
        />
      )}
    </S.GiftContainer>
  )
}

export default Gift
