import React, { FC, useEffect } from 'react'
import Header from './header'
import Content from './content'
import { Footer } from './footer'
import AppRouters from '../../routes/AppRouters'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PurchaseModal from 'components/modals/purchase'
import { useWalletContext } from 'contexts/wallet.context'
import { CircularProgress, Typography } from '@mui/material'
import ConnectWalletModal from './header/ConnectWalletModal'
import IntroModal from 'components/modals/IntroModal'
import VerificationModal from 'components/modals/VerificationModal'
import NetworkModal from 'components/modals/networkmodal'
import PurchaseSurvey from 'components/modals/post-purchasesurvey/PurchaseSurvey'
import EarningDetailsModal from 'components/modals/EarningDetailsModal'
import DctStatusModal from 'components/modals/DctStatusModal'
import TopUpModal from 'components/modals/TopUpModal'
import StakeModal from 'components/modals/stakemodal'
import { useModalContext } from 'contexts/modal.context'
import SoldModal from 'components/modals/soldmodal'
import { useAccount } from 'wagmi'

const Layout: FC = () => {
  const { isAppLoading } = useWalletContext()
  const { showStakeModal } = useModalContext()
  const { isConnected } = useAccount()
  const { setWagmiConnected } = useWalletContext()
  
  useEffect(() => {
    setWagmiConnected(isConnected)    
    
  }, [isConnected])
  return (
    <div className="main-layout">
      <Router>
        <Header />
        <Content>
          {isAppLoading ? (
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} />
          ) : process.env.REACT_APP_MAINTENANCE === 'true' ? (
            <Typography sx={{ fontSize: '3rem', fontWeight: 700 }}>Site Under Maintenance!</Typography>
          ) : (
            <AppRouters />
          )}
        </Content>
        <Footer />
        <PurchaseModal />
        <ConnectWalletModal />
        <IntroModal />
        <NetworkModal />
        <PurchaseSurvey />
        <EarningDetailsModal />
        <TopUpModal />
        <SoldModal />
        <StakeModal show={showStakeModal.show} type={showStakeModal.type} />
        <VerificationModal />
        <DctStatusModal />
      </Router>
      <ToastContainer autoClose={3000} position="bottom-right" closeOnClick rtl={false} draggable />
    </div>
  )
}

export default Layout
