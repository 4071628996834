import { Box } from '@mui/system'
import { Grid, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SOCIAL_ICONSTYPE } from '../../../types'
import { SOCIAL_ICONS } from '../../../utils/constants'
import FooterDropdownLogo from 'assets/icons/FooterDropdown.svg'
import ShareLogo from 'assets/icons/up-right-from-square.svg'
import FooterLogo from 'assets/icons/GiantconnectFooterLogo.svg'
import AppStoreIcon from 'assets/AppStoreIcon.svg'
import PlayStoreIcon from 'assets/PlayStoreIcon.svg'

import { S } from './styled'

export const Footer = () => {
  const mobileView = useMediaQuery('(max-width:450px)')
  const { t } = useTranslation()

  return (
    <>
      <S.CustomFooter maxWidth={false}>
        <S.FooterContainer container>
          <S.FooterLogoContainer item>
            <img style={{ height: '3rem', marginBottom: '3rem' }} src={FooterLogo} alt="" />
            {process.env.REACT_APP_CURRENT_CHAIN === 'mainnet' && (
              <S.FooterLinkText onClick={() => window.open('https://app.testnet.giantprotocol.org', '_self')}>
                <img src={FooterDropdownLogo} alt="FooterDropdownLogo" />
                {t('footer_swich-to.switch-to-testnet')}
                <img src={ShareLogo} alt="ShareLogo" />
              </S.FooterLinkText>
            )}
            {process.env.REACT_APP_CURRENT_CHAIN === 'testnet' && (
              <S.FooterLinkText onClick={() => window.open('https://app.giantprotocol.org', '_self')}>
                <img src={FooterDropdownLogo} alt="FooterDropdownLogo" />
                {t('footer_swich-to.switch-to-mainnet')}
                <img src={ShareLogo} alt="ShareLogo" />
              </S.FooterLinkText>
            )}
          </S.FooterLogoContainer>

          <S.FooterSubContainer item>
            <S.FooterHeaderText>{t('footer_foundation')}</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on About link')
                  }}
                  href="https://giantprotocol.org/about/"
                  target="_blank"
                >
                  {t('footer_about')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Blog link')
                  }}
                  href="https://giantprotocol.org/news/"
                  target="_blank"
                >
                  {t('footer_news')}
                </S.LinkButton>
              </S.FooterLinks>
              {/* <S.FooterLink to="#">Contact us</S.FooterLink> */}
            </Box>
          </S.FooterSubContainer>

          <S.FooterSubContainer item>
            <S.FooterHeaderText>{t('footer_get-involved')}</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be an Ambassador link')
                  }}
                  href="https://forms.gle/PnVp2Sza4tcwbT5n6"
                  target="_blank"
                >
                  {t('footer_ambassador')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be a Provider link')
                  }}
                  href="https://forms.gle/jsEiEsfYt3wUAw6Z8"
                  target="_blank"
                >
                  {t('footer_provider')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be a Validator link')
                  }}
                  href="https://forms.gle/qYLLEHuzBJm7auZKA"
                  target="_blank"
                >
                  {t('footer_validator')}
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.FooterSubContainer>

          <S.FooterSubContainer item>
            <S.FooterHeaderText>{t('footer_support')}</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Installation link')
                  }}
                  href="https://support.giantprotocol.org/hc/en-us/articles/9297603626519-How-do-I-install-and-activate-my-eSIM-"
                  target="_blank"
                >
                  {t('footer_guide')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on FAQs link')
                  }}
                  href="https://support.giantprotocol.org/hc/en-us/sections/1500001787622-FAQs"
                  target="_blank"
                >
                  {t('footer_faqs')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Contact Us link')
                  }}
                  href="https://support.giantprotocol.org/hc/en-us/requests/new"
                  target="_blank"
                >
                  {t('footer_contact-us')}
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.FooterSubContainer>

          <S.SocialMediaContainer
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.8rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid sx={{ display: 'flex', gap: '1rem', width: '100%', overflow: 'hidden' }}>
              {SOCIAL_ICONS.map((icon: SOCIAL_ICONSTYPE, index: any) => {
                return (
                  <S.LinkButton
                    key={index}
                    onClick={async () => {
                      await (window as any).analytics.track(`Clicked on ${icon.name}`)
                    }}
                    href={`${icon.link.toString()}`}
                    target="_blank"
                  >
                    <img src={icon.icon} style={{ cursor: 'pointer' }} alt="" />
                  </S.LinkButton>
                )
              })}
            </Grid>
            <Box
              sx={{ display: 'flex', gap: '2rem', width: '100%', justifyContent: mobileView ? 'center' : 'flex-start' }}
            >
              <S.FooterLinks>
                <a href="https://giant.app.link/webapp-footer-applink" target="_blank" rel="noreferrer">
                  <img src={PlayStoreIcon} alt="" />
                </a>
              </S.FooterLinks>
              <S.FooterLinks>
                <a href="https://giant.app.link/webapp-footer-applink" target="_blank" rel="noreferrer">
                  <img src={AppStoreIcon} alt="" />
                </a>
              </S.FooterLinks>
            </Box>
            <Box
              sx={{ display: 'flex', gap: '2rem', width: '100%', justifyContent: mobileView ? 'center' : 'flex-start' }}
            >
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Privacy Policy link')
                  }}
                  href="https://giantprotocol.org/privacy/"
                  target="_blank"
                >
                  {t('footer_privacy-policy')}
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Terms of service link')
                  }}
                  href="https://giantprotocol.org/tos/"
                  target="_blank"
                >
                  {t('footer_termsofservice')}
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.SocialMediaContainer>
        </S.FooterContainer>
      </S.CustomFooter>

      <S.CopyrightContainer maxWidth={false}>
        @2024, GIANT Protocol Foundation. All rights reserved
      </S.CopyrightContainer>
    </>
  )
}
