import React, { FC } from 'react'
import ProfileMenu from './ProfileMenu'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import BaseLogoBlue from 'assets/icons/BaseLogoBlue.svg'
import EthereumLogo from 'assets/icons/EthereumLogo.svg'
import { useAccount, useBalance, useNetwork} from 'wagmi'

interface Props { handleModalOpen: () => void}

const WagmiAddress: FC<Props> = ({ handleModalOpen }) => {
  const { chain } = useNetwork()
  const { address } = useAccount()
  const { data } = useBalance({address: address, chainId :chain?.id })   
    
  return (
    <>
      <ProfileMenu />
        <BalanceContainer onClick={() => { handleModalOpen() }}>                        
                        <LeftContainer
                          className="animate__animated animate__fadeInRight"
                          sx={{ fontWeight: 700, fontSize: '18px' }}
                        >
                          <ChainNameBox>{chain && chain?.id === 84532?'Sepolia':<img src={EthereumLogo}/>}</ChainNameBox>
                          <Box><span style={{marginRight:'5px'}}>{Number(data?.formatted).toFixed(3)}</span><span>{data?.symbol}</span></Box> 
                        </LeftContainer>
                        <img
                          src={BaseLogoBlue}
                          style={{
                            zIndex: '99',
                            background:'#CED6E0',
                            height: '2.25rem',
                            padding: '0.125rem'
                          }}
                          className="animate__animated animate__fadeIn"
                          alt="walletLogo"
                        />
                        <RightContainer
                          sx={{
                            fontWeight: 400,
                            color: '#66717B',
                            fontSize: '1.125rem',
                          }}
                          className="animate__animated animate__fadeInLeft"
                        >
                          {address?.substring(0, 6)}...
                          {address?.substring(address.length - 4)}
                        </RightContainer>
                      </BalanceContainer>
                    </>
  )
}

const LeftContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '2.5rem',
  borderRadius: '2rem 0 0 2rem',
  padding: '0 1rem',
}))
const RightContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '0 1rem',
  borderRadius: '0 2rem 2rem 0',
}))
const BalanceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: '20px',
}))
export const ChainNameBox = styled(Box)(({ theme }: any) => ({
  fontWeight: '500',
  fontSize: '14px',
  marginRight: '5px',
  color: '#45B549',
  boxSizing: 'border-box',
  width: 'min-content',
  padding: '0px 2px', 
}))
export default WagmiAddress
