import React, { useState, useEffect, FC } from 'react'
import { useMediaQuery } from '@mui/material'
import { S } from '../../modal.styles'

import CTO from '../../../../assets/crypto_dot_com_logo.png'
import GiantDollar from '../../../../assets/icons/GiantDollor_Green.svg'
import { useTranslation } from 'react-i18next'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'
import { useAppContext } from 'contexts/app.context'
import { useModalContext } from 'contexts/modal.context'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { cryptoPayment, getCryptoPaymentPrice } from './buy.helper'

interface Props {
  segmentData: any
}
const CryptoPaymentPage: FC<Props> = ({ segmentData }) => {
  const { userLogin, modalData, setShowPurchaseModal, purchaseType, userDetails, demandReward } = useWalletContext()
  const {
    paymentType,
    credits,
    userEmail,
    setUserEmail,
    isPaymentLoading,
    setIsPaymentLoading,
    checkoutUrl,
    setCheckoutUrl,
    checkoutSuccess,
    setCheckoutSuccess,
    cryptos,
    purchaseQuantity
  } = usePaymentContext()
  const { selectedOfferType, isTopup } = useAppContext()
  const { topUpMetaData } = useModalContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  let cryptoLogos:any[] = []
  const medium = useMediaQuery('(max-width:599.95px)')
  const packPrice = selectedOfferType ? Number((modalData?.resalePrice) * purchaseQuantity )?.toFixed(2) : Number((modalData?.retailPrice) * purchaseQuantity)?.toFixed(2)
  const { t } = useTranslation()

  useEffect(() => {
    if (checkoutSuccess) {
      window.open(checkoutUrl, '_self')
      setShowPurchaseModal(false)
      setIsPaymentLoading(false)
      setCheckoutSuccess(false)
    }
  }, [checkoutSuccess])

  const handleCTOPayment = async () => {
    let purchaseId = isTopup?topUpMetaData?.id:null
    cryptoPayment(
      {
        navigate,
        userLogin,
        segmentData,
        userDetails,
        pathname,
        paymentType,
        modalData,
        purchaseType,
        selectedOfferType,
        isTopup,
        purchaseId,
        setIsPaymentLoading,
        userEmail,
        setCheckoutUrl,
        setCheckoutSuccess,
      }
    )
  }

  return (
    <S.CreditPaymentContainer showEmailField = {true}>
      <S.CreditCardBdy >       
          <S.CreditCardBox>
          {/*<S.PaymentIconContainer>
             <S.PaymentIcon src={EthereumLogo} alt="EthereumLogo" title="Ethereum"></S.PaymentIcon>
             <S.PaymentIcon src={PolygonLogo} alt="PolygonLogo" title="Polygon"></S.PaymentIcon>
             <S.PaymentIcon src={UsdCoinLogo} alt="UsdCoinLogo" title="USDC"></S.PaymentIcon>
             <S.PaymentIcon src={MetamaskLogo} alt="MetamaskLogo" title="Metamask"></S.PaymentIcon>
             <S.PaymentIcon src={BaseLogo} alt="BaseLogo" title="Base"></S.PaymentIcon>
            </S.PaymentIconContainer>*/}            
            <S.StripeContainer
              sx={{
                display: 'flex',marginLeft: 'auto'
              }}
            >
              Powered by &nbsp;
              <img src={CTO} alt="Coinbase" />
            </S.StripeContainer>
          </S.CreditCardBox>              

      </S.CreditCardBdy>
      <S.CreditCardFooter>
        <S.RewardContents>
          <S.PayText>{t('shop_plancard-pay')} $ {getCryptoPaymentPrice(packPrice, credits)}</S.PayText>
          <S.EarnText>
          {t('shop_plancard-earn')}
            <img src={GiantDollar} alt="Giant Dollar" style={{ paddingLeft: '0.2rem' }} />{' '}
            {!selectedOfferType ? Number((modalData?.retailPrice * demandReward) * purchaseQuantity ).toFixed(2) : '0.00'}
          </S.EarnText>
        </S.RewardContents>
        <PrimaryButton
          onClick={() => {
            if (!isPaymentLoading) handleCTOPayment()
          }}
          style={{
            margin: `${medium ? 'auto ' : 'none'}`,
            height: '2.5rem',
            width: `${medium ? '70% ' : '11.25rem'}`,
          }}
          disable={isPaymentLoading}
        >
          {isPaymentLoading ? <S.ContrastProgress /> : 'Next'}
        </PrimaryButton>
        <S.CreditCardPaymentText>On clicking Next you’ll be taken to a Crypto.com checkout page</S.CreditCardPaymentText>
      </S.CreditCardFooter>
    </S.CreditPaymentContainer>
  )
}

export default CryptoPaymentPage
