import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link, Box, useMediaQuery, IconButton } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import styled from '@emotion/styled'
import { useModalContext } from 'contexts/modal.context'
import PrimaryButton from 'components/buttons/PrimaryButton'
import FailedIllustration from 'assets/GiftFailedIllustration.svg'
import { useAppContext } from 'contexts/app.context'

interface Props {
  messageArray:Array<string>,
  handleClose:any
}

const ActivationFailure: FC<Props> = ({ handleClose, messageArray }) => {
  const { setOpenActivationFailureModal } = useModalContext()
  const navigate = useNavigate()
  const medium = useMediaQuery('(max-width:899.95px)')  
  const { setRefetchMydata } = useAppContext()
  
  return (     
          <VerifyContainer sx={{ gap: medium && '10rem !important' }}>
            <VerifyContentContainer sx={{ paddingTop: medium ? '0' : '1rem', gap: medium && '3rem !important' }}>
               <VerifyTitleText>Something is not right.</VerifyTitleText>
                <GiftSuccessUpdatedImgMobile src={FailedIllustration} />
                {messageArray?.map((message, i) => (
                   <VerifyText key = { i }>{message}.</VerifyText>
                 ))}               
                <VerifyText>
                Still having problems? &nbsp;
                <ContactLink href={"https://support.giantprotocol.org/hc/en-us/requests/new"} >
                  Contact us{' '}
                  <IconButton sx={{ padding: '3px' }}>
                    <OpenInNewIcon sx={{ color: '#45B549', fontSize: '0.8rem' }} />
                  </IconButton>
                </ContactLink>
              </VerifyText>
            </VerifyContentContainer>            
          <PrimaryButton
            style={{ height: '50px', width: '11.375rem' }}
            onClick={() => { handleClose() ; setRefetchMydata(true) }}
          >
            Close
          </PrimaryButton>
          <GiftSuccessUpdatedImg src={FailedIllustration} />
        </VerifyContainer>         
  )
}
const VerifyContainer = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '20px 35px 35px 50px',
  boxSizing: 'border-box',
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '20rem',
  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    height: '87vh',
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    height: 'auto',
    overflow:'auto'
  },
}))
const VerifyContentContainer = styled(Box)(({ theme }: any) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.625rem',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))
const VerifyTitleText = styled(Box)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '31px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}))
const GiftSuccessUpdatedImgMobile = styled.img(({ theme }: any) => ({
  height: '15.0625rem',
  width: '25rem',
  bottom: '0',
  display: 'block',
  right: '-2rem',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
}))
const VerifyText = styled(Box)(({ theme }: any) => ({
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}))
const GiftSuccessImg = styled.img(({ theme }: any) => ({
  position: 'absolute',
  right: '0',
  height: '20rem',
  width: '20rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
const GiftSuccessUpdatedImg = styled(GiftSuccessImg)(({ theme }: any) => ({
  width: '30rem',
  bottom: '0',
  display: 'block',
  right: '-2rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
const ContactLink = styled(Link)(({ theme }: any) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontSize: '700',
}))
export default ActivationFailure