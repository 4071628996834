import { useLocation } from 'react-router-dom'
import { Chip, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { useWalletContext } from 'contexts/wallet.context'
import React, { FC } from 'react'
import { S } from './styled'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { setItem } from 'services/localStorage/localStorage.service'
import { convertToPascalCase, decryptCrypto, encryptCrypto, handleSegment } from 'utils'
import { userClient } from 'services/http/user.service'
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi'
import MetamaskLogo from 'assets/icons/MetamaskLogo.png'
import CoinbaseWalletLogo from 'assets/icons/CoinbaseWalletLogo.png'
import { usePaymentContext } from 'contexts/payment.context'
import { useTranslation } from 'react-i18next'

interface Props {
  isToggle: boolean
  handleModalClose: () => void
}

const DisconnectWalletModal: FC<Props> = ({ isToggle, handleModalClose }) => {
  const {
    accounts,
    isWagmiConnected,
    disconnectHandler,
    walletConnectHandler,
    connectedAccount,
    userLoginHandler,
    loginHandler,
    userLogin,
  } = useWalletContext()
  const { pathname } = useLocation()
  const { setPurchases } = usePaymentContext()
  const handleWallectHandler = async (account: any) => {
    setItem('connectedAccount', account)
    await userLoginHandler({ jwt: null })
    await walletConnectHandler(account)
    loginHandler(account, '', '')
    handleModalClose()
  }
  const { address, connector } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { data: ensAvatar } = useEnsAvatar({ name: ensName })
  const { disconnect } = useDisconnect()
  const { t } = useTranslation()

  return (
    <>
      <Modal
        open={isToggle}
        onClose={() => {
          handleModalClose()
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <S.DisconnectModalContainer>
          <S.ModalHeading>
            <h2 id="parent-modal-title">{t('disconnectwallet_modal.head')}</h2>

            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </S.ModalHeading>

          <S.DisconnectModalContent>
            <S.ConnectedAccountBox>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {address?
                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.2rem' }}>
                  {connector?.id === 'metaMask'?<img src={MetamaskLogo} alt="logo" style={{marginRight:'1px',width: '22px',opacity:'0.8'}} />:
                  connector?.id === 'coinbaseWallet'?<img src={CoinbaseWalletLogo} alt="logo" style={{marginRight:'1px',width: '22px',opacity:'0.8'}} />:null}
                  {t('disconnectwallet_modal.connected-to')} {connector?.name}
                  <S.MainText>{ensName ?ensName : ''}</S.MainText>
                  </Box>:
                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '0.2rem' }}>
                  <S.MainText>{ensName ?ensName :connectedAccount?.meta?.name}</S.MainText>
                  <S.SourceChip label={connectedAccount?.meta?.source} size="small" />
                </Box>}
                <S.AddressText>
                  {address?address?.substring(0, 6):connectedAccount?.address?.substring(0, 6)}.....
                  {address?address?.substring(address?.length - 10):connectedAccount?.address?.substring(connectedAccount?.address?.length - 10)}
                </S.AddressText>
              </Box>
              <S.ModalContentBoxItems>
                <S.DisconnectBtn
                  onClick={async () => {                    
                    if (userLogin) {
                      userClient.postUserEventsWithAuth(
                        {
                          eventName: 'Wallet disconnected',
                          properties: {
                            build: process.env.REACT_APP_CURRENT_CHAIN,
                            'wallet address': connectedAccount?.address,
                            'screen name': `${pathname === '/' ? 'Home' : convertToPascalCase(pathname.split('/')[1])}`,
                          },
                        },
                        userLogin,
                      )
                    }
                    await disconnectHandler()
                    setPurchases({
                      self: [],
                      gift: [],
                      myOffers:[],
                    })
                    isWagmiConnected&&disconnect()
                    handleModalClose()                    
                  }}
                >
                  {t('disconnectwallet_modal.disconnect-button')}
                </S.DisconnectBtn>
              </S.ModalContentBoxItems>
            </S.ConnectedAccountBox>
          </S.DisconnectModalContent>
          <S.DisconnectModalContent>
          {!isWagmiConnected&&<S.AddressText sx={{ width: '100%' }}>{t('disconnectwallet_modal.change-to')}</S.AddressText>}
            {accounts.length > 0 &&
              accounts.map((account: any) => {
                return (
                  <S.DisconnectModalContentBox
                    key={account.address}
                    onClick={async () => {        

                      if (userLogin) {
                        userClient.postUserEventsWithAuth(
                          {
                            eventName: 'Wallet changed',
                            properties: {
                              build: process.env.REACT_APP_CURRENT_CHAIN,
                              'wallet address': account.address,
                              'screen name': `${
                                pathname === '/' ? 'Home' : convertToPascalCase(pathname.split('/')[1])
                              }`,
                            },
                          },
                          userLogin,
                        )
                      }
                      // setItem('connectedAccount', account)
                      handleWallectHandler(account)
                      // handleModalClose()
                    }}
                    toggleTheme={account.address === connectedAccount.address}
                  >
                    <S.ModalContentBoxItems sx={{ display: 'flex', width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <S.MainText sx={{ minWidth: '100px' }}>{account?.meta?.name}</S.MainText>
                        <S.AddressText
                          sx={{ minWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                          {account.address}
                        </S.AddressText>
                      </Box>
                    </S.ModalContentBoxItems>
                  </S.DisconnectModalContentBox>
                )
              })}
          </S.DisconnectModalContent>
        </S.DisconnectModalContainer>
      </Modal>
    </>
  )
}

export default DisconnectWalletModal
