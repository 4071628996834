import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { CircularProgress, Radio, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { useModalContext } from 'contexts/modal.context'
import { useWalletContext } from 'contexts/wallet.context'
import { purchaseclient } from 'services/http/purchases.service'
import { userClient } from 'services/http/user.service'
import { S } from './IntroModal.styles'
import { useAppContext } from 'contexts/app.context'

import intro1 from 'assets/introguide/Intro1illustration.svg'
import intro3 from 'assets/introguide/Intro3illustration.svg'
import intro4 from 'assets/introguide/Intro4illustration.svg'
import intro5 from 'assets/introguide/Intro5illustration.svg'
import OpenLinkIcon from 'assets/OpenLinkIcon.svg'
import { useTranslation } from 'react-i18next'

const IntroModal: React.FC = () => {
  // Contexts
  const { showGuideModal, setShowGuideModal, buyModalCloseSurvey } = useModalContext()
  const { userLogin } = useWalletContext()
  const { selectedOfferType } = useAppContext()

  // States
  const [currentStep, setCurrentStep] = useState(1)
  const [buyModalCloseQuestion, setbuyModalCloseQuestion] = useState<any>({})
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [enterReason, setEnterReason] = useState<any>({
    type: 'Buy-data-drop-off-feedback',
    questions: {
      question: '',
      answer: '',
      reason: '',
    },
  })
  const { t } = useTranslation()
  const IntroguideTextStyle = {  color: '#18BC46',  }  
  const LinkStyles = { color: '#18BC46', textDecoration: 'none', }

  const INTRO_GUIDE_DATA = [
    {
      id: 1,
      title: (
        <>
          {t('intro_modal.page1.welcome')}
          <br />
          <span style={IntroguideTextStyle}>GIANT</span> {t('intro_modal.page1.shop')}
        </>
      ),
      content: (
        <>{t('intro_modal.page1.content.first')} <br /> {t('intro_modal.page1.content.last')}</>
      ),
      image: intro1,
    },
    {
      id: 2,
      title: (
        <>
          {t('intro_modal.page2.keep-your')} <span style={IntroguideTextStyle}>{t('intro_modal.page2.primary')}</span>
          <br />
          {t('intro_modal.page2.phone-number')}
        </>
      ),
      content: (
        <> {t('intro_modal.page2.content.first')} <br /> {t('intro_modal.page2.content.last')}</>
      ),
      image: intro3,
    },
    {
      id: 3,
      title: (
        <>
          {t('intro_modal.page3.works-with-any')} <br /> <span style={IntroguideTextStyle}> {t('intro_modal.page3.compatible')} </span>
          {t('intro_modal.page3.device')}
        </>
      ),
      content: (
        <>
          {t('intro_modal.page3.content.first')} <br /> {t('intro_modal.page3.content.second')}{' '}
          <a
            href={`https://support.giantprotocol.org/hc/en-us/articles/9297361603351-How-do-I-know-if-my-device-supports-eSIM-`}
            style={LinkStyles}
            target="_blank"
            rel="noreferrer"
          >
            {t('intro_modal.page3.content.guide')}&nbsp;
            <img src={OpenLinkIcon} alt="openlinkicon" />
          </a>{' '}
          {t('intro_modal.page3.content.last')}{' '}
        </>
      ),
      image: intro4,
    },
    {
      id: 4,
      title: (
        <>
          {t('intro_modal.page4.earn')}{' '}
          <span style={IntroguideTextStyle}>
          {t('intro_modal.page4.rewards')}
            <br />
          </span>{' '}
          {t('intro_modal.page4.with-every-purchase')}
        </>
      ),
      content: (
        <>{t('intro_modal.page4.content.first')} <br />{t('intro_modal.page4.content.last')}</>
      ),
      image: intro5,
    },
  ]
  const mobileView = useMediaQuery('(max-width:450px)')

  // This effect will remove the intro guide modal in mobile view.
  // TODO: have to fix the intro guide modal responsivenss
  useEffect(() => {
    if (buyModalCloseSurvey === '' && mobileView && showGuideModal) {
      setShowGuideModal(false)
    }
  }, [buyModalCloseSurvey, mobileView, showGuideModal])

  useEffect(() => {
    if (userLogin !== null) {
      purchaseclient.feedbackStarReview(userLogin).then((questions: any) => {
        setbuyModalCloseQuestion(questions)
        setEnterReason((prev: any) => {
          return {
            ...prev,
            questions: {
              question: questions?.BuyDataDropOffFeedback?.mq,
              answer: questions?.BuyDataDropOffFeedback?.answerEnum[0],
            },
          }
        })
      })
    }
  }, [userLogin])

  useEffect(() => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Intro guide`,
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            screenName: 'Shop',
          },
        },
        userLogin,
      )
    }
  }, [userLogin])

  const handleClose = () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Closed intro modal',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            screenName: 'Shop',
          },
        },
        userLogin,
      )
    }
    setShowGuideModal(false)
    setCurrentStep(1)
  }

  const handleCloseBuyModal = () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'Buy Data Dropoff feedback',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            status: 'skipped',
            feedback: 'N/A',
            screenName: selectedOfferType ? 'Shop/Resale' : 'Shop',
          },
        },
        userLogin,
      )
      setShowGuideModal(false)
      localStorage.setItem('isFeedbackDone', 'true')
    }
  }

  const handleNext = () => {
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Clicked on next button in intro modal slide ${currentStep}`,
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            screenName: 'Shop',
          },
        },
        userLogin,
      )
    }
    if (currentStep < INTRO_GUIDE_DATA.length) {
      setCurrentStep((prev: any) => prev + 1)
    } else {
      handleClose()
    }
  }

  const handlePrev = () => {
    setCurrentStep((prev: any) => prev - 1)
    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Clicked on prev button in intro modal slide ${currentStep}`,
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            screenName: 'Shop',
          },
        },
        userLogin,
      )
    }
  }

  const handleDisableButton = () => {
    if (enterReason?.questions?.answer?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const handlebuyModalClose = () => {
    setSubmitLoading(true)
    purchaseclient.postFeedback(enterReason, userLogin).then((res: any) => {
      setSubmitLoading(false)
      setEnterReason((prev: any) => {
        return {
          ...prev,
          questions: {
            ...prev.questions,
            answer: enterReason?.questions?.answer,
            reason: enterReason?.questions?.reason,
          },
        }
      })

      if (userLogin) {
        userClient.postUserEventsWithAuth(
          {
            eventName: 'Buy Data Dropoff feedback',
            properties: {
              build: process.env.REACT_APP_CURRENT_CHAIN,
              status: 'submitted',
              feedback: {
                question: enterReason?.questions?.question,
                answer: enterReason?.questions?.answer,
                reason: enterReason?.questions?.reason,
              },
              screenName: selectedOfferType ? 'Shop/Resale' : 'Shop',
            },
          },
          userLogin,
        )
      }

      setShowGuideModal(false)
      localStorage.setItem('isFeedbackDone', 'true')
      setEnterReason((prev: any) => {
        return {
          ...prev,
          questions: {
            question: '',
            answer: '',
            reason: '',
          },
        }
      })
    })
  }

  const handleOnChange = (e: any) => {
    setEnterReason((prev: any) => {
      return {
        ...prev,
        questions: {
          ...prev.questions,
          [e.target.name]: e.target.value,
        },
      }
    })
  }

  return (
    <Modal open={showGuideModal}>
      <S.ModalMainContainer buyModalCloseSurvey={buyModalCloseSurvey}>
        {buyModalCloseSurvey === '' &&
          INTRO_GUIDE_DATA.filter((data: any) => data.id === currentStep).map((data: any, index: any) => {
            return (
              <React.Fragment key={index}>
              
                <S.GuideModalHeader>
                  <S.IllustrationImg src={data.image} alt="" />
                  {currentStep === 4 && (
                    <>
                      <S.CloseIconContainer onClick={handleClose}>
                        <CloseIcon style={{ color: '#ffffff', height: '30px', width: '30px' }} />
                      </S.CloseIconContainer>
                    </>
                  )}
                </S.GuideModalHeader>

                <S.BodyFooterWrapper>
                  <S.IntroMaintext key={data.id}>{data.title}</S.IntroMaintext>
                  <S.IntroContentText>{data.content}</S.IntroContentText>

                  <S.GuideModalFooter>
                    <S.ButtonContaienr>
                      <PrimaryButton
                        style={currentStep === 1?{ height: '3.125rem', width: '8.75rem',background: '#C5C5C5!important' }:{ height: '3.125rem', width: '8.75rem',}}
                        onClick={currentStep === 1?handleClose:handlePrev}
                      >
                        {currentStep === 1?t('intro_modal.button.skip'):t('intro_modal.button.previous')}
                      </PrimaryButton>
                      <PrimaryButton
                        style={{ height: '3.125rem', width: '8.75rem' }}
                        onClick={currentStep === INTRO_GUIDE_DATA.length?handleClose:handleNext}
                      >
                        {currentStep === INTRO_GUIDE_DATA.length?t('intro_modal.button.close'):t('intro_modal.button.next')}
                      </PrimaryButton>
                    </S.ButtonContaienr>
                    <S.StepText>
                      {currentStep} / {INTRO_GUIDE_DATA.length}
                    </S.StepText>
                  </S.GuideModalFooter>
                </S.BodyFooterWrapper>
              </React.Fragment>
            )
          })}
        {buyModalCloseSurvey === 'BUYMODAL_CLOSE_POPUP' && (
          <div style={{ overflowY: 'auto' }}>
            <S.BuyCloseIconContainer onClick={handleCloseBuyModal}>
              <CloseIcon style={{ color: '#ffffff', height: '30px', width: '30px' }} />
            </S.BuyCloseIconContainer>

            <S.BuyModalCloseContainer>
              <S.BuyModalCloseTitle
                dangerouslySetInnerHTML={{ __html: buyModalCloseQuestion?.BuyDataDropOffFeedback?.mq }}
              />
              <S.RadioGroupButton
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={buyModalCloseQuestion?.BuyDataDropOffFeedback?.answerEnum[0]}
                name="answer"
                value={enterReason?.questions?.answer}
              >
                {buyModalCloseQuestion?.BuyDataDropOffFeedback?.answerEnum.map((list: any, index: any) => {
                  return <S.RadioButtonList key={index} value={list} control={<Radio />} label={list} onChange={handleOnChange} />
                })}
              </S.RadioGroupButton>

              <S.InputFieldSubmitContainer>
                <S.InputFieldSubContainer>
                  <S.ReasonInputFiled
                    placeholder="Enter your reason here"
                    maxLength={160}
                    type="text"
                    name="reason"
                    value={enterReason?.questions?.reason}
                    onChange={handleOnChange}
                  />

                  <S.MaxLengthCharacter>(160 characters) </S.MaxLengthCharacter>
                </S.InputFieldSubContainer>
                <S.DisableButton onClick={handlebuyModalClose} disabled={handleDisableButton()}>
                  {submitLoading ? <CircularProgress color="success" /> : 'Submit'}
                </S.DisableButton>
              </S.InputFieldSubmitContainer>
            </S.BuyModalCloseContainer>
          </div>
        )}
      </S.ModalMainContainer>
    </Modal>
  )
}

export default IntroModal
