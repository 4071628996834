import GiantConnectSmallLogo from 'assets/icons/GiantConnectSmallLogo.svg'
import GiantConnectLogo from 'assets/icons/GiantMobileLogo.svg'
import Turphone from 'assets/icons/Truphone.svg'
import WorldStride from 'assets/icons/WorldStrides.svg'
import TwitterLogo from 'assets/icons/TwitterIcon.svg'
import LinkedinLogo from 'assets/icons/LinkedInIcon.svg'
import TelegramLogo from 'assets/icons/TelegramIcon.svg'
import FacebookLogo from 'assets/icons/FacebookIcon.svg'
import DiscordLogo from 'assets/icons/DiscordIcon.svg'

import EthereumLogo from 'assets/icons/EthereumLogo.svg'
import TronLogo from 'assets/icons/TronLogo.svg'
import AlgorandLogo from 'assets/icons/AlgorandLogo.svg'
import FlowLogo from 'assets/icons/FlowLogo.svg'
import SolanaLogo from 'assets/icons/SolanaLogo.svg'
import AvalancheLogo from 'assets/icons/AvalancheLogo.svg'
import StellarLogo from 'assets/icons/StellarLogo.svg'
import HederaLogo from 'assets/icons/HederaLogo.svg'

import TangoIcon from 'assets/icons/TangoIcon.svg'

import Apygraph1 from 'assets/apyGraph1.svg'
import Apygraph2 from 'assets/apyGraph2.svg'
import Apygraph3 from 'assets/apyGraph3.svg'
import {
  SOCIAL_ICONSTYPE,
  POPULAR_COUNTRIES_TYPE,
  DATAPLANS_TYPE, Page,
} from 'types'

export enum ChainType {
  Devnet = 'devnet',
  Testnet = 'testnet',
  Mainnet = 'mainnet',
}

export const headerPages: Page[] = [
  { id: 1, title: 'Shop', route: '/shop', enabled: true },
  { id: 2, title: 'Earn', route: '/earn', enabled: process.env.REACT_APP_ENABLE_EARN_PAGE === 'true'  },
  { id: 3, title: 'My Page', route: '/mypage', enabled: true },
  { id: 4, title: 'Competition', route: '/competition', enabled: process.env.REACT_APP_ENABLE_COMPETITION_PAGE === 'true'  },
];

export interface RouteConfig {
  path: string;
  element: JSX.Element;
  enabled?: boolean; // Optional flag to enable/disable the route
}


export const PROTOCOL_ADDRESS = [
  { address: '5DkHgHXbCs4BDqjvMvYzd866UH3q9H9g9VYWeSdhj8m62SbD', logo: GiantConnectSmallLogo },
]

export const TABLE_HEADERS = ['User', 'Staked Amount', 'Stake %']
export const DASHBOARD_TABLE_HEADERS = [
  'Provider',
  'DCT Value',
  '7d %',
  'Collateral Value',
  '7d %',
  'Collateral Ratio',
  'APY',
  '',
  '',
]
export const STAKE_HISTORY_TABLE_HEADER = ['Date', 'Provider', 'Amount', 'Status']
export const STAKE_REQUEST_TABLE_HEADER = ['Id', 'Provider', 'Amount', 'Days remaining', 'Status', 'Action']
export const TABLE_HEADER_LEADERBOARD = ['#', 'Wallet Address', 'Rewards Earned']
export const MYREFERRAAL_TABLE_HEADERS = [
  'Payout Date',
  'Provider',
  'Region',
  'Amount',
  'Validity',
  'Price',
  'Earnings',
  'Status',
]
export const EARNPAGE_TABLE_CONTENT = [
  {
    id: 1,
    holder: <img src={TangoIcon} alt="" />,
    provider: 'Tango',
    stakedAmount: '168,245',
    dctPercentage: {
      percentage: '0.5 %',
      value: 'up',
    },
    earnedAmount: '1,168,245',
    earnedPercentage: {
      percentage: '1.1%',
      value: 'up',
    },
    apiPercentage: {
      percentage: '0.1%',
      value: 'down',
    },
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
    graph: <img src={Apygraph1} alt="" />,
  },
  {
    id: 2,
    holder: <img src={GiantConnectLogo} alt="" />,
    provider: 'Giant Connect',
    stakedAmount: '453,984',
    dctPercentage: {
      percentage: '0.2 %',
      value: 'up',
    },
    earnedAmount: '2,485,293',
    earnedPercentage: {
      percentage: '1.5%',
      value: 'up',
    },
    apiPercentage: {
      percentage: '0.7%',
      value: 'up',
    },
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
    graph: <img src={Apygraph2} alt="" />,
  },
  {
    id: 3,
    holder: <img src={Turphone} alt="" />,
    provider: 'Turphone',
    stakedAmount: '193,939',
    dctPercentage: {
      percentage: '0.3 %',
      value: 'down',
    },
    earnedAmount: '472,902',
    earnedPercentage: {
      percentage: '0.2%',
      value: 'down',
    },
    apiPercentage: {
      percentage: '0.3%',
      value: 'down',
    },
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
    graph: <img src={Apygraph3} alt="" />,
  },
  {
    id: 4,
    holder: <img src={WorldStride} alt="" />,
    provider: 'World Stride',
    stakedAmount: '193,939',
    dctPercentage: {
      percentage: '0.3 %',
      value: 'up',
    },
    earnedAmount: '472,902',
    earnedPercentage: {
      percentage: '0.2%',
      value: 'down',
    },
    apiPercentage: {
      percentage: '0.3%',
      value: 'up',
    },
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
    graph: <img src={Apygraph3} alt="" />,
  },
]
export const SOCIAL_ICONS: Array<SOCIAL_ICONSTYPE> = [
  {
    id: 1,
    icon: DiscordLogo,
    link: 'https://discord.gg/ECD5XS8f99',
    name: 'Discord Link',
  },
  {
    id: 2,
    icon: TelegramLogo,
    link: 'https://t.me/giantprotocol',
    name: 'Telegram Link',
  },
  {
    id: 3,
    icon: TwitterLogo,
    link: ' https://twitter.com/giantprotocol',
    name: 'Twitter Link',
  },
  {
    id: 4,
    icon: FacebookLogo,
    link: ' https://www.facebook.com/giantprotocol',
    name: 'Facebook Link',
  },
  {
    id: 5,
    icon: LinkedinLogo,
    link: 'https://www.linkedin.com/company/giantprotocol/',
    name: 'Linkedin Link',
  },
]

export const POPULAR_COUNTRIES: Array<POPULAR_COUNTRIES_TYPE> = [
  {
    id: 1,
    name: 'United States',
    flag: 'US',
  },
  {
    id: 2,
    name: 'Japan',
    flag: 'JP',
  },
  {
    id: 3,
    name: 'Singapore',
    flag: 'SG',
  },
]
export const LONGTEXT_TOOLTIPTEXT = [
  {
    popupName: 'Released:',
    title: 'available in the RSP and ready to be downloaded/installed',
  },
  {
    popupName: 'Downloaded:',
    title: 'downloaded to the user device',
  },
  {
    popupName: 'Installed:',
    title: 'installed in the user device but disabled (i.e. turned OFF)',
  },
  {
    popupName: 'Enabled:',
    title: 'enabled in the user device (i.e. turned ON)',
  },
  {
    popupName: 'Deleted:',
    title: 'deleted from the user device',
  },
  {
    popupName: 'Error:',
    title: 'error during installation, another profile shall be requested',
  },
  {
    popupName: 'Unavailble:',
    title: 'profile unavilable in the RSP',
  },
]

export const DATAPLANS: Array<DATAPLANS_TYPE> = [
  { value: '1', label: '1GB' },
  { value: '2', label: '2GB' },
  { value: '5', label: '5GB' },
  // { value: '15', label: '15GB' },
]

export const INSTALLATION_CHIP_DATA_ANDROID = [
  {
    id: 1,
    title: 'Google Pixel',
  },
  {
    id: 2,
    title: 'Samsung Galaxy Phones',
  },
  {
    id: 3,
    title: 'Huawei P40 and P40 Pro',
  },
  {
    id: 4,
    title: 'Oppo Find X3 and X5, Reno 5A',
  },
  {
    id: 5,
    title: 'Motorola Razr',
  },
  {
    id: 6,
    title: 'Rakuten Big and Big-S',
  },
  {
    id: 7,
    title: 'Sony Xperia 10 III Lite',
  },
  {
    id: 8,
    title: 'HONOR Magic 3 and Pro Plus',
  },
]
export const BULK_PAYMENT_TYPE = [
  { value: 'crypto', label: 'USDC' },
  { value: 'coinbase', label: 'Coinbase' },
]
export const PAYMENT_TYPE = [  
  { value: 'crypto', label: 'USDC' },
  { value: 'coinbase', label: 'Coinbase' },
  { value: 'cto', label: 'Crypto' },
  { value: 'creditcard', label: 'Credit Card' },  
]
export const DEVICE_TYPE = [
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
]
export const PURCHASE_TYPE_BUY = 'self'
export const PURCHASE_TYPE_GIFT = 'gift'
export const PURCHASE_TYPE_BULK_BUY = 'BULK_PURCHASE'
export const PURCHASE_TYPE_SINGLE_BUY = 'PURCHASE'
export const PURCHASE_TYPE_TOPUP = 'topup'
export const DATA_PLANS_STATUS = [
  {
    type: 'active',
    color: '#00C853',
    text: 'Active',
  },
  {
    type: 'not-redeemed',
    color: '#C4C4C4',
    text: 'Not redeemed',
  },
  {
    type: 'expired',
    color: '#DE1428',
    text: 'Expired',
  },
  {
    type: 'expired-refund',
    color: '#DE1428',
    text: 'Expired | Refund granted',
  },
  {
    type: 'minted',
    color: '#FFC430',
    text: 'Inactive',
  },
  {
    type: 'inactive-refund-pending',
    color: '#FFC430',
    text: 'Inactive | Refund request pending',
  },
  {
    type: 'inactive-refund-denied',
    color: '#FFC430',
    text: 'Inactive | Refund denied',
  },
]

export const PAYMENT_METHOD = [
  {
    id: 1,
    value: 'ETH',
    label: 'USDC Ethereum',
    logo: EthereumLogo,
  },
  {
    id: 2,
    value: 'TRX',
    label: 'USDC Tron',
    logo: TronLogo,
  },
  {
    id: 3,
    value: 'ALGO',
    label: 'USDC Algorand',
    logo: AlgorandLogo,
  },
  {
    id: 4,
    value: 'FLOW',
    label: 'USDC Flow',
    logo: FlowLogo,
  },
  {
    id: 5,
    value: 'SOL',
    label: 'USDC Solana',
    logo: SolanaLogo,
  },
  {
    id: 6,
    value: 'AVAX',
    label: 'USDC Avalanche',
    logo: AvalancheLogo,
  },
  {
    id: 7,
    value: 'XLM',
    label: 'USDC Stellar',
    logo: StellarLogo,
  },
  {
    id: 8,
    value: 'HBAR',
    label: 'USDC Hedera',
    logo: HederaLogo,
  },
]

export const REPORTISSUE_REASONS = [
  // 'I no longer need this plan ',
  "I'm unable to activate this eSIM",
  'My eSIM is installed but I’m not getting data',
  'My device doesn’t support eSIM',
  'Other',
]

//Stepper
export const BUY_STEPS = ['Buy', 'Scan', 'Earn']
export const GIFT_STEPS = ['Buy', 'Gift', 'Earn']
export const BUY_STEPS_USED = ['Buy', 'Scan']
export const GIFT_STEPS_USED = ['Buy', 'Gift']

export const dataDropdownOptions = [
  {
    value: '1',
    label: '1 GB',
  },
  {
    value: '3',
    label: '3 GB',
  },
  {
    value: '5',
    label: '5 GB',
  },
  {
    value: '10',
    label: '10 GB',
  },
  {
    value: '20',
    label: '20 GB',
  },
  {
    value: '50',
    label: '50 GB',
  },
  {
    value: '100',
    label: '100 GB',
  },
]

export const countriesWithPlaceholderFlag = [
  'AFRICA',
  'AN',
  'ASIA',
  'Global',
  'Middle East and North Africa',
  'LATAM',
  'CARIBBEAN',
  'GB-SCT',
  'ES-XCI',
]

export const FEEDBACK_STARS = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
]
export const FEEDBACK_CAROUSEL = [1, 2, 3]

//STEPS
export const BUY = 0
export const SCAN = 1
export const GIFT = 1
export const EARN = 2

//SELL_STEPS
export const CONFIRM_DETAILS = 'CONFIRM_DETAILS'
export const UNINSTALL = 'UNINSTALL'
export const VISIT_SHOP = 'VISIT_SHOP'

//UNINSTALL_STEPS
export const ANDROID_STEPS = [
  'Click the eSIM you want to uninstall.',
  'Click toggle button to disable eSIM if not already disabled.',
  'Click Erase SIM.',
]
export const IOS_STEPS = [
  'Click the eSIM you want to uninstall.',
  'Click toggle button “Turn On This Line” if not already disabled.',
  'Click Delete SIM.',
]
