import React, { FC } from 'react'
import { Typography, Button } from '@mui/material'
import { useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import WalletIcon from 'assets/icons/WalletIcon.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  style?: any
  onLoggingIn?: any
  connectToPolkadot?: boolean
}

const PolkadotButton: FC<Props> = ({ style, onLoggingIn, connectToPolkadot }) => {
  const { setConnectedModalOpen, showPurchaseModal } = useWalletContext()
  const { toggleTransferDct } = usePaymentContext()
  const { t } = useTranslation()

  const handlePolkadot = () => {
    // showPurchaseModal && onLoggingIn()
    const hasExt = window.injectedWeb3
    if (Object.keys(hasExt).includes('polkadot-js')) setConnectedModalOpen(true)
    else window.open('https://polkadot.js.org/extension/')
    connectToPolkadot && toggleTransferDct(true)
  }
  return (
    <Button
      sx={{
        width: '30%',
        background: 'rgba(69, 181, 73, 0.1)',
        borderRadius: '0.5rem',
        padding: '0.75rem',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'none',
        ...style,
      }}
      onClick={handlePolkadot}
    >
      <img src={WalletIcon} alt="" />
      <Typography sx={{ fontSize: '1.125rem', fontWeight: 'bold', color: '#0B213E' }}>
        {t('connect-wallet')}
      </Typography>
    </Button>
  )
}

export default PolkadotButton
