import { createContext, useContext, useReducer } from 'react'
import { PAYMENT_TYPE, DEVICE_TYPE } from 'utils/constants'

interface PaymentContextType {
  paymentType: string
  deviceType: string
  cryptos: any
  isViewAllPlanModal: boolean
  viewAllPlanData: any
  currentUserGiftCode: string
  mintedDct: string
  purchaseId: string
  walletPurchaseData: any
  transferDct: boolean
  activationModalLocation: string
  cryptoData: any
  paymentSuccess: boolean
  offerData: any
  activatedPurchasedata: any
  purchaseModalLoading: boolean
  myPlanCardAction: string
  apiRegions: any
  resaleRegions: any
  paymentId: string
  transactionStatus: any
  selfPurchases: any
  giftPurchases: any
  myOfferPurchases: any
  filteredOffer: any
  currentLoggedUserData: any
  credits: any
  userEmail: any
  isPaymentLoading: boolean
  checkoutUrl: string
  checkoutSuccess: boolean
  purchaseOrderType:string
  purchaseQuantity:number
  purchaseAmount:number
  transactionHash:string
  paymentUsingWagmi:boolean
  setPaymentType: (data: string) => void
  setDeviceType: (data: string) => void
  setCryptos: (data: any) => void
  setIsViewAllPlanModal: (data: boolean) => void
  setViewAllPlanData: (data: any) => void
  handleGiftCode: (data: any) => void
  setMintedDct: (data: any) => void
  handlePurchaseId: (data: any) => void
  handleCurrentLoggedUserData: (data: any) => void
  handleWalletPurchaseData: (data: any) => void
  toggleTransferDct: (data: any) => void
  activationModalLocationHandler: (data: any) => void
  setCryptoData: (data: any) => void
  setPaymentSuccess: (data: boolean) => void
  handleOfferData: (data: any) => void
  handleActivatedPurchaseData: (data: any) => void
  setPurchaseModalLoading: (data: boolean) => void
  setMyPlanCardAction: (data: string) => void
  setRegions: (data: string) => void
  setPaymentId: (data: string) => void
  setTransactionStatus: (data: any) => void
  setPurchases: (data: any) => void
  setFilteredOffer: (data: any) => void
  setResaleRegions: (data: any) => void
  setCredits: (data: any) => void
  setUserEmail: (data: any) => void
  setIsPaymentLoading: (data: any) => void
  setCheckoutUrl: (data: any) => void
  setCheckoutSuccess: (data: any) => void
  setPurchaseOrderType: (data: any) => void
  setPurchaseQuantity: (data: any) => void
  setPurchaseAmount: (data: any) => void
  setTransactionHash: (data: any) => void
  setPaymentOptionUsingWagmi: (data: any) => void
}

const initialState: PaymentContextType = {
  paymentType: PAYMENT_TYPE[0].value,
  deviceType: DEVICE_TYPE[0].value,
  cryptos: [],
  isViewAllPlanModal: false,
  viewAllPlanData: [],
  currentUserGiftCode: '',
  mintedDct: '',
  purchaseId: '',
  walletPurchaseData: {},
  transferDct: false,
  activationModalLocation: 'shop',
  cryptoData: {},
  paymentSuccess: false,
  offerData: {},
  activatedPurchasedata: {},
  purchaseModalLoading: false,
  myPlanCardAction: '',
  apiRegions: [],
  resaleRegions: [],
  paymentId: '',
  transactionStatus: { status: 0, statusMsg: '' },
  selfPurchases: [],
  giftPurchases: [],
  myOfferPurchases: [],
  filteredOffer: {},
  currentLoggedUserData: {},
  credits: 0,
  userEmail: '',
  isPaymentLoading: false,
  checkoutUrl: '',
  checkoutSuccess: false,
  purchaseOrderType: 'PURCHASE',
  purchaseQuantity:1,
  purchaseAmount:0,
  transactionHash:"",
  paymentUsingWagmi:false,
  setCryptos: () => {},
  setPaymentType: () => {},
  setDeviceType: () => {},
  setIsViewAllPlanModal: () => {},
  setViewAllPlanData: () => {},
  handleGiftCode: () => {},
  setMintedDct: () => {},
  handlePurchaseId: () => {},
  handleWalletPurchaseData: () => {},
  handleCurrentLoggedUserData: () => {},
  toggleTransferDct: () => {},
  activationModalLocationHandler: () => {},
  setCryptoData: () => {},
  setPaymentSuccess: () => {},
  handleOfferData: () => {},
  handleActivatedPurchaseData: () => {},
  setPurchaseModalLoading: () => {},
  setMyPlanCardAction: () => {},
  setRegions: () => {},
  setPaymentId: () => {},
  setTransactionStatus: () => {},
  setPurchases: () => {},
  setFilteredOffer: () => {},
  setResaleRegions: () => {},
  setCredits: () => {},
  setUserEmail: () => {},
  setIsPaymentLoading: () => {},
  setCheckoutUrl: () => {},
  setCheckoutSuccess: () => {},
  setPurchaseOrderType: () => {},
  setPurchaseQuantity: () => {},
  setPurchaseAmount: () => {},
  setTransactionHash: () => {},
  setPaymentOptionUsingWagmi: () => {},
}

const reducer = (state: PaymentContextType, action: any) => {
  switch (action.type) {
    case 'SET_PAYMENT_TYPE': {
      return { ...state, paymentType: action.payload }
    }
    case 'SET_DEVICE_TYPE': {
      return { ...state, deviceType: action.payload }
    }
    case 'SET_CRYPTOS': {
      return { ...state, cryptos: action.payload }
    }
    case 'SET_VIEWALLPLAN_MODAL': {
      return { ...state, isViewAllPlanModal: action.payload }
    }
    case 'SET_VIEWALLPLAN_MODAL_DATA': {
      return { ...state, viewAllPlanData: action.payload }
    }
    case 'SET_GIFT_CODE': {
      return { ...state, currentUserGiftCode: action.payload }
    }
    case 'SET_MINTED_DCT': {
      return { ...state, mintedDct: action.payload }
    }
    case 'SET_PURCHASE_ID': {
      return { ...state, purchaseId: action.payload }
    }
    case 'SET_WALLET_PURCHASE_ID': {
      return { ...state, walletPurchaseData: action.payload }
    }
    case 'SET_CURRENT_LOGGED_USER_DATA': {
      return { ...state, currentLoggedUserData: action.payload }
    }
    case 'TOGGLE_TRANSFER_DCT': {
      return { ...state, transferDct: action.payload }
    }
    case 'SET_ACTIVATEMODAL_LOCATION': {
      return { ...state, activationModalLocation: action.payload }
    }
    case 'SET_CRYPTO_DATA': {
      return { ...state, cryptoData: action.payload }
    }
    case 'SET_PAYMENT_SUCCESS': {
      return { ...state, paymentSuccess: action.payload }
    }
    case 'SET_OFFER_DATA': {
      return { ...state, offerData: action.payload }
    }
    case 'SET_ACTIVATED_DATA': {
      return { ...state, activatedPurchasedata: action.payload }
    }
    case 'SET_PURCHASE_MODAL_LOADING': {
      return { ...state, purchaseModalLoading: action.payload }
    }
    case 'SET_MY_PLAN_CARD_ACTION': {
      return { ...state, myPlanCardAction: action.payload }
    }
    case 'SET_REGIONS': {
      return { ...state, apiRegions: action.payload }
    }
    case 'SET_PAYMENT_ID': {
      return { ...state, paymentId: action.payload }
    }
    case 'SET_TRANSACTION_STATUS': {
      return { ...state, transactionStatus: action.payload }
    }    
    case 'SET_PURCHASES': {
      return {
        ...state,
        selfPurchases: action.payload.self,
        giftPurchases: action.payload.gift,
        myOfferPurchases: action.payload.myOffers,
      }
    }
    case 'SET_FILTERED_OFFER': {
      return { ...state, filteredOffer: action.payload }
    }
    case 'SET_RESALE_REGIONS': {
      return { ...state, resaleRegions: action.payload }
    }
    case 'SET_CREDITS': {
      return { ...state, credits: action.payload }
    }
    case 'SET_USER_EMAIL': {
      return { ...state, userEmail: action.payload }
    }
    case 'SET_IS_PAYMENT_LOADING': {
      return { ...state, isPaymentLoading: action.payload }
    }
    case 'SET_CHECKOUT_URL': {
      return { ...state, checkoutUrl: action.payload }
    }
    case 'SET_CHECKOUT_SUCCESS': {
      return { ...state, checkoutSuccess: action.payload }
    }
    case 'SET_PURCHASEORDER_TYPE': {
      return { ...state, purchaseOrderType: action.payload }
    }
    case 'SET_PURCHASEQUANTITY': {
      return { ...state, purchaseQuantity: action.payload }
    }
    case 'SET_PURCHASEAMOUNT': {
      return { ...state, purchaseAmount: action.payload }
    }
    case 'SET_TRANSACTION_HASH': {
      return { ...state, transactionHash: action.payload }
    }
    case 'PAYMENTUSING_WAGMI': {
      return { ...state, paymentUsingWagmi: action.payload }
    }
    default: {
      throw new Error(`Unknown type: ${action.type}`)
    }
  }
}

export const PaymentContext = createContext<PaymentContextType>(initialState)

const PaymentProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handlePaymentType = (data: string) => {
    dispatch({ type: 'SET_PAYMENT_TYPE', payload: data })
  }
  const handleDeviceType = (data: string) => {
    dispatch({ type: 'SET_DEVICE_TYPE', payload: data })
  }
  const handleCryptos = (data: any) => {
    dispatch({ type: 'SET_CRYPTOS', payload: data })
  }
  const handleGiftCode = (data: any) => {
    dispatch({ type: 'SET_GIFT_CODE', payload: data })
  }
  const setMintedDct = (data: any) => {
    dispatch({ type: 'SET_MINTED_DCT', payload: data })
  }
  const handlePurchaseId = (data: any) => {
    dispatch({ type: 'SET_PURCHASE_ID', payload: data })
  }
  const handleWalletPurchaseData = (data: any) => {
    dispatch({ type: 'SET_WALLET_PURCHASE_ID', payload: data })
  }
  const handleCurrentLoggedUserData = (data: any) => {
    dispatch({ type: 'SET_CURRENT_LOGGED_USER_DATA', payload: data })
  }
  const activationModalLocationHandler = (data: any) => {
    dispatch({ type: 'SET_ACTIVATEMODAL_LOCATION', payload: data })
  }
  const setRegions = (data: any) => {
    dispatch({ type: 'SET_REGIONS', payload: data })
  }

  state.setIsViewAllPlanModal = (data: any) => {
    dispatch({ type: 'SET_VIEWALLPLAN_MODAL', payload: data })
  }

  state.setViewAllPlanData = (data: any) => {
    dispatch({ type: 'SET_VIEWALLPLAN_MODAL_DATA', payload: data })
  }

  state.toggleTransferDct = (data: boolean) => {
    dispatch({ type: 'TOGGLE_TRANSFER_DCT', payload: data })
  }
  state.setCryptoData = (data: any) => {
    dispatch({ type: 'SET_CRYPTO_DATA', payload: data })
  }
  state.setPaymentSuccess = (data: boolean) => {
    dispatch({ type: 'SET_PAYMENT_SUCCESS', payload: data })
  }
  state.handleOfferData = (data: any) => {
    dispatch({ type: 'SET_OFFER_DATA', payload: data })
  }
  state.handleActivatedPurchaseData = (data: any) => {
    dispatch({ type: 'SET_ACTIVATED_DATA', payload: data })
  }
  state.setPurchaseModalLoading = (data: boolean) => {
    dispatch({ type: 'SET_PURCHASE_MODAL_LOADING', payload: data })
  }
  state.setMyPlanCardAction = (data: string) => {
    dispatch({ type: 'SET_MY_PLAN_CARD_ACTION', payload: data })
  }
  state.setPaymentId = (data: string) => {
    dispatch({ type: 'SET_PAYMENT_ID', payload: data })
  }
  state.setTransactionStatus = (data: any) => {
    dispatch({ type: 'SET_TRANSACTION_STATUS', payload: data })
  }
  state.setPurchases = (data: any) => {
    dispatch({ type: 'SET_PURCHASES', payload: data })
  }
  state.setFilteredOffer = (data: any) => {
    dispatch({ type: 'SET_FILTERED_OFFER', payload: data })
  }
  state.setResaleRegions = (data: any) => {
    dispatch({ type: 'SET_RESALE_REGIONS', payload: data })
  }
  state.setCredits = (data: any) => {
    dispatch({ type: 'SET_CREDITS', payload: data })
  }
  state.setUserEmail = (data: any) => {
    dispatch({ type: 'SET_USER_EMAIL', payload: data })
  }
  state.setIsPaymentLoading = (data: any) => {
    dispatch({ type: 'SET_IS_PAYMENT_LOADING', payload: data })
  }
  state.setCheckoutUrl = (data: any) => {
    dispatch({ type: 'SET_CHECKOUT_URL', payload: data })
  }
  state.setCheckoutSuccess = (data: any) => {
    dispatch({ type: 'SET_CHECKOUT_SUCCESS', payload: data })
  }
  state.setPurchaseOrderType = (data: any) => {
    dispatch({ type: 'SET_PURCHASEORDER_TYPE', payload: data })
  }
  state.setPurchaseQuantity = (data: any) => {
    dispatch({ type: 'SET_PURCHASEQUANTITY', payload: data })
  }
  state.setPurchaseAmount = (data: any) => {
    dispatch({ type: 'SET_PURCHASEAMOUNT', payload: data })
  }
  state.setTransactionHash = (data: any) => {
    dispatch({ type: 'SET_TRANSACTION_HASH', payload: data })
  }
  state.setPaymentOptionUsingWagmi = (data: any) => {
    dispatch({ type: 'PAYMENTUSING_WAGMI', payload: data })
  }
  let data = {
    ...state,
    setPaymentType: handlePaymentType,
    setDeviceType: handleDeviceType,
    setCryptos: handleCryptos,
    handleGiftCode,
    setMintedDct,
    handlePurchaseId,
    handleWalletPurchaseData,
    handleCurrentLoggedUserData,
    activationModalLocationHandler,
    setRegions,
  }
  return <PaymentContext.Provider value={data}>{props.children}</PaymentContext.Provider>
}

const usePaymentContext = () => useContext(PaymentContext)

export { PaymentProvider, usePaymentContext }
