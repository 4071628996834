import { FC, useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import searchIcon from 'assets/icons/SearchIcon.svg'
import { S } from './networkmodal.style'
import CustomDropDown from 'components/dropdown/CustomDropDown'
import { useModalContext } from 'contexts/modal.context'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'

const NetworkModal: FC = () => {
  const { showNetworkModal, setShowNetworkModal } = useModalContext()
  const { apiRegions } = usePaymentContext()
  const { allProviders } = useWalletContext()
  const [countryOptions, setCountryOptions] = useState<any>([])
  const [manipulatedData, setManipulatedData] = useState<any>([])
  const [searchedCountry, setSearchedCountry] = useState<any>('')
  const [selectedCountryNetwork, setSelectedCountryNetwork] = useState<any>({
    value: '',
    label: '',
    flagCountryCode: '',
    network: [],
  })
  const [selectedProvider, setSelectedProvider] = useState<any>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (showNetworkModal?.data[0]?.coverage?.length > 0) {
      handleCountryDropdownData()
    }
  }, [showNetworkModal.data])

  useEffect(() => {
    if (allProviders?.length > 0 && showNetworkModal?.isOpen === true && showNetworkModal?.data[0]?.poolId) {
      let temp = allProviders.find((data: any) => data.poolId === showNetworkModal?.data[0]?.poolId)
      setSelectedProvider(temp)
    }
  }, [allProviders, showNetworkModal?.data[0]])

  const handleCountryDropdownData = () => {
    let manipulatedData = showNetworkModal?.data[0]?.coverage.map((data: any) => {
      let temp = apiRegions.find((val: any) => val.flagCountryCode === data.name)

      return {
        ...data,
        country: temp?.name,
      }
    })

    setManipulatedData(manipulatedData)

    let regionList = manipulatedData?.map((data: any) => {
      return { value: data.country, label: data.country, flagCountryCode: data.name, network: data.networks }
    })
    const updatedRegionList = Array.from(new Set(regionList?.map((el: any) => JSON.stringify(el)))).map((el: any) =>
      JSON.parse(el),
    )
    setCountryOptions(updatedRegionList)
  }

  const handleClose = () => {
    setShowNetworkModal({
      data: [],
      type: 'network',
      isOpen: false,
      offer: {},
    })
    setSelectedCountryNetwork({
      value: '',
      label: '',
      flagCountryCode: '',
      network: [],
    })
  }

  return (
    <Modal open={showNetworkModal.isOpen}>
      <S.ModalMainContainer>
        <S.NetworkModalHeader>
          {showNetworkModal.type === 'network' ? (
            <S.HeaderSubContainer>
              <S.HeaderText>
                {' '}
                <img
                  src={`data:image/png;base64,${selectedProvider?.logo}`}
                  alt="logo"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                />
                &nbsp;
                {selectedProvider?.displayName} {showNetworkModal?.offer?.name} {t('network_modal.coverage')}
              </S.HeaderText>
              <CustomDropDown
                selectedValue={selectedCountryNetwork}
                setSelectedValue={setSelectedCountryNetwork}
                options={countryOptions}
                placeholder={t('network_modal.supports-count',{ count :showNetworkModal?.data[0]?.coverage?.length})}
                type="network"
                showIcon={false}
              />
              {(selectedCountryNetwork === null || selectedCountryNetwork?.network.length === 0) && (
                <S.HeaderSubText>{t('network_modal.speed-may-vary')}</S.HeaderSubText>
              )}
            </S.HeaderSubContainer>
          ) : (
            <S.HeaderSubContainer>
              <S.HeaderText>{`${showNetworkModal?.data[0]?.coverage?.length} Supported Countries`}</S.HeaderText>
              <S.InputContainer>
                <S.SearchIconImg src={searchIcon} />

                <S.CustomInput
                  placeholder="Search"
                  value={searchedCountry}
                  onChange={(e: any) => setSearchedCountry(e.target.value)}
                />
              </S.InputContainer>
            </S.HeaderSubContainer>
          )}

          <S.CloseIconContainer onClick={handleClose}>
            <CloseIcon style={{ color: '#ffffff', height: '30px', width: '30px' }} />
          </S.CloseIconContainer>
        </S.NetworkModalHeader>

        <S.NetworkModalBody>
          {showNetworkModal.type === 'network' && (
            <>
              {selectedCountryNetwork !== null && selectedCountryNetwork?.network.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <S.NetworksHeaderText>{t('network_modal.networks')}</S.NetworksHeaderText> &emsp;
                  <S.HeaderSubText>({t('network_modal.speed-may-vary')})</S.HeaderSubText>
                </Box>
              )}
              {selectedCountryNetwork?.network?.map((data: any) => {
                return (
                  <>
                    <S.SingleItem>
                      {data?.types.map((type: any) => {
                        return <S.LteBox>{type}</S.LteBox>
                      })}
                      {data?.name}
                    </S.SingleItem>
                  </>
                )
              })}
            </>
          )}

          {showNetworkModal?.type === 'country' && (
            <S.HeaderSubContainer>
              {manipulatedData
                ?.filter((val: any) => val?.country?.toLowerCase().includes(searchedCountry.toLowerCase()))
                .map((data: any) => {
                  return (
                    <S.SingleItem>
                      <img
                        alt="country flag"
                        style={{ height: '2rem', width: '2rem' }}
                        src={`https://giant-protocol.github.io/country-flag-icons/3x2/${data.name}.svg`}
                      />
                      <span>{data?.country}</span>
                    </S.SingleItem>
                  )
                })}
            </S.HeaderSubContainer>
          )}
        </S.NetworkModalBody>
      </S.ModalMainContainer>
    </Modal>
  )
}

export default NetworkModal
