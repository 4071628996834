import { Buffer } from 'buffer'
import { BigNumber } from 'bignumber.js'
import { PAYMENT_METHOD } from 'utils/constants'
var CryptoJS = require('crypto-js')

export const Utils = {
  paramConversion: {
    num: ['Compact<Balance>', 'BalanceOf', 'u8', 'u16', 'u32', 'u64', 'u128', 'i8', 'i16', 'i32', 'i64', 'i128'],
  },
}

export const isNumType = (type: string) => Utils.paramConversion.num.some((el) => type.indexOf(el) >= 0)

export function transformParams(paramFields: any[], inputParams: any[], opts = { emptyAsNull: true }) {
  const paramVal = inputParams.map((inputParam: { value: string } | null | string) => {
    if (typeof inputParam === 'object' && inputParam !== null && typeof inputParam.value === 'string') {
      return inputParam.value.trim()  
    } else if (typeof inputParam === 'string') {
      return inputParam.trim()
    }
    return inputParam
  })
  const params = paramFields.map((field: any, ind: number) => ({
    ...field,
    value: paramVal[ind] || null,
  }))
  return params.reduce((memo: any, { type = 'string', value }: any) => {
    if (value == null || value === '') return opts.emptyAsNull ? [...memo, null] : memo

    let converted = value

    if (type.indexOf('Vec<') >= 0) {
      converted = converted.split(',').map((e: string) => e.trim())
      converted = converted.map((single: string) =>
        isNumType(type) ? (single.indexOf('.') >= 0 ? Number.parseFloat(single) : Number.parseInt(single)) : single,
      )
      return [...memo, converted]
    }

    if (isNumType(type)) {
      converted = converted.indexOf('.') >= 0 ? Number.parseFloat(converted) : Number.parseInt(converted)
    }
    return [...memo, converted]
  }, [])
}

export function tohex(name: string): string {
  return Buffer.from(name, 'utf8').toString('hex')
}

export function uriToHex(didUri: string) {
  return didUri
    .split(':')
    .map((part, i) => {
      switch (i) {
        case 0:
          return part
        case 1:
          return '0x' + tohex(part)

        default:
          return tohex(part)
      }
    })
    .reduce((a, b) => `${a === '' ? '' : a + ':'}${b}`, '')
}

export const convert = (from: any, to: any) => (str: any) => Buffer.from(str, from).toString(to)

export const hexToUtf8Convert = convert('hex', 'utf8')

export function hexToUtf8(hex: any) {
  if (hex.length) {
    let newHex = hex.charAt(0) === '0' ? hex.slice(2) : hex
    return decodeURIComponent('%' + newHex.match(/.{1,2}/g).join('%'))
  }
}

export const currencyToNumber = (currency: string) => currency.replace(/[^0-9.-]+/g, '')

export const toDecimal = (amount: string, decimal = 12, fixed = 2, string?: any) => {
  let fullAmount = new BigNumber(amount).dividedBy(Math.pow(10, decimal)).toString()
  return {
    full: fullAmount !== 'NaN' ? fullAmount : '0',
    deci: fullAmount !== 'NaN' ? new BigNumber(fullAmount)?.toFixed(fixed) : '0.00',
  }
}

export const toWei = (amount: string, decimal = 12) => {
  return new BigNumber(amount).multipliedBy(Math.pow(10, decimal)).toString()
}

export const getDifference = (array1: any, array2: any) => {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return object1.metadata.id === object2.metadata.id
    })
  })
}

export const decryptCrypto = (data: any) => {
  let salt = process.env.REACT_APP_SERCET_KEY ?? ''
  var bytes = CryptoJS.AES.decrypt(data, salt)
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export const encryptCrypto = (data: any) => {
  let salt = process.env.REACT_APP_SERCET_KEY ?? ''
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString()
  return ciphertext
}

export const formatBytes = (bytes: any, decimals = 2) => {
  // if (bytes === 0 || bytes === "0") return { value: 0, size: 'Bytes' }
  if (parseFloat(bytes) <= 1048576) return { value: 0, size: 'MB' }

  let k = 1024
  let decimalValue = decimals < 0 ? 0 : decimals
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))

  return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(decimalValue)), size: sizes[i] }
}

export const DateDifference = (date: any) => {
  let value = date - new Date().getTime()

  return value <= 0 ? 0 : Math.ceil(value / (1000 * 3600 * 24))
}

export const handleCryptoData = (data: any) => {
  let newData: any = {}
  let result: any = PAYMENT_METHOD.filter((item) => {
    return item.value === data.chain && item
  })
  if (result[0] !== undefined) {
    newData.logo = result[0].logo
    newData.value = data.chainName
    newData.label = data.chainName
  }
  newData = { ...newData, ...data }
  return newData
}

export function shortenedAddress(address: string, chars = 4): any {
  if (address) {
    return `${address.slice(0, chars + 2)}...${address.substring(address.length - 1 - 6, address.length)}`
  }
}

export function shortenedLink(address: string, chars = 4): any {
  if (address) {
    return `${address.slice(0, chars + 2)}.....${address.substring(address.length - 1 - 4)}`
  }
}

export const sliceIntoChunks = (arr: any, chunkSize: any) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const getInternationalCurrency = (data: any) => {
  if (typeof data === 'number') {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else return data.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertToPascalCase = (data: string) => {
  return data.toLowerCase().replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase()
  })
}

export const handleSegment = (eventname: string, props: any) => {
  ;(window as any).analytics.track(eventname, {
    ...props,
  })
}

export const getPercentage = (percent: any, total: any) => {
  return ((Number(percent) / 100) * Number(total)).toFixed(2)
}
