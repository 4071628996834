import { createContext, useContext, useEffect, useReducer } from 'react'
import { getItem, setItem } from '../services/localStorage/localStorage.service'
import { getPolkadotAccounts, handleEvents, handleWalletConnect } from '../polkadot'
import { toast } from 'react-toastify'
import { getBalance } from 'polkadot/callmethods/system'
import { getCurrentRewardDemand } from 'polkadot/callmethods/provider'
import { getSGiantBalance } from 'polkadot/callmethods/staking'
import { PURCHASE_TYPE_BUY } from 'utils/constants'
import { convertToPascalCase, decryptCrypto, encryptCrypto } from 'utils'
import { dctTransfer } from 'utils/constants/config'
import { usePaymentContext } from './payment.context'
import { userClient } from 'services/http/user.service'
import { appClient } from 'services/http/app.service'

export enum API_STATE {
  CONNECT_INIT,
  CONNECTING,
  READY,
  ERROR,
  NULL,
}

interface WalletContextType {
  isConnected: boolean
  openConnectModal: boolean
  isConnecting: boolean
  connectedAccount: any
  isWagmiConnected: boolean
  isWagmiConnecting: boolean
  polkadotAccountAddress: any
  demandReward: any
  accounts: any
  userDetails: any
  balance: {
    full: string
    deci: string
  }
  sGiantBalance: {
    full: string
    deci: string
  }
  assetBalance: any
  stakeBalance: {
    total: string
    earnedBalance: string
  }
  assetMetadata: any
  api: any
  apiState: API_STATE
  modalCountryData: any
  modalData: any
  userLogin: any
  isLogged: boolean
  providerPoolsData: any
  selectPoolData: any
  tokenDatas: {
    appId: any
    address: any
  }
  isRefresh: boolean
  showPurchaseModal: boolean
  showActivateModal: boolean
  purchaseType: string
  showViewPlansModal: boolean
  showReportIssueModal: boolean
  selectViewPlansType: string
  openConfirmModal: any
  selectViewPlanDetails: any
  selectViewPlanStatus: any
  isAppLoading: boolean
  allProviders: any
  popularRegions: any
  activePoolId: number[]
  connectWalletLoader: boolean
  walletConnectHandler: (account: any) => void
  accountHandler: (provider: string) => void
  disconnectHandler: () => void
  userLoginHandler: (data: any) => void
  modalDataHandler: (data: any) => void
  getInstanceHandler: (account: any) => void
  providerPoolsDataHandler: (data: any) => void
  selectPoolsDataHandler: (data: any) => void
  setConnectedModalOpen: (data: boolean) => void
  setHandleTokenData: (data: any) => void
  updateBalance: (data: any) => void
  updateSBalance: (data: any) => void
  toggleRefreshBtn: (data: boolean) => void
  setShowPurchaseModal: (data: boolean) => void
  setShowActivateModal: (data: boolean) => void
  setViewPlansModal: (data: boolean) => void
  setPurchaseModalType: (data: any) => void
  setReportIssueModal: (data: boolean) => void
  setSelectViewPlansType: (data: any) => void
  setSelectViewPlanDetails: (data: any) => void
  setSelectViewPlanStatus: (data: any) => void
  setOpenConfirmModal: (data: any) => void
  loginHandler: (account: any, email: string | null, uid: any) => void
  setIsAppLoading: (data: boolean) => void
  setAllProviders: (data: any) => void
  setPopularRegions: (data: any) => void
  setActivePoolId: (data: number | string) => void
  clearAccounts: () => void
  setConnectWalletLoader: (data: any) => void
  setWagmiConnecting: (data: any) => void
  setWagmiConnected: (data: any) => void
}

const initialState: WalletContextType = {
  isConnected: false,
  openConnectModal: false,
  isConnecting: false,
  connectedAccount: {},
  isWagmiConnected: false,
  isWagmiConnecting: false,
  polkadotAccountAddress: null,
  userDetails: {},
  accounts: [],
  balance: {
    full: '0',
    deci: '0',
  },
  sGiantBalance: {
    full: '0',
    deci: '0',
  },
  demandReward: 0,
  assetBalance: '0',
  stakeBalance: {
    total: '0',
    earnedBalance: '0',
  },
  assetMetadata: {
    name: 'Giant',
    symbol: 'GIANT',
    decimals: 6,
    isFrozen: false,
    deposit: 0,
  },
  api: null,
  apiState: API_STATE.NULL,
  modalData: [],
  modalCountryData: [],
  userLogin: null,
  isLogged: false,
  providerPoolsData: [],
  selectPoolData: {},
  tokenDatas: {
    address: null,
    appId: null,
  },
  isRefresh: false,
  showPurchaseModal: false,
  showActivateModal: false,
  purchaseType: PURCHASE_TYPE_BUY,
  showViewPlansModal: false,
  showReportIssueModal: false,
  selectViewPlansType: '',
  selectViewPlanStatus: false,
  openConfirmModal: false,
  selectViewPlanDetails: {},
  isAppLoading: false,
  allProviders: [],
  popularRegions: [],
  activePoolId: [],
  connectWalletLoader: true,
  walletConnectHandler: () => {},
  accountHandler: (provider: any) => {},
  disconnectHandler: () => {},
  modalDataHandler: () => {},
  userLoginHandler: () => {},
  getInstanceHandler: () => {},
  providerPoolsDataHandler: () => {},
  selectPoolsDataHandler: () => {},
  setConnectedModalOpen: () => {},
  setHandleTokenData: () => {},
  updateBalance: () => {},
  updateSBalance: () => {},
  toggleRefreshBtn: () => {},
  setShowPurchaseModal: () => {},
  setViewPlansModal: () => {},
  setPurchaseModalType: () => {},
  setReportIssueModal: () => {},
  setShowActivateModal: () => {},
  setSelectViewPlansType: () => {},
  setSelectViewPlanDetails: () => {},
  setSelectViewPlanStatus: () => {},
  setOpenConfirmModal: () => {},
  loginHandler: () => {},
  setIsAppLoading: () => {},
  setAllProviders: () => {},
  setPopularRegions: () => {},
  setActivePoolId: () => {},
  clearAccounts: () => {},
  setConnectWalletLoader: () => {},
  setWagmiConnecting: () => {},
  setWagmiConnected: () => {},
}

export const ASSETID = 1

const reducer = (state: WalletContextType, action: any) => {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return { ...state, accounts: action.payload }
    case 'CONNECT_INIT':
      return { ...state, isConnecting: action.payload }
    case 'CONNECTED':
      return { ...state, isConnected: action.payload._isConnected }
    case 'CONNECT_SUCCESS':
      return {
        ...state,
        isConnected: true,
        connectedAccount: action.payload.account,
        balance: action.payload.bal,
        sGiantBalance: action.payload.sGiantBalance,
        assetBalance: action.payload.assetBal,
      }
    case 'SET_POLKADOT_ADDRESS':
      return { ...state, polkadotAccountAddress: action.payload }  
    case 'SET_WAGMI_CONNECT_INIT':
      return { ...state, isWagmiConnecting: action.payload }
    case 'SET_WAGMI_CONNECTED':
      return { ...state, isWagmiConnected: action.payload }  
    case 'UPDATE_BALANCE':
      return { ...state, balance: action.payload }
    case 'UPDATE_S_BALANCE':
      return { ...state, sGiantBalance: action.payload }
    case 'DISCONNECT':
      return {
        ...state,
        isConnected: false,
        connectedAccount: initialState.connectedAccount,
        isConnecting: false,
        userLogin: initialState.userLogin,
        polkadotAccountAddress: initialState.polkadotAccountAddress,
      }
    case 'SET_METADATA':
      return { ...state, assetMetadata: action.payload }
    case 'SET_API':
      return { ...state, api: action.payload }
    case 'SET_API_STATE':
      if (action.payload === API_STATE.ERROR) {
        toast.error('Error connecting to RPC, trying to reconnect', {})
      }
      return { ...state, apiState: action.payload }
    case 'SET_STAKE_BALANCE':
      return { ...state, stakeBalance: action.payload }
    case 'SET_ASSET_BALANCE':
      return { ...state, assetBalance: action.payload }
    case 'SET_MODAL_DATA':
      return { ...state, modalData: action.payload.data, modalCountryData: action.payload.modalCountryData }
    case 'SET_LOGGED':
      return { ...state, isLogged: action.payload }
    case 'SET_REWARD_DEMAND':
      return { ...state, demandReward: action.payload }
    case 'SET_USER_LOGIN':
      return { ...state, userLogin: action.payload.jwt, userDetails: action.payload, isConnecting: false }
    case 'SET_PROVIDER_POOLS_DATA':
      return { ...state, providerPoolsData: action.payload }
    case 'SET_SELECT_POOLS_DATA':
      return { ...state, selectPoolData: action.payload }
    case 'TOGGLE_CONNECTED_MODAL': {
      return { ...state, openConnectModal: action.payload }
    }
    case 'SET_TOKEN_DATA': {
      return { ...state, tokenDatas: action.payload }
    }
    case 'TOGGLE_REFRESH': {
      return { ...state, isRefresh: action.payload }
    }
    case 'TOGGLE_PURCHASE_MODAL': {
      return { ...state, showPurchaseModal: action.payload }
    }
    case 'TOGGLE_ACTIVATE_MODAL': {
      return { ...state, showActivateModal: action.payload }
    }
    case 'TOGGLE_PURCHASE_MODAL_TYPE': {
      return { ...state, purchaseType: action.payload }
    }
    case 'TOGGLE_VIEW_PLANS_MODAL': {
      return { ...state, showViewPlansModal: action.payload }
    }
    case 'TOGGLE_REPORT_ISSUE_MODAL': {
      return { ...state, showReportIssueModal: action.payload }
    }
    case 'SET_VIEW_PLANS_TYPE': {
      return { ...state, selectViewPlansType: action.payload }
    }
    case 'SET_VIEW_PLANS_DETAILS': {
      return { ...state, selectViewPlanDetails: action.payload }
    }
    case 'SET_VIEW_PLAN_STATUS': {
      return { ...state, selectViewPlanStatus: action.payload }
    }
    case 'TOGGLE_CONFIRM_MODAL': {
      return { ...state, openConfirmModal: action.payload }
    }
    case 'SET_APP_LOADING': {
      return { ...state, isAppLoading: action.payload }
    }
    case 'SET_ALL_PROVIDERS': {
      return { ...state, allProviders: action.payload }
    }
    case 'SET_POPULAR_REGIONS': {
      return { ...state, popularRegions: action.payload }
    }
    case 'SET_ACTIVE_POOL_ID': {
      return { ...state, activePoolId: action.payload }
    }
    case 'SET_CONNECT_WALLET_LOADER':
      return { ...state, connectWalletLoader: action.payload }
    default:
      throw new Error(`Unknown type: ${action.type}`)
  }
}

export const WalletContext = createContext<WalletContextType>(initialState)

function WalletProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { transferDct, toggleTransferDct, walletPurchaseData } = usePaymentContext()
  const { userLogin } = useWalletContext()

  useEffect(() => {
    if (state.apiState === API_STATE.READY) {
      getCurrentRewardDemand().then((res) => {
        dispatch({ type: 'SET_REWARD_DEMAND', payload: res })
      })
    }
  }, [state.apiState, state.assetMetadata])

  useEffect(() => {
    handleEvents(dispatch)
  }, [dispatch])

  useEffect(() => {
    const _isConnected = getItem<boolean>('isConnected')
    dispatch({ type: 'CONNECTED', payload: { _isConnected } })
  }, [])

  useEffect(() => {
    const fetchBalance = async () => {
      if (state.connectedAccount?.address && state.connectedAccount.address !== '') {
        let balance = await getBalance(state.connectedAccount)
        let sGiantBalance = await getSGiantBalance(state.connectedAccount)
        dispatch({ type: 'UPDATE_BALANCE', payload: balance })
        dispatch({ type: 'UPDATE_S_BALANCE', payload: sGiantBalance })
      }
    }

    let interval = setInterval(() => {
      fetchBalance()
    }, 5000)
    return () => clearInterval(interval)
  }, [state.connectedAccount.address, state.assetMetadata?.decimals])
  useEffect(() => {
    if(state.userDetails?.status === 'success'&&state.userDetails?.user?.polkadotAccountAddress&&state.isWagmiConnected){
      dispatch({ type: 'SET_POLKADOT_ADDRESS', payload: state.userDetails?.user?.polkadotAccountAddress })
    }    
  }, [state.userDetails])
  
  const handleWallet = async (account: any) => {
    if (state.apiState === API_STATE.READY && !!account) {
      try {
        if (userLogin) {
          userClient.postUserEventsWithAuth(
            {
              eventName: 'Wallet connected',
              properties: {
                build: process.env.REACT_APP_CURRENT_CHAIN,
                'wallet address': account?.address,
                'screen name': `${
                  window.location.pathname === '/'
                    ? 'Home'
                    : convertToPascalCase(window.location.pathname.split('/')[1])
                }`,
              },
            },
            userLogin,
          )
        }

        dispatch({ type: 'CONNECT_INIT', payload: true })
        let walletDetails = await handleWalletConnect(account, state?.assetMetadata?.decimals)
        if (walletDetails.isConnected) {
          dispatch({ type: 'CONNECT_SUCCESS', payload: walletDetails })
        } else {
          dispatch({ type: 'CONNECT_INIT', payload: false })
        }
      } catch (e) {
        dispatch({ type: 'CONNECT_INIT', payload: false })
      }
    }
  }

  const handlePolkadotAccounts = async (provider: string) => {
    try {
      if (state.apiState === API_STATE.READY) {
        getPolkadotAccounts(provider).then((accounts: any) => {
          if (accounts.account.length) {
            dispatch({ type: 'SET_ACCOUNTS', payload: accounts.account })
            setItem('currentProvider', provider)
          } else {
            toast.warn('No accounts found, Please create an account')
          }
        })
      } else {
        toast.error(
          <div
            onClick={() => handlePolkadotAccounts(provider)}
            style={{ border: 'none', background: 'transparent', width: '100%', textAlign: 'left' }}
          >
            Please wait for the RPC to be ready,Click to Try again
          </div>,
          {},
        )
      }
    } catch (err) {}
  }
  const handleUserLogin = (data: any) => {
    dispatch({ type: 'SET_USER_LOGIN', payload: data })
    dispatch({ type: 'SET_LOGGED', payload: true })
  }
  const handleModalData = (data: any) => {
    dispatch({ type: 'SET_MODAL_DATA', payload: data })
  }

  const handleDisconnect = () => {
    try {
      localStorage.removeItem('connectedAccount')
      dispatch({ type: 'DISCONNECT' })
    } catch (err) {}
  }
  const handleProviderPoolsData = (data: any) => {
    dispatch({ type: 'SET_PROVIDER_POOLS_DATA', payload: data })
  }
  const handleSelectPoolsData = (data: any) => {
    dispatch({ type: 'SET_SELECT_POOLS_DATA', payload: data })
  }
  const handleConnectedModal = (data: boolean) => {
    dispatch({ type: 'TOGGLE_CONNECTED_MODAL', payload: data })
  }
  const handleTokenData = (data: any) => {
    dispatch({ type: 'SET_TOKEN_DATA', payload: data })
  }
  const handleUpdateBalance = (data: any) => {
    dispatch({ type: 'UPDATE_BALANCE', payload: data })
  }
  const handleUpdateSGiantBalance = (data: any) => {
    dispatch({ type: 'UPDATE_S_BALANCE', payload: data })
  }
  const handleRefreshToggle = (data: boolean) => {
    dispatch({ type: 'TOGGLE_REFRESH', payload: data })
  }
  const handlePurchaseModalToggle = (data: boolean) => {
    dispatch({ type: 'TOGGLE_PURCHASE_MODAL', payload: data })
  }
  const handleActivateModalToggle = (data: boolean) => {
    dispatch({ type: 'TOGGLE_ACTIVATE_MODAL', payload: data })
  }
  const handleViewPlansModal = (data: boolean) => {
    dispatch({ type: 'TOGGLE_VIEW_PLANS_MODAL', payload: data })
  }
  const handleReportIssueModal = (data: boolean) => {
    dispatch({ type: 'TOGGLE_REPORT_ISSUE_MODAL', payload: data })
  }
  const handlePurchaseModalType = (data: any) => {
    dispatch({ type: 'TOGGLE_PURCHASE_MODAL_TYPE', payload: data })
  }
  const handleSelectViewPlansType = (data: any) => {
    dispatch({ type: 'SET_VIEW_PLANS_TYPE', payload: data })
  }
  const handleOpenConfirmModal = (data: boolean) => {
    dispatch({ type: 'TOGGLE_CONFIRM_MODAL', payload: data })
  }
  const handleLogin = (account: any, email: any, uid: string | null) => {
    handleDoLogin(account, 0, email, uid)
  }
  state.clearAccounts = () => {
    dispatch({ type: 'SET_ACCOUNTS', payload: [] })
  }
  state.setSelectViewPlanDetails = (data: any) => {
    dispatch({ type: 'SET_VIEW_PLANS_DETAILS', payload: data })
  }
  state.setSelectViewPlanStatus = (data: any) => {
    dispatch({ type: 'SET_VIEW_PLAN_STATUS', payload: data })
  }
  state.setIsAppLoading = (data: boolean) => {
    dispatch({ type: 'SET_APP_LOADING', payload: data })
  }
  state.setAllProviders = (data: any) => {
    dispatch({ type: 'SET_ALL_PROVIDERS', payload: data })
  }
  state.setPopularRegions = (data: any) => {
    dispatch({ type: 'SET_POPULAR_REGIONS', payload: data })
  }
  state.setActivePoolId = (data: number | string) => {
    dispatch({ type: 'SET_ACTIVE_POOL_ID', payload: data })
  }
  state.setConnectWalletLoader = (data: any) => {
    dispatch({ type: 'SET_CONNECT_WALLET_LOADER', payload: data })
  }
  state.setWagmiConnecting = (data: any) => {
    dispatch({ type: 'SET_WAGMI_CONNECT_INIT', payload: data })
  }
  state.setWagmiConnected = (data: any) => {
    dispatch({ type: 'SET_WAGMI_CONNECTED', payload: data })
  }
  const handleDoLogin = (account: any, counter: any, email: string | null, uid: string | null) => {
    appClient.getAppId().then((res) => {
      let result = decryptCrypto(res.appId)
      let data = encryptCrypto({
        email: email,
        address: account?.address ? account.address : null,
        appId: result.appId,
        browserId: uid,
      })
      doLogin(data, account, counter, result.appId)
    })
  }
  const doLogin = (data: any, account: any, counter: any, appId: any) => {
    const payload = encryptCrypto({
      dctId: walletPurchaseData.dctId,
      address: account?.address ?? null,
      appId: appId,
    })
    appClient
      .signIn(data)
      .then((res) => {
        handleUserLogin(res)
        transferDct &&
          dctTransfer(payload, res.jwt).then((res) => {
            toggleTransferDct(false)
          })
        state.setIsAppLoading(false)
        ;(window as any).analytics.identify(res?.user?.username)
      })
      .catch((err) => {
        console.log('Error : ', err)
        if (err.response.data.error === 'Unauthorized' && counter < 2) {
          handleDoLogin(account, counter + 1, '', '')
        } else {
          state.setIsAppLoading(false)
          handleDisconnect()
        }
      })
  }
  let data = {
    ...state,
    modalDataHandler: handleModalData,
    walletConnectHandler: handleWallet,
    accountHandler: handlePolkadotAccounts,
    disconnectHandler: handleDisconnect,
    userLoginHandler: handleUserLogin,
    providerPoolsDataHandler: handleProviderPoolsData,
    selectPoolsDataHandler: handleSelectPoolsData,
    setConnectedModalOpen: handleConnectedModal,
    setHandleTokenData: handleTokenData,
    updateBalance: handleUpdateBalance,
    updateSBalance: handleUpdateSGiantBalance,
    toggleRefreshBtn: handleRefreshToggle,
    setShowPurchaseModal: handlePurchaseModalToggle,
    setShowActivateModal: handleActivateModalToggle,
    setViewPlansModal: handleViewPlansModal,
    setPurchaseModalType: handlePurchaseModalType,
    setSelectViewPlansType: handleSelectViewPlansType,
    setReportIssueModal: handleReportIssueModal,
    setOpenConfirmModal: handleOpenConfirmModal,
    loginHandler: handleLogin,
  }

  return <WalletContext.Provider value={data}>{props.children}</WalletContext.Provider>
}

const useWalletContext = () => useContext(WalletContext)

export { WalletProvider, useWalletContext }
