import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { v4 as uuidV4 } from 'uuid'
import BigNumber from 'bignumber.js'
import 'react-toastify/dist/ReactToastify.css'

import { API_STATE, useWalletContext } from 'contexts/wallet.context'
import { usePaymentContext } from 'contexts/payment.context'
import { getDefaultDctSales } from 'utils/constants/config'
import { handleCryptoData, toDecimal } from 'utils'
import { PAYMENT_TYPE } from 'utils/constants'
import { setItem, getItem } from 'services/localStorage/localStorage.service'
import { providerClient } from 'services/http/providers.service'
import { regionClient } from 'services/http/regions.service'
import { useAppContext } from 'contexts/app.context'
import Layout from 'components/layout/Layout'
import { resaleClient } from 'services/http/resale.service'
import { getCreditsFromChain } from 'polkadot/callmethods/credits'
import { paymentClient } from 'services/http/payment.service'
import { useAccount, useEnsAvatar, useEnsName } from 'wagmi'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: FC = () => {
  const [userToken, setUserToken] = useState<any>(false)
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const {
    connectedAccount,
    walletConnectHandler,
    tokenDatas,
    allProviders,
    accountHandler,
    apiState,
    isConnected,
    isWagmiConnected,
    userLogin,
    isAppLoading,
    setHandleTokenData,
    loginHandler,
    setIsAppLoading,
    setAllProviders,
    setPopularRegions,
    setActivePoolId,
    polkadotAccountAddress
  } = useWalletContext()
  const { setCryptos, setPaymentType, paymentType, cryptos, setRegions, setResaleRegions, setCredits } =
    usePaymentContext()
  const { setDctSalesValue } = useAppContext()
  const { address, connector } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { data: ensAvatar } = useEnsAvatar({ name: ensName })
  //FETCH_ALL_PROVIDERS
  useQuery('fetchAllProviders', () => providerClient.getProviders(userLogin), {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
    onSuccess: (data) => {
      setAllProviders(data)
      setActivePoolId(
        data
          .map((provider: any) => {
            if (provider.status === 'active') return provider.poolId
          })
          .filter((data: number | string) => data !== undefined),
      )
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: userToken,
  })

  const { refetch: refetchPopularRegions } = useQuery(
    'getPopularRegions',
    async () => await regionClient.getPopularRegions(userLogin),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      onSuccess: (data) => {
        setPopularRegions(data)
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(allProviders.length !== 0) && userToken,
    },
  )

  const { refetch: refetchActiveRegions } = useQuery(
    'getActiveRegions',
    async () => await regionClient.getRegions(userLogin),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      onSuccess: (data) => {
        setRegions(data)
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(allProviders.length !== 0) && userToken,
    },
  )

  const { refetch: refetchResalePacks } = useQuery(
    'getResalePacks',
    async () => await resaleClient.getResalePacks(userLogin),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      onSuccess: (data) => {
        setResaleRegions(data)
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(allProviders.length !== 0) && userToken,
    },
  )

  useEffect(() => {
    userLogin && setUserToken(true)
  }, [userLogin])

  useEffect(() => {
    if (userLogin !== null) {
      getDefaultDctSales(userLogin).then((dctValue) => {
        setDctSalesValue(dctValue.DCTsales)
      })
    }
  }, [userLogin])

  useEffect(() => {
    let UID: string | null = localStorage.getItem('UID') ?? null
    const accountAddress = localStorage.getItem('connectedAccount')
    if ((accountAddress === null|| accountAddress === 'false')&& (!isConnected && !isWagmiConnected)) {
      setIsAppLoading(true)
      if (UID === null) {
        UID = uuidV4()
        localStorage.setItem('UID', UID)
      }
      !isAppLoading&&loginHandler(null, null, UID)
    }
  }, [isConnected,isWagmiConnected])

  useEffect(() => {
    connectedAccount.address && setHandleTokenData({ ...tokenDatas, address: connectedAccount.address })
    if (userLogin === null && connectedAccount.address === undefined) {
      setPaymentType(PAYMENT_TYPE[1].value)
    }
  }, [connectedAccount, userLogin])

  useEffect(() => {
    if (userLogin !== null && paymentType === PAYMENT_TYPE[0].value && cryptos.length === 0) {
      paymentClient.getCryptos(userLogin).then((res) => {
        let result: any = []
        let data: any = res[0].circlePayment
        data.map((item: any) => {
          result.push(handleCryptoData(item))
        })
        setCryptos(result)
      })
    }
  }, [userLogin, paymentType])

  useEffect(() => {
    let storeAccountAddress: any = getItem('connectedAccount')
    let currentProvider: any = getItem('currentProvider')
    if (
      apiState === API_STATE.READY &&
      storeAccountAddress &&
      storeAccountAddress?.address !== '' &&
      isConnected === false
    ) {
      if(currentProvider !== 'wagmi'){
        accountHandler(currentProvider)
        handleWallectHandler(storeAccountAddress)
      }
    }
  }, [apiState])

  useEffect(() => {
    let currentProvider: any = getItem('currentProvider')
    if (userLogin !== null) {
      refetchPopularRegions()
      refetchActiveRegions()
      refetchResalePacks()
    }
    if ((connectedAccount?.address !== 'undefined' || isWagmiConnected ) && userLogin !== null) {
      getCreditsValue()
    }
  }, [connectedAccount, userLogin, isWagmiConnected])

  useEffect(() => {
    let cv = {
      address: address,
      meta: {
        name: ensName ? ensName :'Wagmi',
        source: connector?.name,
      },
    }
    if(isWagmiConnected){
      setItem('connectedAccount', cv )
      setItem('currentProvider', 'wagmi')
      loginHandler(cv, '', '')
    }    
  }, [isWagmiConnected])
  
  const handleWallectHandler = async (account: any) => {
    setItem('connectedAccount', account)
    await walletConnectHandler(account)
    loginHandler(account, '', '')
  }  
  const getCreditsValue = async () => {
    let finalCredits = await getCreditsFromChain(polkadotAccountAddress?polkadotAccountAddress:connectedAccount?.address)
    let temp = toDecimal(finalCredits).deci
    setCredits(temp)
  }

  return <Layout />
}

export default App
