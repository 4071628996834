import React, { useState, FC, useEffect } from 'react'
import { Grid, Typography, Box, Modal, Select, Button, useMediaQuery, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'

import GiantDollor from 'assets/icons/GiantDollor.svg'
import CloseIcon from '@mui/icons-material/Close'

import { stakingClient } from 'services/http/staking.service'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'
import { toDecimal } from 'utils'
import moment from 'moment'

const EarningDetailsModal: FC = () => {
  const match = useMediaQuery('(max-width:1024px)')
  const { userLogin } = useWalletContext()
  const { showEarningDetailsModal, setShowEarningDetailsModal, earnMetaData } = useModalContext()
  const [earningHistory, setEarningHistory] = useState<any>([])
  const [earningHistoryLoading, setEarningHistoryLoading] = useState<any>(false)
  const [dataLoading, setDataLoading] = useState<any>(true)
  const [earningPage, setEearningPage] = useState<number>(1)

  useEffect(() => {
    if (showEarningDetailsModal && userLogin !== null) {
      getEarningHistoryData(1)
    }
  }, [showEarningDetailsModal, userLogin])

  const getEarningHistoryData = (page: number) => {
    setEarningHistoryLoading(true)
    try {
      stakingClient.getEarningRewardsHistory(userLogin, page).then(async (res: any) => {
        if (res?.data?.earnHistory?.length) {
          if (res?.data?.earnHistory?.length <= 0) {
            // if (type === 'stake') {
            //   setShowLoadmoreBtn(false)
            // }
            setEarningHistoryLoading(false)
          } else {
            setEarningHistoryLoading(false)
            earningPage > 1
              ? setEarningHistory((prev: any) => {
                  return [...prev, ...res?.data?.earnHistory]
                })
              : setEarningHistory([...res?.data?.earnHistory])
          }
          setDataLoading(false)
        } else {
          setEarningHistory([])
          setEarningHistoryLoading(false)
        }
      })
    } catch (err) {
      setEarningHistoryLoading(false)
      console.log(err, 'error')
    }
  }

  const convertToInternationalCurrencySystem = (labelValue: any) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
      : Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
      : Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
      : Math.abs(Number(labelValue))
  }

  const fetchNextPage = async (type: string) => {
    setEearningPage((prev: number) => prev + 1)
    getEarningHistoryData(earningPage + 1)
  }

  return (
    <Modal
      open={showEarningDetailsModal}
      onClose={() => setShowEarningDetailsModal(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <EarningDetailsContainer>
        <EarningHeader>
          <EarningHeading>Earning Details</EarningHeading>
          <CloseIcon
            sx={{
              borderRadius: '50%',
              border: '1px solid #DEE7FD',
              background: '#FFFFFF',
              color: 'black',
              padding: '5px !important',
            }}
            onClick={() => setShowEarningDetailsModal(false)}
          />
        </EarningHeader>
        <EarningContainer>
          <EarnedContent container rowSpacing={1}>
            <Earn sx={{ gap: `${match ? '0' : '2rem'}` }}>
              <Grid>
                <Typography> Total Earned</Typography>
                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                  <img src={GiantDollor} alt="" style={{ paddingRight: '5px', width: '30px', height: '30px' }} />
                  <EarningHeading>{Number(earnMetaData?.totalReward)?.toFixed(2)}</EarningHeading>
                </Grid>
              </Grid>

              <PurchaseEarned item>
                <Typography> Purchase Rewards Earned </Typography>
                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                  <img src={GiantDollor} alt="" style={{ paddingRight: '5px', width: '30px', height: '30px' }} />
                  <EarningHeading>{Number(earnMetaData?.totalPurchasedRewards)?.toFixed(2)} </EarningHeading>
                </Grid>
              </PurchaseEarned>

              <Grid>
                <Typography> Staking Rewards Earned </Typography>
                <Grid style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                  <img src={GiantDollor} alt="" style={{ paddingRight: '5px', width: '30px', height: '30px' }} />
                  <EarningHeading>{Number(earnMetaData?.totalStakedRewards)?.toFixed(2)}</EarningHeading>
                </Grid>
              </Grid>
            </Earn>
          </EarnedContent>

          <RewardsSchedule>
            <RewardsBody>
              <EarnedHeading>Current Rewards Schedule</EarnedHeading>
              <Grid container rowSpacing={1} style={{ padding: '1rem 0' }}>
                <Grid item xs={12} sm={6} lg={3} md={3}>
                  <Typography>
                    Purchase <br /> Rewards{' '}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3} md={3}>
                  <Typography>Current</Typography>
                  <EarningHeading>{earnMetaData?.currentRate}% </EarningHeading>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} md={4}>
                  <Typography>Next</Typography>
                  <EarningHeading>{earnMetaData?.next}% </EarningHeading>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} md={2}>
                  <Typography>Sales Until Next</Typography>
                  <EarningHeading>
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ paddingRight: '5px', width: '30px', height: '30px' }} src={GiantDollor} alt="" />{' '}
                      {convertToInternationalCurrencySystem(earnMetaData?.sales)}
                    </Grid>
                  </EarningHeading>
                </Grid>
              </Grid>
              <Grid item container rowSpacing={1} style={{ padding: '1rem 0' }}>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Typography>
                    Staking <br /> Rewards
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Typography>Share of Sales</Typography>
                  <EarningHeading>{earnMetaData?.shareOfSales}% </EarningHeading>
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={4}>
                  <Typography>Validator Rewards (APY)</Typography>
                  <EarningHeading>{earnMetaData?.validatorRewards}% </EarningHeading>
                </Grid>
                <Grid></Grid>
              </Grid>
            </RewardsBody>
          </RewardsSchedule>
          <EarningHistory sx={{ maxHeight: '40vh', overflow: 'scroll' }}>
            <EarnedHeading>Your Earning History</EarnedHeading>
            {dataLoading ? (
              <Box sx={{ width: '100%', height: '40vh', display: 'grid', placeContent: 'center' }}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <>
                {earningHistory.length > 0 &&
                  earningHistory.map((history: any, index: number) => (
                    <PurchaseRewards key={index}>
                      <PurchaseCard>
                        <Grid item sm={12} xs={12} lg={6} md={6}>
                          <EarnedHeading>
                            {' '}
                            {history?.rewardType === 'DemandRewardPaid'
                              ? 'Purchase Reward'
                              : history?.rewardType === 'YieldRewardPaid'
                              ? 'Staking reward (sales)'
                              : history?.rewardType === 'SupplyRewardPaid'
                              ? 'Supply reward'
                              : 'Staking Reward (validator)'}{' '}
                          </EarnedHeading>
                          <Typography style={{ color: '#909193' }}>
                            {moment(history?.blockDatetime).format('MMM DD, YYYY hh:mm')}
                          </Typography>
                        </Grid>
                        <AlignContainer item sm={12} xs={12} lg={6} md={6}>
                          <img src={GiantDollor} alt="" style={{ paddingRight: '5px' }} />
                          <EarnedHeading>{toDecimal(history?.amount).deci}</EarnedHeading>
                        </AlignContainer>
                      </PurchaseCard>
                    </PurchaseRewards>
                  ))}
              </>
            )}

            {!earningHistoryLoading && earningHistory?.length >= 10 && (
              <Box sx={{ display: 'grid', placeContent: 'center', margin: '1rem 0' }}>
                <LoadMoreBtn onClick={() => fetchNextPage('request')}>Load next 10</LoadMoreBtn>
              </Box>
            )}
          </EarningHistory>
        </EarningContainer>
      </EarningDetailsContainer>
    </Modal>
  )
}
export default EarningDetailsModal

export const EarningDetailsContainer = styled(Grid)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  outline: 'none',
  position: 'absolute',
  width: '60%',
  '@media (max-width: 865px)': {
    width: '80%',
    maxHeight: '80%',
  },
}))

export const LoadMoreBtn = styled(Button)(({ theme }: any) => ({
  background: '#C4C4C4',
  height: '1.875rem',
  width: '8.5625rem',
  borderRadius: '0.5rem',
  color: theme.palette.common.white,
}))

const EarningContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '85vh',
}))

const EarningHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(110.09deg, rgba(69, 181, 73, 0.6) 1.97%, rgba(69, 181, 73, 0.28) 100%)',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 2rem 1rem 4rem',
  gap: '1rem',
  '@media (max-width: 500px)': {
    padding: '1rem',
  },
}))

const EarningHeading = styled(Typography)(({ theme }) => ({
  color: ' #0B213E',
  fontSize: '28px',
  lineHeight: '42px',
  fontWeight: '700',
}))
const EarnedContent = styled(Grid)(({ theme }) => ({
  padding: '2rem 4rem',
  flwxWrap: 'wrap',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 1024px)': {
    padding: '2rem',
  },

  '@media (max-width: 830px)': {
    padding: '2rem 4rem',
  },
  '@media (max-width: 600px)': {
    padding: '2rem',
  },
}))
const Earn = styled(Grid)(({ theme }) => ({
  display: 'flex',

  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid #DEE7FD',
  flexWrap: 'wrap',

  paddingBottom: '2rem',
  '@media (max-width: 1000px)': {
    flexWrap: 'wrap',
    gap: '2rem',
    padding: '1rem',
  },
}))
const RewardsBody = styled(Grid)(({ theme }) => ({
  width: '100%',
  borderBottom: '1px solid #DEE7FD',
  paddingBottom: '1rem',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    gap: '2rem',
    padding: '1rem',
  },
}))

const EarnedHeading = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '800',
}))
const TableCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))

const AlignContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const EarnedTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '23px',
  fontWeight: '700',
}))
const RewardsSchedule = styled(Box)(({ theme }) => ({
  padding: '0 4rem',
  background: '#FCFCFC',

  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    gap: '2rem',
    padding: '1rem',
  },
}))

const LeaderBoard = styled(Box)(({ theme }) => ({
  background: '#FCFCFC',
  padding: '1rem 4rem',

  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    padding: '1rem',
    gap: '2rem',
  },
}))
const LeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DEE7FD',
  alignItems: 'center',
  gap: '1rem',
  paddingBottom: '1rem',
  flexWrap: 'wrap',
}))

const EarningHistory = styled(Box)(({ theme }) => ({
  padding: '1rem 4rem 1rem 4rem',
  background: 'white',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    padding: '1rem',
  },
}))

const PurchaseRewards = styled(Grid)(({ theme }) => ({
  background: 'rgba(69, 181, 73, 0.06)',
  borderRadius: '5px',
  padding: '0.5rem 0',
  margin: '0.5rem 0',
  '@media (max-width: 500px)': {
    margin: '1rem 0',
  },
}))
const PurchaseCard = styled(Grid)(({ theme }) => ({
  padding: '0 1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    gap: '1rem',
  },
}))
const TotalEarned = styled(Grid)(({ theme }) => ({
  '@media (max-width: 1100px)': {
    width: '100px',
  },
}))

export const CustomSelect = styled(Select)(({ theme }) => ({
  textTransform: 'none',
  height: '34px',
  minWidth: '253px',
  borderRadius: '4px',
  padding: '1.25rem 1rem ',
  background: ' #F3F5F7',
  border: 'none',
  fontSize: '18px',
  fontWeight: '500',
  svg: {
    color: '#000',
    paddingRight: '0.5rem',
  },
  '@media (max-width:320px)': {
    minWidth: '200px',
  },
}))

export const PageToggleBtn = styled(Button)(({ theme }: any) => ({
  minWidth: '44px',
  height: '44px',
  borderRadius: '8px',
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  '&:hover': {
    background: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
  },
}))

export const PurchaseEarned = styled(Grid)(({ theme }: any) => ({
  borderLeft: `1px solid ${theme.palette.grey[700]}`,
  padding: '0 6rem',
  borderRight: `1px solid ${theme.palette.grey[700]}`,
  flexWrap: 'wrap',

  '@media (max-width:1450px)': {
    padding: '0 3rem',
  },

  '@media (max-width:1300px)': {
    padding: '0 2rem',
  },
  '@media (max-width:1250px)': {
    padding: '0 1rem',
  },
  '@media (max-width:960px)': {
    padding: '0 1.5rem',
    borderRight: 'none',
  },
  '@media (max-width:700px)': {
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
  },
  '@media (max-width: 560px)': {
    borderLeft: 'none',
    borderRight: 'none',
    padding: '0',
  },
}))
