import { createContext, useContext, useReducer } from 'react'
interface AppContextType {
  dctValue: number
  selectedCountry: any
  selectedProvider: any
  selectedDataValue: any
  popularCountry: any
  selectedOfferType: boolean
  isTopup:boolean
  doRefetchMydata:boolean
  doRefetchPurchases: boolean
  setSelectedCountry: (data: any) => void
  setSelectedProvider: (data: any) => void
  setSelectedDataValue: (data: any) => void
  setPopularCountry: (data: any) => void
  setDataSoldValue: (data: any) => void
  setDctSalesValue: (data: any) => void
  setSelectedOfferType: (data: any) => void
  setIsTopup:(data: any) => void
  setRefetchMydata:(data: any) => void
  setRefetchPurchases: (data: any) => void
}

const initialState: AppContextType = {
  dctValue: 0,
  selectedCountry: { value: '', label: '', flagCountryCode: '' },
  selectedProvider: { value: '', label: '', logo: '' },
  selectedDataValue: { value: '', label: '' },
  popularCountry: '',
  selectedOfferType: false,
  isTopup:false,
  doRefetchMydata:false,
  doRefetchPurchases: false,
  setSelectedCountry: () => {},
  setSelectedProvider: () => {},
  setSelectedDataValue: () => {},
  setPopularCountry: () => {},
  setDataSoldValue: () => {},
  setDctSalesValue: () => {},
  setSelectedOfferType: () => {},
  setIsTopup:() => {},
  setRefetchMydata:() => {},
  setRefetchPurchases: () => {},
}

const reducer = (state: AppContextType, action: any) => {
  switch (action.type) {
    case 'SET_DCT_VALUE':
      return { ...state, dctValue: action.payload }
    case 'SET_SELECTED_COUNTRY':
      return { ...state, selectedCountry: action.payload }
    case 'SET_SELECTED_DATA_VALUE':
      return { ...state, selectedDataValue: action.payload }
    case 'SET_POPULAR_COUNTRY':
      return { ...state, popularCountry: action.payload }
    case 'SET_SELECTED_PROVIDER':
      return { ...state, selectedProvider: action.payload }
    case 'SET_SELECTED_OFFER_TYPE':
      return { ...state, selectedOfferType: action.payload }
    case 'SET_IS_TOPUP':
      return { ...state, isTopup: action.payload }
    case 'SET_REFETCH_MYDATA':
      return { ...state, doRefetchMydata: action.payload }
    case 'SET_REFETCH_PURCHASES':
      return { ...state, doRefetchPurchases: action.payload }
    default:
      return { ...state }
  }
}

export const AppContext = createContext<AppContextType>(initialState)

function AppProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setDctValue = (value: number) => {
    dispatch({ type: 'SET_DCT_VALUE', payload: value })
  }
  state.setDataSoldValue = (data: any) => {
    dispatch({ type: 'SET_DATA_SOLD', payload: data })
  }
  state.setDctSalesValue = (data: any) => {
    dispatch({ type: 'SET_DCT_VALUE', payload: data })
  }
  state.setSelectedCountry = (data: any) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: data })
  }
  state.setSelectedDataValue = (data: any) => {
    dispatch({ type: 'SET_SELECTED_DATA_VALUE', payload: data })
  }
  state.setPopularCountry = (data: any) => {
    dispatch({ type: 'SET_POPULAR_COUNTRY', payload: data })
  }
  state.setSelectedProvider = (data: any) => {
    dispatch({ type: 'SET_SELECTED_PROVIDER', payload: data })
  }
  state.setSelectedOfferType = (data: any) => {
    dispatch({ type: 'SET_SELECTED_OFFER_TYPE', payload: data })
  }
  state.setIsTopup = (data: any) => {
    dispatch({ type: 'SET_IS_TOPUP', payload: data })
  }
  state.setRefetchMydata = (data: any) => {
    dispatch({ type: 'SET_REFETCH_MYDATA', payload: data })
  }
  state.setRefetchPurchases = (data: any) => {
    dispatch({ type: 'SET_REFETCH_PURCHASES', payload: data })
  }

  let data = { ...state, setDctValue }

  return <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
}

const useAppContext = () => useContext(AppContext)

export { AppProvider, useAppContext }
