import styled from '@emotion/styled'
// import { Link } from 'react-router-dom'
import { Box, Checkbox, Input, Typography } from '@mui/material'

export namespace B {
  export const PurchaseContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    gap: '1rem',
    flexWrap: 'wrap',
    width: 'unset',
    alignSelf: 'flex-end',
    padding: '1rem 2rem 1rem 2rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'unset',
    },
  }))

  export const DetailsContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 'unset',
    alignSelf: 'flex-end',
    gap: '2.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      gap: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: '1rem',
    },
  }))

  export const InputContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    width: '75%',
    height: '6.4375rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }))

  export const ActivateBuyModalBody = styled(Box)(({ theme }: any) => ({
    [theme.breakpoints.down(500)]: {
      overflowX: 'hidden',
      height: '20.6rem',
      overflowY: 'auto',
    },
    // [theme.breakpoints.down(391)]: {
    //   overflowX: 'hidden',
    //   height: '20.6rem',
    //   overflowY: 'auto',
    // },
    // [theme.breakpoints.down(376)]: {
    //   overflowX: 'hidden',
    //   height: '20.6rem',
    //   overflowY: 'auto',
    // },
  }))

  export const ActivateContainer = styled(Box)(({ theme }: any) => ({
    padding: '1rem 2rem 1rem 2rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'unset',
      gridTemplateRows: '1fr',
      height: '77vh',
    },
    [theme.breakpoints.down(500)]: {
      height: '65vh',
      maxHeight: '70vh',
      overflowY: 'auto',
    },
  }))

  export const ActivateContents = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    // gap: '4rem',
    justifyContent: 'space-around',

    [theme.breakpoints.down('md')]: {
      gap: '5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }))

  export const InfoContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      gap: '3rem',
    },
  }))

  export const SuccessText = styled(Typography)(({ theme }: any) => ({
    color: '#0B213E',
    fontSize: '1.5rem',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))

  export const ActivateMessage = styled(Typography)(({ theme }: any) => ({
    color: '#0B213E',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.875rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))

  export const URLContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    flexWrap: 'wrap',
  }))

  export const URLText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '0.9375rem',
  }))

  export const TextBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '290px',
    },
  }))

  export const ActivateDays = styled(Typography)(({ theme }: any) => ({
    color: '#0B213E',
    fontSize: '0.75rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))

  export const ImgContainer = styled(Box)(({ theme, medium }: any) => ({
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: medium ? 'flex' : 'none',
      alignItems: medium ? 'center' : 'end',
    },
    // justifyContent: 'flex-end',
    // alignItems: 'flex-end',
  }))

  export const PurchaseSuccessImg = styled.img(({ theme }: any) => ({
    height: '294px',
    width: '311px',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '200px',
    },
  }))

  export const TransactionHash = styled(Input)(({ theme }: any) => ({
    background: theme.palette.grey[800],
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.grey[800]}`,
    width: '100%',
    padding: '0.25rem 0.7rem',
    fontWeight: 400,
    fontSize: '0.75rem !important',
    lineHeight: '1rem !important',
    minHeight: '2.6875rem',
    input: {
      '&:disabled': {
        cursor: 'not-allowed !important',
      },
      '&::placeholder': {
        '&::after': {
          content: '"*"',
          color: 'red !important',
        },
      },
    },
  }))

  export const PaymentInitiated = styled(Typography)(({ theme }: any) => ({
    color: '#D45E35',
    fontSize: '0.75rem',
    left: '0.75rem',
    top: '2.5rem',
    // position: 'absolute',
  }))

  export const PaymentConfirming = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 500,
  }))

  export const TransferPending = styled(Typography)(({ theme }: any) => ({
    color: ' #ff9900',
    fontSize: '1rem',
    fontWeight: 500,
  }))

  export const TransactionNotFound = styled(Typography)(({ theme }: any) => ({
    color: '#D45E35',
    fontSize: '1rem',
  }))

  export const QRBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '0.25rem',
    width: '240px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }))

  export const WarningInfoContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    alignItems: 'baseline',
    position: 'relative',
  }))

  export const TroubleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: '0.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down(500)]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))

  export const CustomLink = styled(TroubleText)(({ theme }: any) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }))

  export const DepositAddressText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontSize: '1rem',
    cursor: 'pointer',
  }))
  export const ErrorText = styled(Typography)(({ theme }: any) => ({
    color: 'red',
    fontSize: '0.75rem',
  }))
  export const CheckBoxWrapper = styled(PurchaseContainer)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '25vh',
    justifyContent: 'space-between',
    paddingTop: '3rem',
  }))
  export const CheckBoxContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }))
  export const CustomCheckBox = styled(Checkbox)(({ theme }: any) => ({
    width: '1.25rem',
    padding: 0,
    color: '#45B549',
    '&.Mui-disabled.Mui-checked': {
      pointerEvents: 'auto',
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      color: 'red',
    },
  }))
  export const CheckBoxText = styled(Typography)(({ theme }: any) => ({
    fontSize: '18px',
    color: theme.palette.primary.main,
    fontWeight: '400',
  }))
  export const RetryText = styled(CheckBoxText)(({ theme }: any) => ({
    color: '#D45E35',
  }))
  export const ButtonContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'flex-end',
  }))
  export const ORText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: '0.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    textIndent: "4rem",
    [theme.breakpoints.down(500)]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))
}
