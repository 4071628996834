import { FC, useEffect, useState } from 'react'
import { Box, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import { S } from './stakehistory.style'
import { STAKE_HISTORY_TABLE_HEADER } from 'utils/constants'
import { convertToPascalCase, toDecimal } from 'utils'
import moment from 'moment'
import { useWalletContext } from 'contexts/wallet.context'

interface Props {
  loader: boolean
  data: any
  type: string
  fetchNextPage: any
  showLoadmoreBtn: boolean
}
const HistoryTable: FC<Props> = ({ data, type, fetchNextPage, loader, showLoadmoreBtn }) => {
  return (
    <TableContainer sx={{ maxHeight: 300 }}>
      <S.CustomTable size="small" stickyHeader aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ height: '4rem' }}>
            {STAKE_HISTORY_TABLE_HEADER.map((data, i) => (
              <S.CustomTableCell
                key={i}
                sx={{
                  fontWeight: 'bold',
                  background: '#F1F2F6',
                }}
              >
                {data}
              </S.CustomTableCell>
            ))}
            <S.CustomTableCell
              sx={{
                background: '#F1F2F6',
              }}
            ></S.CustomTableCell>
          </TableRow>
        </TableHead>

        {loader ? (
          <TableBody>
            <S.CustomTableRow>
              <S.CustomTableCell colSpan={5} align="center">
                <CircularProgress />
              </S.CustomTableCell>
            </S.CustomTableRow>
          </TableBody>
        ) : (
          <TableBody>
            {data?.map((history: any) => {
              return (
                <S.CustomTableRow>
                  <S.CustomTableCell align="left">
                    {moment(history?.blockDatetime).format('DD MMM, YYYY')}
                  </S.CustomTableCell>
                  <S.CustomTableCell align="left">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={`data:image/png;base64,${history?.selectedProvider?.logo}`}
                        alt="logo"
                        style={{ width: '1.75rem', height: '1.75rem', marginRight: '1rem' }}
                      />
                      {convertToPascalCase(history?.selectedProvider?.displayName)}
                    </Box>
                  </S.CustomTableCell>
                  <S.CustomTableCell align="left">{toDecimal(history?.amount, 12).deci}</S.CustomTableCell>
                  {/* <S.CustomTableCell align="left">{history?.amount}</S.CustomTableCell> */}
                  <S.CustomTableCell align="left">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Box
                        sx={{
                          height: '11px',
                          width: '11px',
                          borderRadius: '50%',
                          background: history.complete ? '#18BC46' : '#FF8730',
                        }}
                      ></Box>
                      {history.complete && 'Completed'}
                    </Box>
                  </S.CustomTableCell>
                  <S.CustomTableCell align="left"></S.CustomTableCell>
                </S.CustomTableRow>
              )
            })}

            {!data.length && (
              <S.CustomTableRow>
                <S.CustomTableCell colSpan={5} align="center">
                  {`No ${type} history found.`}
                </S.CustomTableCell>
              </S.CustomTableRow>
            )}
          </TableBody>
        )}
      </S.CustomTable>

      {!loader && data?.length >= 10 && showLoadmoreBtn === true && (
        <Box sx={{ display: 'grid', placeContent: 'center', margin: '1rem 0' }}>
          <S.LoadMoreBtn onClick={() => fetchNextPage('stake')}>Load next 10</S.LoadMoreBtn>
        </Box>
      )}
    </TableContainer>
  )
}

export default HistoryTable
