import styled from '@emotion/styled'
import { Box, Typography, Button, FormControlLabel, RadioGroup } from '@mui/material'

export namespace S {
  export const ModalMainContainer = styled(Box)(({ theme, buyModalCloseSurvey }: any) => ({
    // width: '60.5%',
    position: 'absolute',
    top: buyModalCloseSurvey === '' ? '55%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F1F2F6',
    borderRadius: '15px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    padding: buyModalCloseSurvey === '' ? '0 2.5rem' : '0',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      minHeight: buyModalCloseSurvey === '' ? 520 : 520,
      minWidth: buyModalCloseSurvey === '' ? 900 : 900,
    },

    [theme.breakpoints.down('md')]: {
      width: buyModalCloseSurvey === '' ? '75%' : '75%',
      height: buyModalCloseSurvey === '' ? '82vh' : '70vh',
      maxHeight: '95vh',
    },

    [theme.breakpoints.down('sm')]: {
      width: buyModalCloseSurvey === '' ? '75%' : '90%',
      maxHeight: '95vh',
      height: buyModalCloseSurvey === '' ? '82vh' : '85vh',
      // overflowY: 'auto',
    },
  }))

  export const IntroMaintext = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    // fontSize: 'calc(30px + (55 - 30) * ((100vw - 300px) / (1600 - 300)))',
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '1.875rem',
    // },
  }))
  export const ButtonContaienr = styled(Typography)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
  }))

  export const IntroContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: 'calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)))',

    color: theme.palette.grey[600],
    [theme.breakpoints.down('md')]: {
      // fontSize: '1.4rem',
      textAlign: 'center',
    },
  }))

  export const StepText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.125rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
    },
  }))

  export const GuideModalHeader = styled(Box)(({ theme }: any) => ({
    width: '100%',
    height: '6.2rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))

  export const GuideModalBody = styled(Box)(({ theme }: any) => ({}))
  export const GuideModalFooter = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    padding: '2rem 2rem 0 2rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
    },
  }))

  export const IllustrationImg = styled.img(({ theme }: any) => ({
    position: 'absolute',
    right: '2rem',
    top: '-7.75rem',
    width: '300px',
    aspectRatio: 'auto',
    [theme.breakpoints.down('md')]: {
      position: 'inherit',
      right: '0',
      width: '12.5rem',
      height: '12.5rem',
      top: '-3rem',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '12.5rem',
    //   height: '12.5rem',
    // },
  }))

  export const CloseIconContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    right: '-4.35rem',
    top: '-1.6rem',
    height: '4rem',
    width: '4rem',
    background: theme.palette.primary.main,
    borderRadius: '0.6875rem',
    display: 'grid',
    placeContent: 'Center',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '3rem',
      right: '-3.35rem',

      width: '3rem',
    },
  }))

  export const BodyFooterWrapper = styled(Box)(({ theme }: any) => ({
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-around',
    display:"grid",
    gridTemplateRows:"40% 40% 20%",
    gap: '2rem',
    height: '21rem',
    [theme.breakpoints.down('md')]: {
      height: '29rem',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    // [theme.breakpoints.down('sm')]: {
    //   height: '35rem',
    // },
  }))

  /* Buymodal Close Container styles */

  export const BuyModalCloseHeader = styled(Box)(({ theme }: any) => ({
    height: '2rem',
  }))
  export const CloseIconBuyModalClose = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    right: '-1.3rem',
    top: '-1.6rem',
    height: '4rem',
    width: '4rem',
    background: theme.palette.primary.main,
    borderRadius: '0.6875rem',
    display: 'grid',
    placeContent: 'Center',
    cursor: 'pointer',
  }))
  export const BuyModalCloseContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    padding: '2rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      height: '36rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40rem',
    },
    [theme.breakpoints.down(376)]: {
      height: '30rem',
      minHeight: '74vh',
    },
  }))
  export const BuyModalContainer = styled(RadioGroup)(({ theme }: any) => ({
    width: '100%',
    position: 'relative',
  }))

  export const BuyCloseIconContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '-2rem',
    right: '-1rem',
    height: '4rem',
    width: '4rem',
    background: theme.palette.primary.main,
    borderRadius: '0.6875rem',
    display: 'grid',
    placeContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '50px',
    },
  }))
  export const BuyModalCloseTitle = styled('div')(({ theme }: any) => ({
    fontWeight: '700',
    textAlign: 'center',
    fontSize: 'calc(20px + (35 - 20) * ((100vw - 300px) / (1600 - 300)))',
  }))

  export const RadioButtonList = styled(FormControlLabel)(({ theme }: any) => ({
    '&,.MuiFormControlLabel-label': {
      fontSize: '18px',
      fontWeight: '400',
    },

    '.css-hyxlzm': {
      color: '#18BC46 !important',
    },
  }))
  export const RadioGroupButton = styled(RadioGroup)(({ theme }: any) => ({
    paddingRight: '4rem',
    [theme.breakpoints.down('md')]: {
      gap: '1rem',
      paddingRight: '0',
    },
  }))

  export const InputFieldSubmitContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    paddingLeft: '2rem',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      width: '95%',
      gap: '3rem',
    },
  }))
  export const InputFieldSubContainer = styled(Box)(({ theme }: any) => ({
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
    },
  }))
  export const MaxLengthCharacter = styled('span')(({ theme }: any) => ({
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '0.5rem',
  }))
  export const ReasonInputFiled = styled('textarea')(({ theme }: any) => ({
    background: '#ced6e040',
    color: '#66717B',
    borderRadius: '0.5rem',
    padding: '1rem 0.75rem',
    fontWeight: 400,
    fontSize: '1.125rem !important',
    lineHeight: '1.4375rem !important',
    outline: 'none',
    border: 'none',
    display: 'flex',

    height: '50px',
    textAlign: 'start',
    width: '700px',
    justifyContent: 'flex-end',
    '&::placeholder': {
      '&::after': {
        content: '"*"',
        color: 'red',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
    },
  }))
  export const DisableButton = styled(Button)(({ theme }: any) => ({
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1.25rem',
    height: '3.125rem',
    width: '8.75rem',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      textAlign: 'center',
    },
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))
}
